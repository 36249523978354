import React from "react";
import AnalyticsEvent from "../../shared/AnalyticsEvent";

const blog_202210 = [
  {
    "img": "/images/site/blog/20221016_john-horiz.png",
    "tag": "About Us",
    "title": "Passionate Educators With Track Record For Improvement",
    "slug": "passionate-educators-with-track-record-for-improvement",
    "date": "16 Oct 2022",
    "publishDate": "2022-10-16T12:00:00.000+08:00",
    "content": 
    <React.Fragment>
      <div className="post-meta pb-100"><a href="/blog" className="theme-btn-five"><i className="fa fa-arrow-left"></i> Back</a>
        <img
          src="/images/site/blog/20221016_john-horiz.png"
          alt="johnathan chan yue han"
        />
        <div className="tag">16 Oct 2022</div>
        <h3 className="title">
          Passionate Educators With Track Record For Improvement
        </h3>
        <h4>About the Founder</h4>
        <p>
        <img
          src="/images/site/blog/20221016_john-vert.png"
          alt="johnathan chan yue han"
          className="image-inline"
        />
        Mr Johnathan Chan was a Nanyang President Research Scholar and taught at the 
        Senior High department of Dunman High and Nanyang Girls’ High School. He has 
        taught for 15 years, and seen hundreds of students improve and graduate under 
        his guidance.
        </p>
        <p>
        Mr Johnathan Chan taught Advanced Science Classes in Nanyang Girls’ High, which 
        was offered to the top 5% of students cohort. In the Advanced class, complex 
        scientific concepts were explored beyond the scope of the usual IP syllabus.
        </p>
        <p>
        With his years of experience, he has expanded EduZ and created a team of passionate 
        educators. EduZ’s teachers believe in the success of their students and strive to 
        help their students improve.
        </p>
        <h4>Education</h4>
        <ul>
          <li>• Bachelor of Business, Nanyang Technological University</li>
          <li>• 7 G.C.E ‘O’ Level Distinctions</li>
          <li>• 6 G.C.E ‘A’ Levels Distinctions</li>
        </ul>
        <h4>Teaching Experiences</h4>
        <ul>
          <li>• Senior High Department Teacher in Dunman High</li>
          <li>• Nanyang IP Teacher in NYGH</li>
          <li>• Spring Field Secondary School Teacher</li>
          <li>• More than 15 years of mentoring and coaching experience</li>
        </ul>
        <h4>Awards</h4>
        <ul>
          <li>• Nanyang President Research Scholarship</li>
          <li>• Tan Teck Meng Scholarship</li>
        </ul>
        <p></p>
        <img
          src="/images/site/blog/20221016_logos.png"
          alt="logos"
        />
        <p></p>
        <div style={{ textAlign: 'center' }}>
          <p>Keen to learn more about Eduz Tuition?</p>
          <a href="https://wa.me/+6590625285?lang=en"
          target="_blank"
          rel="noreferrer"
          className="theme-btn-five"
          data-aos="fade-in"
          data-aos-duration="1200"
          data-aos-delay="200"
          onClick={() => AnalyticsEvent({
            category: "WhatsAppEnquire",
            action: "Click",
            source: "/blog/passionate-educators-with-track-record-for-improvement",
            label: "blogClickWhatsAppEnquire"
          })}
        >
        <i className="fa fa-whatsapp"></i> Chat with us now
        </a>
      </div>
      <p></p>
      </div>
    </React.Fragment>
  }
]


export default blog_202210
