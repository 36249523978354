import React from "react";
import AnalyticsEvent from "../../shared/AnalyticsEvent";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";

const SubjectContent = 
[
  {
    "id": "primary-1",
    "level": "Primary 1",
    "subjects":[
      {
        "subject": "English",
        "description": "P1 English focuses on developing a good foundation in English while the student is still in the early stages of formative learning.",
        "subjectIcon": "/images/site/icon/subject/english.png",
        "content": [{
            "icon": "/images/site/icon/subject/mastery.png",
            "meta": "Content & Language Mastery",
            "subTitle": `Our P1 English focuses on understanding nouns, pronouns, adjectives, the proper use of tenses and punctuations.`,
            "dataDelay": "0",
          },
          {
            "icon": "/images/site/icon/subject/skills.png",
            "meta": "Skills and Techniques",
            "subTitle": `Students will be introduced to comprehensions (short stories), creative writing and oratory (reading aloud).`,
            "dataDelay": "100",
          }],
      },
      {
        "subject": "Chinese",
        "description": "P1 Chinese is all about having a structured routine to learning and practicing Chinese.",
        "subjectIcon": "/images/site/icon/subject/chinese.png",
        "content": [{
            "icon": "/images/site/icon/subject/mastery.png",
            "meta": "Content & Language Mastery",
            "subTitle": `P1 Chinese focuses on building a foundation in Hanyu Pinyin, practice writing more Chinese characters and recognizing and differentiating the various Chinese pronunciation.`,
            "dataDelay": "0",
          },
          {
            "icon": "/images/site/icon/subject/skills.png",
            "meta": "Skills and Techniques",
            "subTitle": `Students have the opportunity to speak Chinese with on-the-spot guidance by tutors.`,
            "dataDelay": "100",
          }],
      },
      {
        "subject": "Math",
        "description": "P1 Math focuses on developing a good foundation in mathematics which is necessary to gain mastery in mathematics as the student progress.",
        "subjectIcon": "/images/site/icon/subject/lower-pri-math.png",
        "content": [{
            "icon": "/images/site/icon/subject/mastery.png",
            "meta": "Content & Language Mastery",
            "subTitle": `Our P1 Math covers numbers, simple mathematical equations, measurements, geometry and comprehending pictorial graphs.`,
            "dataDelay": "0",
          },
          {
            "icon": "/images/site/icon/subject/skills.png",
            "meta": "Skills and Techniques",
            "subTitle": `Application-based questions about length, time and money will help students gain awareness of the importance of math in everyday life.`,
            "dataDelay": "100",
          }],
      },
    ]
  },
  {
    "id": "primary-2",
    "level": "Primary 2",
    "subjects":[
      {
        "subject": "English",
        "description": "P2 English focuses on building on the foundation and allowing room for further learning and applications.",
        "subjectIcon": "/images/site/icon/subject/english.png",
        "content": [{
            "icon": "/images/site/icon/subject/mastery.png",
            "meta": "Content & Language Mastery",
            "subTitle": `Our P2 English focuses on the proper use of tenses for various scenarios and the introduction of synonyms and idioms to expand their vocabulary.`,
            "dataDelay": "0",
          },
          {
            "icon": "/images/site/icon/subject/skills.png",
            "meta": "Skills and Techniques",
            "subTitle": `Students learn to analyse the meaning in short stories, poems and letters while practicing sentence writing for composition. Additional practice to speak English during class will continue to hone their verbal skills.`,
            "dataDelay": "100",
          }],
      },
      {
        "subject": "Chinese",
        "description": "P2 Chinese builds upon the basics and enhance the link between Chinese and their daily activities.",
        "subjectIcon": "/images/site/icon/subject/chinese.png",
        "content": [{
            "icon": "/images/site/icon/subject/mastery.png",
            "meta": "Content & Language Mastery",
            "subTitle": `P2 Chinese exposure to composition and reading comprehension which expand their Chinese word bank.`,
            "dataDelay": "0",
          },
          {
            "icon": "/images/site/icon/subject/skills.png",
            "meta": "Skills and Techniques",
            "subTitle": `Basic composition writing skills, oral test components are taught. Furthermore, students will have additional practice to read and write Chinese text as they learn identify, learn and apply sentence structures.`,
            "dataDelay": "100",
          }],
      },
      {
        "subject": "Math",
        "description": "P2 Math focuses on building on the foundation set at P1 and allowing room for further learning and applications.",
        "subjectIcon": "/images/site/icon/subject/lower-pri-math.png",
        "content": [{
            "icon": "/images/site/icon/subject/mastery.png",
            "meta": "Content & Language Mastery",
            "subTitle": `Our P2 Math explores more advanced concept in numbers and mathematical equations, while using numbers up to 1000. Topics involving mass, volume and fractions will also be introduced to prepare students for P3.`,
            "dataDelay": "0",
          },
          {
            "icon": "/images/site/icon/subject/skills.png",
            "meta": "Skills and Techniques",
            "subTitle": `Crucial presentation skills are taught to students to instil a good writing habit from young. Rectification of any misconceptions is also important in P2 to ensure students have an accurate understanding of mathematics.`,
            "dataDelay": "100",
          }],
      },
    ]
  },
  {
    "id": "primary-3",
    "level": "Primary 3",
    "subjects":[
      {
        "subject": "English",
        "description": "P3 English focuses on exposing students to a wider range of themes for greater sense of social awareness.",
        "subjectIcon": "/images/site/icon/subject/english.png",
        "content": [{
            "icon": "/images/site/icon/subject/mastery.png",
            "meta": "Content & Language Mastery",
            "subTitle": `Our P3 English focuses on synthesis and transformation of sentences while understanding tones and emotions in texts. They are highly encouraged to develop their repertoire of vocabulary through analysing and reading of books.`,
            "dataDelay": "0",
          },
          {
            "icon": "/images/site/icon/subject/skills.png",
            "meta": "Skills and Techniques",
            "subTitle": `Students learn to read and analyse article-based comprehension passages while applying descriptive vocabulary in compositions.`,
            "dataDelay": "100",
          }],
      },
      {
        "subject": "Chinese",
        "description": "P3 Chinese focuses on integration of emotions into the Chinese language.",
        "subjectIcon": "/images/site/icon/subject/chinese.png",
        "content": [{
            "icon": "/images/site/icon/subject/mastery.png",
            "meta": "Content & Language Mastery",
            "subTitle": `P3 Chinese program teaches students to identify tones and emotions in text and apply to their own speech. A better understanding of what is being conversed is expected.`,
            "dataDelay": "0",
          },
          {
            "icon": "/images/site/icon/subject/skills.png",
            "meta": "Skills and Techniques",
            "subTitle": `Students’ vocabulary bank is expanded as a more diverse range of topics are touched upon. Sentence structure for writing and speaking Chinese are refined and more writing composition skills are taught.`,
            "dataDelay": "100",
          }],
      },
      {
        "subject": "Math",
        "description": "P3 Math focuses on broadening the list of topics and students’ answering skills, in preparation to answer more problem-based questions.",
        "subjectIcon": "/images/site/icon/subject/lower-pri-math.png",
        "content": [{
            "icon": "/images/site/icon/subject/mastery.png",
            "meta": "Content & Language Mastery",
            "subTitle": `Our P3 Math explores numbers and mathematical equations, while using numbers up to 10 000. Additional topics in area, perimeters, angles and perpendicular and parallel lines are introduced. Statistical components like analysing scales and bar graphs are also taught to prepare students for P4.`,
            "dataDelay": "0",
          },
          {
            "icon": "/images/site/icon/subject/skills.png",
            "meta": "Skills and Techniques",
            "subTitle": `Students in P3 will learn skills more specific to answering examinations questions accurately, using the foundation set at primary 1 and 2.`,
            "dataDelay": "100",
          }],
      },
      {
        "subject": "Science",
        "description": "P3 Science focuses on introducing the basic of science to students. They will be given a brief introduction on each of the main building blocks of science in the PSLE syllabus.",
        "subjectIcon": "/images/site/icon/subject/lower-pri-science.png",
        "content": [{
            "icon": "/images/site/icon/subject/mastery.png",
            "meta": "Content & Language Mastery",
            "subTitle": `P3 Science covers the basic of PSLE science themes in Diversity, Cycles, Systems, Interactions and Energy. Science experiments are introduced to arouse students’ interest and aid understanding.`,
            "dataDelay": "0",
          },
          {
            "icon": "/images/site/icon/subject/skills.png",
            "meta": "Skills and Techniques",
            "subTitle": `Students are introduced to the way science questions are asked and practice question analysis using the scientific method.`,
            "dataDelay": "100",
          }],
      },
    ]
  },
  {
    "id": "primary-4",
    "level": "Primary 4",
    "subjects":[
      {
        "subject": "English",
        "description": "P4 English focuses on preparing students for more time-based assessments while developing critical thinking skills. This will enable them to better transition to upper primary, where the demands lie beyond just demonstrating a basic understanding of the language.",
        "subjectIcon": "/images/site/icon/subject/english.png",
        "content": [{
            "icon": "/images/site/icon/subject/mastery.png",
            "meta": "Content & Language Mastery",
            "subTitle": `Our P4 English touches on phasal verbs, connectors, clauses and common expression. Active discussion are held during lesson time to encourage students to express their personal thoughts and opinions.`,
            "dataDelay": "0",
          },
          {
            "icon": "/images/site/icon/subject/skills.png",
            "meta": "Skills and Techniques",
            "subTitle": `Students are taught keyword analysis and inferential skills to tackle comprehension questions. Students are also encouraged to come up with creative stories to capture readers’ attention in composition. Continued refinement in their pronunciation, enunciation, intonation help students gain confidence in their oral skills.`,
            "dataDelay": "100",
          }],
      },
      {
        "subject": "Chinese",
        "description": "P4 Chinese focuses on building their word bank and application of harder-level words in their everyday life.",
        "subjectIcon": "/images/site/icon/subject/chinese.png",
        "content": [{
            "icon": "/images/site/icon/subject/mastery.png",
            "meta": "Content & Language Mastery",
            "subTitle": `Fluency in reading and speaking is expected while students prepare for oral exams. Chinese idioms are also introduced to enhance their vocabulary bank.`,
            "dataDelay": "0",
          },
          {
            "icon": "/images/site/icon/subject/skills.png",
            "meta": "Skills and Techniques",
            "subTitle": `Building onto their foundation, description of actions and emotions are expected in their written composition. Students are also exposed to a wider range of question type in their reading comprehension.`,
            "dataDelay": "100",
          }],
      },
      {
        "subject": "Math",
        "description": "P4 Math focuses on preparing students for more time-based assessments while tackling questions that require more critical thinking.",
        "subjectIcon": "/images/site/icon/subject/upper-pri-math.png",
        "content": [{
            "icon": "/images/site/icon/subject/mastery.png",
            "meta": "Content & Language Mastery",
            "subTitle": `Our P4 Math explores more mathematical symbols and numbers, while using numbers up to 100 000. Additional topics in factors, multiples, decimals and symmetry are introduced. Students are also taught to create and analyse tables and line graphs.`,
            "dataDelay": "0",
          },
          {
            "icon": "/images/site/icon/subject/skills.png",
            "meta": "Skills and Techniques",
            "subTitle": `Students in P4 continue to work on answering techniques while practicing application-based mathematics concepts and questions.`,
            "dataDelay": "100",
          }],
      },
      {
        "subject": "Science",
        "description": "P4 Science focuses on building on their science foundation and allowing room for further learning and applications.",
        "subjectIcon": "/images/site/icon/subject/lower-pri-science.png",
        "content": [{
            "icon": "/images/site/icon/subject/mastery.png",
            "meta": "Content & Language Mastery",
            "subTitle": `P4 Science touches on more chapters under the 6 PSLE science themes, while revising the topics covered in P3 at greater depth.`,
            "dataDelay": "0",
          },
          {
            "icon": "/images/site/icon/subject/skills.png",
            "meta": "Skills and Techniques",
            "subTitle": `More skill-based scientific skills like drawing relationship between variables, analysing information in charts and graphs, and interpreting the aims, variables and conclusion of experiment set-up are the focus in this level.`,
            "dataDelay": "100",
          }],
      },
    ]
  },
  {
    "id": "primary-5",
    "level": "Primary 5",
    "subjects":[
      {
        "subject": "English",
        "description": "P5 English focuses on more advanced concepts in English, whilst preparing for the upcoming primary school leaving examination (PSLE).",
        "subjectIcon": "/images/site/icon/subject/english.png",
        "content": [{
            "icon": "/images/site/icon/subject/mastery.png",
            "meta": "Content & Language Mastery",
            "subTitle": `P5 English introduces direct and indirect speech to students. The concept of persuasion in passages and conversations are also taught.`,
            "dataDelay": "0",
          },
          {
            "icon": "/images/site/icon/subject/skills.png",
            "meta": "Skills and Techniques",
            "subTitle": `Students are introduced to situational writing and are equipped with writing techniques like flashback, foreshadowing and twist and hook to enhance their composition skills. Mock interview preparation for students keen on Direct School Admission or other general interview settings are also provided.`,
            "dataDelay": "100",
          }],
      },
      {
        "subject": "Chinese",
        "description": "P5 Chinese focuses on clear writing and articulation while being able to understand the (hidden) meaning of sentences.",
        "subjectIcon": "/images/site/icon/subject/chinese.png",
        "content": [{
            "icon": "/images/site/icon/subject/mastery.png",
            "meta": "Content & Language Mastery",
            "subTitle": `Chinese proverbs are introduced to enhance students’ vocabulary. The skill to identify when and how to use more advanced phrases is also crucial to make their oral and writing skills stand out.`,
            "dataDelay": "0",
          },
          {
            "icon": "/images/site/icon/subject/skills.png",
            "meta": "Skills and Techniques",
            "subTitle": `The main focus is on honing exam techniques for each examinable components, which includes sharpening students’ vocabulary for their description writing, encouraging appropriate reasoning and expression for their oral examinations and brushing up inference skills for their comprehension paper.`,
            "dataDelay": "100",
          }],
      },
      {
        "subject": "Math",
        "description": "P5 math focuses on more advanced concepts in mathematics, whilst preparing for the upcoming primary school leaving examination (PSLE).",
        "subjectIcon": "/images/site/icon/subject/upper-pri-math.png",
        "content": [{
            "icon": "/images/site/icon/subject/mastery.png",
            "meta": "Content & Language Mastery",
            "subTitle": `Our P5 Math explores calculation of area, volume of 3D figures while differentiating between parallelogram, rhombus and trapezium. Students also learn about statistical mean, median in a set of data. Ratio, rate and speed are introduced to students.`,
            "dataDelay": "0",
          },
          {
            "icon": "/images/site/icon/subject/skills.png",
            "meta": "Skills and Techniques",
            "subTitle": `Students in P5 learn tips and tricks to use their calculator efficiently while building good habit like double checking their own answers to minimize the loss of marks.`,
            "dataDelay": "100",
          }],
      },
      {
        "subject": "Science",
        "description": "P5 Science focuses on more advanced scientific concepts, whilst preparing for the upcoming primary school leaving examination (PSLE).",
        "subjectIcon": "/images/site/icon/subject/upper-pri-science.png",
        "content": [{
            "icon": "/images/site/icon/subject/mastery.png",
            "meta": "Content & Language Mastery",
            "subTitle": `P5 Science touches on more in-depth chapters under the 6 PSLE science themes. Most of the content requires understanding of processes and the combination of knowledge from multiple chapters are necessary to answer practice questions.`,
            "dataDelay": "0",
          },
          {
            "icon": "/images/site/icon/subject/skills.png",
            "meta": "Skills and Techniques",
            "subTitle": `Students are taught on how to identify question requirement and its respective answering structure. Students are also drilled on the use of specific scientific keyword in answering questions.`,
            "dataDelay": "100",
          }],
      },
    ]
  },
  {
    "id": "primary-6",
    "level": "Primary 6",
    "subjects":[
      {
        "subject": "English",
        "description": "P6 English focuses on overall refining of their skills, as well as preparing students for their PSLE English papers.",
        "subjectIcon": "/images/site/icon/subject/english.png",
        "content": [{
            "icon": "/images/site/icon/subject/mastery.png",
            "meta": "Content & Language Mastery",
            "subTitle": `P6 English covers the remaining PSLE topics like passive and active voice, spelling and editing and reported speeches.`,
            "dataDelay": "0",
          },
          {
            "icon": "/images/site/icon/subject/skills.png",
            "meta": "Skills and Techniques",
            "subTitle": `Students are tasked with practice questions and personalized feedback are provided to address each student’s individual weakness.`,
            "dataDelay": "100",
          },
          {
            "icon": "/images/site/icon/subject/reinforcement.png",
            "meta": "Revision and Reinforcement",
            "subTitle": `Revision will be focused mastering the individual paper requirements and honing analytical and time management skills through practice tests in simulated exam conditions.`,
            "dataDelay": "200",
          }],
      },
      {
        "subject": "Chinese",
        "description": "P6 Chinese focuses on overall refining of their skills, as well as preparing students for their PSLE Chinese papers.",
        "subjectIcon": "/images/site/icon/subject/chinese.png",
        "content": [{
            "icon": "/images/site/icon/subject/mastery.png",
            "meta": "Content & Language Mastery",
            "subTitle": `Advanced phrases, proverbs and idioms are introduced for more advanced students while more (social) topics are touched upon for a more well-rounded Chinese education.`,
            "dataDelay": "0",
          },
          {
            "icon": "/images/site/icon/subject/skills.png",
            "meta": "Skills and Techniques",
            "subTitle": `Students are tasked with practice questions and personalized feedback are provided to address each student’s individual weakness. Cheat sheets with important and useful phases, proverbs and idioms are provided to improve composition and oral skills.`,
            "dataDelay": "100",
          },
          {
            "icon": "/images/site/icon/subject/reinforcement.png",
            "meta": "Revision and Reinforcement",
            "subTitle": `In P6 Chinese, revision will be focused on mastering the individual Chinese paper requirements and honing time management skills. It is crucial to continue students’ exposure and usage of Chinese characters to build familiarization.`,
            "dataDelay": "200",
          }],
      },
      {
        "subject": "Math",
        "description": "P6 Math focuses on overall refining of their skills, as well as preparing students for their PSLE Math papers.",
        "subjectIcon": "/images/site/icon/subject/upper-pri-math.png",
        "content": [{
            "icon": "/images/site/icon/subject/mastery.png",
            "meta": "Content & Language Mastery",
            "subTitle": `Our P6 Math covers the remaining PSLE topics in algebra, nets and pie charts.`,
            "dataDelay": "0",
          },
          {
            "icon": "/images/site/icon/subject/skills.png",
            "meta": "Skills and Techniques",
            "subTitle": `Problem sums that involve real-world situations and combines the knowledge of multiple concepts are a top priority to prepare students for the challenging final exam.`,
            "dataDelay": "100",
          },
          {
            "icon": "/images/site/icon/subject/reinforcement.png",
            "meta": "Revision and Reinforcement",
            "subTitle": `Revision will be focused on drilling concepts and clearing any erroneous understanding of mathematics through practicing past year examination questions.`,
            "dataDelay": "200",
          }],
      },
      {
        "subject": "Science",
        "description": "P6 Science focuses on overall refining of their skills, as well as preparing students for their PSLE Science papers.",
        "subjectIcon": "/images/site/icon/subject/upper-pri-science.png",
        "content": [{
            "icon": "/images/site/icon/subject/mastery.png",
            "meta": "Content & Language Mastery",
            "subTitle": `P6 Science touches on the remaining chapters under the 6 PSLE science themes. Topical revision of common and tricky PSLE Science questions will be held in class.`,
            "dataDelay": "0",
          },
          {
            "icon": "/images/site/icon/subject/skills.png",
            "meta": "Skills and Techniques",
            "subTitle": `Practice questions that involve real-world situations and combines the knowledge of multiple concepts are a top priority to prepare students for the challenging final exam.`,
            "dataDelay": "100",
          },
          {
            "icon": "/images/site/icon/subject/reinforcement.png",
            "meta": "Revision and Reinforcement",
            "subTitle": `Revision will be focused on drilling concepts and clearing any erroneous understanding of mathematics through practicing past year examination questions.`,
            "dataDelay": "200",
          }],
      },
    ]
  },
  {
    "id": "secondary-1",
    "level": "Secondary 1",
    "subjects":[
      {
        "subject": "English",
        "description": "S1 English focuses on building on the skills established in primary school and exposure to new components such as summary writing. There is no more MCQ questions and students are taught the requirements and question formats for their secondary school English paper.",
        "subjectIcon": "/images/site/icon/subject/english.png",
        "content": [{
            "icon": "/images/site/icon/subject/mastery.png",
            "meta": "Content & Language Mastery",
            "subTitle": `Students are introduced summary writing, a new format of editing which requires students to identify and correct the errors. Written passages now covers a wider range of general knowledge topics to help students develop a global perspective and build content for use in their essay.`,
            "dataDelay": "0",
          },
          {
            "icon": "/images/site/icon/subject/skills.png",
            "meta": "Skills and Techniques",
            "subTitle": `Students are taught of essay writing techniques to incorporate personal thoughts and opinions. Students also learn summarizing skills which allow them to locate keywords within a text and paraphrase in a succinct manner.`,
            "dataDelay": "100",
          }],
      },
      {
        "subject": "Chinese",
        "description": "S1 Chinese focuses on introducing students to the O level examination format and papers. Hanyu Pinyin are no longer tested while huge emphasis is placed on comprehension paper. Students are also exposed to a new examinable paper type – email writing.",
        "subjectIcon": "/images/site/icon/subject/chinese.png",
        "content": [{
            "icon": "/images/site/icon/subject/mastery.png",
            "meta": "Content & Language Mastery",
            "subTitle": `Students will be taught the format of writing an email as required in their paper 1. Students are also encouraged to do more reading of Chinese passages to build their Chinese word bank.`,
            "dataDelay": "0",
          },
          {
            "icon": "/images/site/icon/subject/skills.png",
            "meta": "Skills and Techniques",
            "subTitle": `The email writing skills taught allows students to build their bilingual skills as they explore more overlaps between Chinese and English.`,
            "dataDelay": "100",
          }],
      },
      {
        "subject": "Math",
        "description": "S1 Math focuses on building upon primary school math, while introducing newer more complex concepts to prepare students for their next Mathematic national exams.",
        "subjectIcon": "/images/site/icon/subject/lower-sec-math.png",
        "content": [{
            "icon": "/images/site/icon/subject/mastery.png",
            "meta": "Content & Language Mastery",
            "subTitle": `S1 Math builds upon a wide range of topics like numeric operations, ratio, percentage, rate, speed and geometry. New topics like algebra, inequalities, graphs and mensuration are introduced. Students’ novel introduction to statistics and probability is at the end of the year where they learn simple data analysis and interpretation.`,
            "dataDelay": "0",
          },
          {
            "icon": "/images/site/icon/subject/skills.png",
            "meta": "Skills and Techniques",
            "subTitle": `Students are taught to master basic algebra with conceptual drills and questions of varying difficulty. Questions attempted are also more problem-based and requires a systematic and logical approach in answering.`,
            "dataDelay": "100",
          }],
      },
      {
        "subject": "Science",
        "description": "S1 Science focuses on building a strong foundation of the three sciences while also introducing experimental skills to grow their interest in science. ",
        "subjectIcon": "/images/site/icon/subject/lower-sec-science.png",
        "content": [{
            "icon": "/images/site/icon/subject/mastery.png",
            "meta": "Content & Language Mastery",
            "subTitle": `S1 Science introduces students to Laboratory Safety and Apparatus as well as cover scientific concepts from all 3 sciences ranging from Cells and Movement of Substances to Elements and Compounds to Physical Quantities, and Mass, Weight and Density.`,
            "dataDelay": "0",
          },
          {
            "icon": "/images/site/icon/subject/skills.png",
            "meta": "Skills and Techniques",
            "subTitle": `Students will gain question analysis skills to interpret information and answer the question appropriately. Students will also learn more about science experiments, how they are conducted and how to write and record experiment procedures.`,
            "dataDelay": "100",
          }],
      },
    ]
  },
  {
    "id": "secondary-2",
    "level": "Secondary 2",
    "subjects":[
      {
        "subject": "English",
        "description": "S2 English focuses on integration of more general knowledge while improving students’ vocabulary for added sophistication and summarizing skills.",
        "subjectIcon": "/images/site/icon/subject/english.png",
        "content": [{
            "icon": "/images/site/icon/subject/mastery.png",
            "meta": "Content & Language Mastery",
            "subTitle": `Written passages and in-class discussion continues to cover a wide range of current affairs topics to help students develop a global perspective and build content for use in their essay. Students attempt papers of diverse topics to apply discussed ideas. Grammatical exercises are also of priority to build a strong foundation and avoid loss of marks.`,
            "dataDelay": "0",
          },
          {
            "icon": "/images/site/icon/subject/skills.png",
            "meta": "Skills and Techniques",
            "subTitle": `Students’ confidence and writing skills are honed with practices to the various essay types. Students are also trained in their time management skills through practice tests in simulated exam conditions.`,
            "dataDelay": "100",
          }],
      },
      {
        "subject": "Chinese",
        "description": "S2 Chinese focuses on building students’ familiarity with the increased difficulty in Chinese papers.",
        "subjectIcon": "/images/site/icon/subject/chinese.png",
        "content": [{
            "icon": "/images/site/icon/subject/mastery.png",
            "meta": "Content & Language Mastery",
            "subTitle": `Continued exposure to Chinese characters in textbooks, passages and practices allows students to recognize, read and apply. Students are expected to be capable of reading text and passages smoothly.`,
            "dataDelay": "0",
          },
          {
            "icon": "/images/site/icon/subject/skills.png",
            "meta": "Skills and Techniques",
            "subTitle": `Students’ oral and listening skills will be tested as students prepare for their Secondary 2 exams while continued practices in the various composition and comprehension question types will build concise answering skills.`,
            "dataDelay": "100",
          }],
      },
      {
        "subject": "Math",
        "description": "S2 Math focuses on building upon S1 Math foundation while introducing new topics in geometry, trigonometry, and statistics. S2 Math program aims to equip students with the necessary skillset and knowledge to in preparation for upper secondary Math.",
        "subjectIcon": "/images/site/icon/subject/lower-sec-math.png",
        "content": [{
            "icon": "/images/site/icon/subject/mastery.png",
            "meta": "Content & Language Mastery",
            "subTitle": `S2 Math revisits and introduces more complex concepts in topics like ratio, algebra, functions, graphs and inequalities, data analysis. New topics this year includes understanding congruency, similarity, the Pythagoras theorem, introduction to trigonometry and also probability. `,
            "dataDelay": "0",
          },
          {
            "icon": "/images/site/icon/subject/skills.png",
            "meta": "Skills and Techniques",
            "subTitle": `Students are taught to identify question requirement and the appropriate method to use. Questions tends to be more application-based. Students are expected to build a habit to check their own answers to minimize careless errors and loss of marks. Revision will be focused on mastering all the lower secondary math topics to prepare students for their final exams.`,
            "dataDelay": "100",
          }],
      },
      {
        "subject": "Science",
        "description": "S2 Science focuses on a wider range of conceptual and application based questions to ensure students are well prepared for advanced concepts in their upper secondary level.",
        "subjectIcon": "/images/site/icon/subject/lower-sec-science.png",
        "content": [{
            "icon": "/images/site/icon/subject/mastery.png",
            "meta": "Content & Language Mastery",
            "subTitle": `S2 Science touches on more in-depth scientific concepts like Human System, Acids and Bases and Electricity.`,
            "dataDelay": "0",
          },
          {
            "icon": "/images/site/icon/subject/skills.png",
            "meta": "Skills and Techniques",
            "subTitle": `Students will learn how to analyse graphical data and utilize that information to answer the question while also attempt questions that requires higher order thinking skills. Students are also trained in their time management skills through practice tests in simulated exam conditions.`,
            "dataDelay": "100",
          },]
      },
    ]
  },
  {
    "id": "secondary-3",
    "level": "Secondary 3",
    "subjects":[
      {
        "subject": "English",
        "description": "S3 English focuses on the expression of students’ ideas and opinion with precision and persuasiveness. It is also crucial to build upon students’ exposure to current affairs even further while focusing on using appropriate tone and style for the relevant papers.",
        "subjectIcon": "/images/site/icon/subject/english.png",
        "content": [{
            "icon": "/images/site/icon/subject/mastery.png",
            "meta": "Content & Language Mastery",
            "subTitle": `Written passages and in-class discussion continues to cover a wide range of current affairs topics to help students develop a global perspective and build content for use in their essay. Students continue to attempt papers of diverse topics to apply discussed ideas. `,
            "dataDelay": "0",
          },
          {
            "icon": "/images/site/icon/subject/skills.png",
            "meta": "Skills and Techniques",
            "subTitle": `Students are taught to master their reasoning skills for writing mature text, while also summarizing ideas and concepts appropriately in a coherent and logical manner. Examination skills such as time management and concise answering techniques will be honed.`,
            "dataDelay": "100",
          }
        ],
      },
      {
        "subject": "Chinese",
        "description": "S3 Chinese focuses on building students’ confidence in attempting their Chinese papers.",
        "subjectIcon": "/images/site/icon/subject/chinese.png",
        "content": [{
            "icon": "/images/site/icon/subject/mastery.png",
            "meta": "Content & Language Mastery",
            "subTitle": `In-class discussion are held to expose students to a wide range of current affairs topics to help students develop a global perspective, build their Chinese vocabulary and build content for use in their essay.`,
            "dataDelay": "0",
          },
          {
            "icon": "/images/site/icon/subject/skills.png",
            "meta": "Skills and Techniques",
            "subTitle": `Numerous practice questions are given to build students’ familiarity in the answering techniques for the different question types in their composition and comprehension papers.`,
            "dataDelay": "100",
          }
        ],
      },
      {
        "subject": "E. Math",
        "description": "S3 E math is a continuation of Mathematics from lower secondary. More complex concepts are taught while essential mathematical presentation and analytical skills are honed.",
        "subjectIcon": "/images/site/icon/subject/e-math.png",
        "content": [{
            "icon": "/images/site/icon/subject/mastery.png",
            "meta": "Content & Language Mastery",
            "subTitle": `S3 E Math revises and expand students’ knowledge on topics from the lower secondary math. New concepts include indices, coordinate geometry, set notation, matrices, properties of circles and further trigonometry and its application.`,
            "dataDelay": "0",
          },
          {
            "icon": "/images/site/icon/subject/skills.png",
            "meta": "Skills and Techniques",
            "subTitle": `Students are taught to integrate existing knowledge with upper secondary topics while honing their presentation skills. Building of good habits like double checking their own answers is also crucial to minimize the loss of marks. `,
            "dataDelay": "100",
          }
        ],
      },
      {
        "subject": "A. Math",
        "description": "S3 A Math is a set of concepts that is more advanced than E Math. Problem sums require longer more complicated formulae to solve. Nonetheless, the underlying mathematical technique is highly similar to E Math and guidance will be provided to students. ",
        "subjectIcon": "/images/site/icon/subject/a-math.png",
        "content": [{
            "icon": "/images/site/icon/subject/mastery.png",
            "meta": "Content & Language Mastery",
            "subTitle": `S3 A Math focuses largely on advanced trigonometry with concepts on addition formula, double-angle formula, and R formula and drawing of the sine and cosine graphs. Non-trigonometry concepts are still covered, and these includes inequalities, indices and surds, polynomials and partial fractions and binomial expansions.`,
            "dataDelay": "0",
          },
          {
            "icon": "/images/site/icon/subject/skills.png",
            "meta": "Skills and Techniques",
            "subTitle": `Manipulation of symbols and functions is a crucial skill in A Math. Students are also frequently expected to prove expressions using identities and formulae and communicate inferences and conclusions to problem sums.`,
            "dataDelay": "100",
          }
        ],
      },
      {
        "subject": "Combined Phy/Chem",
        "description": "S3 Combined P/C focuses on providing a balanced revision for both Physics and Chemistry as catered to the Combined P/C syllabus.",
        "subjectIcon": "/images/site/icon/subject/upper-sec-comb-science.png",
        "content": [{
            "icon": "/images/site/icon/subject/mastery.png",
            "meta": "Content & Language Mastery",
            "subTitle": `S3 Combined P/C touches on chemistry topics like Atomic Structure and Stoichiometry while managing physics topics like Kinematics, Thermal Physics, and Dynamics.`,
            "dataDelay": "0",
          },
          {
            "icon": "/images/site/icon/subject/skills.png",
            "meta": "Skills and Techniques",
            "subTitle": `Students will be exposed to reasoning and conceptual application skills with dedicated focus on concept application and examination skills that are necessary for the combined science level.`,
            "dataDelay": "100",
          }
        ],
      },
      {
        "subject": "Pure Chemistry",
        "description": "S3 Pure Chem focuses on building students’ Chemistry knowledge as a cumulative curriculum.  A strong foundation is crucial for their upper level Chemistry.",
        "subjectIcon": "/images/site/icon/subject/chemistry.png",
        "content": [{
            "icon": "/images/site/icon/subject/mastery.png",
            "meta": "Content & Language Mastery",
            "subTitle": `S3 Pure Chem reinforces students’ foundation with topics such as Atomic Structure and Stoichiometry that is helpful for their theory and practical papers.`,
            "dataDelay": "0",
          },
          {
            "icon": "/images/site/icon/subject/skills.png",
            "meta": "Skills and Techniques",
            "subTitle": `Students will be coached and guided in problem solving strategies and answering techniques on a wide range of exam questions. Through these repeated practices, students will explored the multiple ways of solving so students can reapply into the various question types.`,
            "dataDelay": "100",
          }
        ],
      },
      {
        "subject": "Pure Physics",
        "description": "S3 Pure Physics focuses on deconstructing complex Physics concepts and allowing the students to learn them in a well-paced, structured manner.",
        "subjectIcon": "/images/site/icon/subject/physics.png",
        "content": [{
            "icon": "/images/site/icon/subject/mastery.png",
            "meta": "Content & Language Mastery",
            "subTitle": `S3 Pure Physics explores topics such as Kinematics, Pressure, Thermal Properties of Matter, General Wave Properties and Electromagnetic Spectrum in depth.`,
            "dataDelay": "0",
          },
          {
            "icon": "/images/site/icon/subject/skills.png",
            "meta": "Skills and Techniques",
            "subTitle": `Students are taught to develop an understanding of the role of Physics in scientific issues and relevant applications of Physics, which is beneficial to prepare them for their practical and theory papers.`,
            "dataDelay": "100",
          }
        ],
      },
    ]
  },
  {
    "id": "secondary-4",
    "level": "Secondary 4",
    "subjects":[
      {
        "subject": "English",
        "description": "S4 English focuses on meeting the expectation of the 'N' or ‘O’ Level English paper while pushing students to stand out in their essays and attempt their comprehension and communication examinations with confidence.",
        "subjectIcon": "/images/site/icon/subject/english.png",
        "content": [{
            "icon": "/images/site/icon/subject/mastery.png",
            "meta": "Content & Language Mastery",
            "subTitle": `Even more complex written passages and more recent current affairs in-class discussion continues to make the bulk of lesson time to help students prepare for their final exams. Students continue to attempt papers of diverse topics to apply discussed ideas. `,
            "dataDelay": "0",
          },
          {
            "icon": "/images/site/icon/subject/skills.png",
            "meta": "Skills and Techniques",
            "subTitle": `Students are taught to master their critical evaluation, summarizing and comprehension skills on a wide range of topics, while also organising and supporting arguments in a coherent and logical manner to substantiate arguments.`,
            "dataDelay": "100",
          },
          {
            "icon": "/images/site/icon/subject/reinforcement.png",
            "meta": "Revision and Reinforcement",
            "subTitle": `Revision will be focused on mastering the individual 'N' or ‘O’ Level paper requirements and honing time management skills through practice tests in simulated exam conditions. Discussion on key topics will be held to build students’ repository of general knowledge and current affairs.`,
            "dataDelay": "200",
          },
        ],
      },
      {
        "subject": "Chinese",
        "description": "S4 Chinese focuses on meeting the expectation of the ‘O’ Level Chinese paper while building students’ examinations confidence.",
        "subjectIcon": "/images/site/icon/subject/chinese.png",
        "content": [{
            "icon": "/images/site/icon/subject/mastery.png",
            "meta": "Content & Language Mastery",
            "subTitle": `Lesson plan will be tailored according to the O level schedule, with focus on the May-July examination. Additional lessons can also be tailored for students who wishes to retake their Chinese in the year end examination.`,
            "dataDelay": "0",
          },
          {
            "icon": "/images/site/icon/subject/skills.png",
            "meta": "Skills and Techniques",
            "subTitle": `Specific skills to master the individual ‘O’ Level paper requirements will be taught to students. Personalized feedback and lesson plan will be provided to students in this final countdown period.`,
            "dataDelay": "100",
          },
          {
            "icon": "/images/site/icon/subject/reinforcement.png",
            "meta": "Revision and Reinforcement",
            "subTitle": `Holistic revision, ranging from written text to comprehension to oral and listening will be the focus for the entire year. Students’ time management skills will be honed through practice tests in simulated exam conditions.`,
            "dataDelay": "200",
          },
        ],
      },
      {
        "subject": "E. Math",
        "description": "S4 E. Math focuses on meeting the expectation of the ‘O’ Level E Math syllabus. Revision for the entire ‘O’ Level syllabus will be carried out in the latter half of the year.",
        "subjectIcon": "/images/site/icon/subject/e-math.png",
        "content": [{
            "icon": "/images/site/icon/subject/mastery.png",
            "meta": "Content & Language Mastery",
            "subTitle": `S4 E. Math continues to introduce new concepts like vectors, radian measurement and additional statistical plots. Familiarization of the equations in the mathematical formulae is also crucial to become exam ready.`,
            "dataDelay": "0",
          },
          {
            "icon": "/images/site/icon/subject/skills.png",
            "meta": "Skills and Techniques",
            "subTitle": `Identification of topics and question requirements is prioritized, along with practicing ample examination questions for exposure. `,
            "dataDelay": "100",
          },
          {
            "icon": "/images/site/icon/subject/reinforcement.png",
            "meta": "Revision and Reinforcement",
            "subTitle": `Intensive revision will be focused on mastering all the E Math concepts through topical revision packages. Examination skills such as time management and error analysis will be honed through practice tests.`,
            "dataDelay": "200",
          }],
      },
      {
        "subject": "A. Math",
        "description": "S4 A Math focuses on meeting the expectation of the ‘O’ Level A Math syllabus. Revision for the entire ‘O’ Level syllabus will be carried out in the later half of the year.",
        "subjectIcon": "/images/site/icon/subject/a-math.png",
        "content": [{
            "icon": "/images/site/icon/subject/mastery.png",
            "meta": "Content & Language Mastery",
            "subTitle": `S4 A Math continues to introduce more concepts, namely calculus, geometric theorems, and application-based kinematics.`,
            "dataDelay": "0",
          },
          {
            "icon": "/images/site/icon/subject/skills.png",
            "meta": "Skills and Techniques",
            "subTitle": `Problem sums that involving real-world situations and combines the knowledge of multiple concepts are a top priority to prepare students for the challenging final exam.`,
            "dataDelay": "100",
          },
          {
            "icon": "/images/site/icon/subject/reinforcement.png",
            "meta": "Revision and Reinforcement",
            "subTitle": `Intensive revision will be focused on mastering all the A Math concepts through topical revision packages. Examination skills such as time management and error analysis will be honed through practice tests.`,
            "dataDelay": "200",
          }],
      },
      {
        "subject": "Combined Phy/Chem",
        "description": "S4 Combined P/C focuses on meeting the expectation of the ‘O’ Level Combined P/C syllabus. Revision for the entire ‘O’ Level syllabus will be carried out in the later half of the year.",
        "subjectIcon": "/images/site/icon/subject/upper-sec-comb-science.png",
        "content": [{
            "icon": "/images/site/icon/subject/mastery.png",
            "meta": "Content & Language Mastery",
            "subTitle": `S4 Combined P/C continues to build student knowledge with chemistry topics like redox and Organic Chemistry while also touching on physics topics like Electricity and Magnetism.`,
            "dataDelay": "0",
          },
          {
            "icon": "/images/site/icon/subject/skills.png",
            "meta": "Skills and Techniques",
            "subTitle": `Emphasis will be on ensuring student fully grasp the basic concepts in each chapter and thus, practice questions focus on answering common question types in each and every chapter.`,
            "dataDelay": "100",
          },
          {
            "icon": "/images/site/icon/subject/reinforcement.png",
            "meta": "Revision and Reinforcement",
            "subTitle": `Intensive revision will be focused on mastering all the Combined P/C concepts through topical revision packages. Examination skills such as time management and error analysis will be honed through practice tests.`,
            "dataDelay": "200",
          },
        ],
      },
      {
        "subject": "Pure Chemistry",
        "description": "S4 Pure Chemistry focuses on meeting the expectation of the ‘O’ Level Pure Chemistry syllabus. Revision for the entire ‘O’ Level syllabus will be carried out in the later half of the year.",
        "subjectIcon": "/images/site/icon/subject/chemistry.png",
        "content": [{
            "icon": "/images/site/icon/subject/mastery.png",
            "meta": "Content & Language Mastery",
            "subTitle": `S4 Pure Chemistry continues to build on students’ knowledge with new topics like Organic Chemistry and Electrochemistry and continues to integrate S3 Chemistry topics into these new topics.`,
            "dataDelay": "0",
          },
          {
            "icon": "/images/site/icon/subject/skills.png",
            "meta": "Skills and Techniques",
            "subTitle": `Students are taught to integrate existing knowledge with upper secondary topics while honing their presentation skills. Building of good habits like double checking their own answers is also crucial to minimize the loss of marks.`,
            "dataDelay": "100",
          },
          {
            "icon": "/images/site/icon/subject/reinforcement.png",
            "meta": "Revision and Reinforcement",
            "subTitle": `Intensive revision will be focused on mastering all the Pure Chemistry concepts through topical revision packages. Examination skills such as time management and error analysis will be honed through practice tests.`,
            "dataDelay": "200",
          }],
      },
      {
        "subject": "Pure Physics",
        "description": "S4 Pure Physics focuses on meeting the expectation of the ‘O’ Level Pure Physics syllabus. Revision for the entire ‘O’ Level syllabus will be carried out in the later half of the year.",
        "subjectIcon": "/images/site/icon/subject/physics.png",
        "content": [{
            "icon": "/images/site/icon/subject/mastery.png",
            "meta": "Content & Language Mastery",
            "subTitle": `S4 Pure Physics touches on new topics such as Static Electricity, Magnetism, Electromagnetism and Electromagnetic Induction. `,
            "dataDelay": "0",
          },
          {
            "icon": "/images/site/icon/subject/skills.png",
            "meta": "Skills and Techniques",
            "subTitle": `Students will be coached and guided in problem solving strategies and answering techniques on a wide range of exam questions. Through these repeated practices, students will explored the multiple ways of solving so students can reapply into the various question types.`,
            "dataDelay": "100",
          },
          {
            "icon": "/images/site/icon/subject/reinforcement.png",
            "meta": "Revision and Reinforcement",
            "subTitle": `Intensive revision will be focused on mastering all the Pure Physics concepts through topical revision packages. Examination skills such as time management and error analysis will be honed through practice tests.`,
            "dataDelay": "200",
          }],
      },
    ]
  },
  {
    "id": "junior-college-1",
    "level": "Junior College 1",
    "subjects":[
      {
        "subject": "General Paper (GP)",
        "description": "Our JC1 GP focuses on developing students’ critical reading, writing and thinking skills for quick and accurate analysis of for their GP paper. Focus will also be on exposure to current affairs which is essential for the JC syllabus in building content knowledge for their papers.",
        "subjectIcon": "/images/site/icon/subject/jc-gp.png",
        "content": [{
            "icon": "/images/site/icon/subject/mastery.png",
            "meta": "Content & Language Mastery",
            "subTitle": `In our JC1 GP, we focus on understanding the individual requirements for the GP paper, mainly argumentative writing, comprehension, summary and application question.`,
            "dataDelay": "0",
          },
          {
            "icon": "/images/site/icon/subject/skills.png",
            "meta": "Skills and Techniques",
            "subTitle": `In our JC1 GP, we focus on honing students’ critical reasoning and writing skills to express ideas clearly. Student’s inference and summarizing skills will be honed, and they will also be trained to apply knowledge and understanding of issues in addressing specific task(s).`,
            "dataDelay": "100",
          }
        ],
      },
      {
        "subject": "Chinese",
        "description": "JC1 Chinese focuses on meeting the expectation of the ‘A’ Level Chinese paper where the main differences are combination of composition and comprehension in Paper 1, which includes a new summary writing component.",
        "subjectIcon": "/images/site/icon/subject/chinese.png",
        "content": [{
            "icon": "/images/site/icon/subject/mastery.png",
            "meta": "Content & Language Mastery",
            "subTitle": `Students will be taught more about the difference in exam format with focus on the Mid-Year A Level paper. Additional lessons can also be tailored for students who wishes to retake their Chinese in the year end examination. `,
            "dataDelay": "0",
          },
          {
            "icon": "/images/site/icon/subject/skills.png",
            "meta": "Skills and Techniques",
            "subTitle": `Specific skills to master the individual ‘A’ Level paper requirements will be taught and personalized feedback and lesson plan will be provided to students.`,
            "dataDelay": "100",
          },
          {
            "icon": "/images/site/icon/subject/reinforcement.png",
            "meta": "Revision and Reinforcement",
            "subTitle": `Revision will be focused on mastering the individual ‘A’ Level paper requirements and honing time management skills through practice tests in simulated exam conditions.`,
            "dataDelay": "200",
          },
        ],
      },
      {
        "subject": "H1/H2 Math",
        "description": "In our JC1 math, teaching is paced to help students cope better with the intensity and the additional questions and explanation during our lessons can effectively help students understand better.",
        "subjectIcon": "/images/site/icon/subject/jc-math.png",
        "content": [{
            "icon": "/images/site/icon/subject/mastery.png",
            "meta": "Content & Language Mastery",
            "subTitle": `In our JC1 H1 Math, pure math topics such as Calculus, equations and inequalities and exponential, logarithmic function are covered. Meanwhile JC1 H2 math students learn the more advanced pure math topics such as functions, graphs, Sequences and Series, vectors, complex numbers, and advanced calculus.`,
            "dataDelay": "0",
          },
          {
            "icon": "/images/site/icon/subject/skills.png",
            "meta": "Skills and Techniques",
            "subTitle": `In our JC1 math, students are taught to master the use of their Graphic Calculator (GC) to solve and check for answers. They are also taught to identify the topic for each question and integrate various concepts to present answers.`,
            "dataDelay": "100",
          }
        ],
      },
      {
        "subject": "H1/H2 Chemistry",
        "description": "Our JC1 Chemistry program acts as a bridging program for students from their ‘O’ level to ‘A’ Level. Certain ‘O’ level topics will be revisited and explored deeper.",
        "subjectIcon": "/images/site/icon/subject/jc-chemistry.png",
        "content": [{
            "icon": "/images/site/icon/subject/mastery.png",
            "meta": "Content & Language Mastery",
            "subTitle": `For JC1 Chemistry, VSEPR model, structure and bonding of molecules are covered as bridging modules from O Level. JC1 H1 Chemistry also cover the basics of Kinetics, Equilibrium, Energetics and parts of Organic Chemistry. H2 Chemistry cover the above-mentioned chapters in full depth, while exploring Solubility Equilibria, Entropy, and Titration.`,
            "dataDelay": "0",
          },
          {
            "icon": "/images/site/icon/subject/skills.png",
            "meta": "Skills and Techniques",
            "subTitle": `In our JC1 Chemistry program, students are taught to master the identification of the concept behind each question. They are honed to take note of key words in questions and understand the question requirements.`,
            "dataDelay": "100",
          }
        ],
      },
      {
        "subject": "H1/H2 Physics",
        "description": "Our JC1 Physics program acts as a bridging program for students from their ‘O’ level to ‘A’ Level. Certain ‘O’ level topics will be revisited and explored deeper.",
        "subjectIcon": "/images/site/icon/subject/jc-physics.png",
        "content": [{
            "icon": "/images/site/icon/subject/mastery.png",
            "meta": "Content & Language Mastery",
            "subTitle": `Our JC1 H1 Physics focuses on a broad range of introductory topics covering the whole of measurements, kinematics and Dynamics, which builds the foundation for the later part of the subject. H2 Physics explores additional topics in Newtonian Mechanics, Thermal Physics and Oscillations and waves. Lessons are held with simulations, demonstrations, and videos where we show how the physics concepts can be applied in everyday situations and use relevant examples to allow students to understand the theoretical concepts better.`,
            "dataDelay": "0",
          },
          {
            "icon": "/images/site/icon/subject/skills.png",
            "meta": "Skills and Techniques",
            "subTitle": `In our JC1 Physics program, students are taught to master the identification of the concept behind each question. They are honed to take note of key words in questions and understand the question requirements.`,
            "dataDelay": "100",
          }
        ],
      },
      {
        "subject": "H1/H2 Economics",
        "description": "JC Economics, as a brand-new topic to students, will be taught from scratch to students. Our program helps students with content understanding and organising and supporting of their arguments in a succinct and logical manner.",
        "subjectIcon": "/images/site/icon/subject/jc-economics.png",
        "content": [{
            "icon": "/images/site/icon/subject/mastery.png",
            "meta": "Content & Language Mastery",
            "subTitle": `JC1 will focus on the microeconomics analysis (individual and societal levels) where idea of unlimited wants, limited resources and knowledge of how firms and markets function are introduced to students. H1 Economics is a lighter load than H2 - with lesser content, with the removal of topics such as Firms and Decisions, and are only examinable on case studies.`,
            "dataDelay": "0",
          },
          {
            "icon": "/images/site/icon/subject/skills.png",
            "meta": "Skills and Techniques",
            "subTitle": `Our JC1 economics explicitly teach the relevant economic skills like the tools and methods of analysis used by economists, the ability to explain and analyse economic issues and evaluate perspectives and decisions of economic agents.`,
            "dataDelay": "100",
          }
        ],
      },
    ]
  },
  {
    "id": "junior-college-2",
    "level": "Junior College 2",
    "subjects":[
      {
        "subject": "General Paper (GP)",
        "description": "Our JC2 GP aims to strengthen students’ understanding of current affairs even further and focuses on using appropriate tone and style to the context, task, and audience. Emphasis will be placed on the construction of cogent arguments and formulate informed and insightful personal responses.",
        "subjectIcon": "/images/site/icon/subject/jc-gp.png",
        "content": [{
            "icon": "/images/site/icon/subject/mastery.png",
            "meta": "Content & Language Mastery",
            "subTitle": `In the second year, emphasis will be on strengthening students’ understanding of current affairs and trends as well as gain ample practice with 'A' level question types. Key components of the paper covered includes composition, comprehension, summary and application questions.`,
            "dataDelay": "0",
          },
          {
            "icon": "/images/site/icon/subject/skills.png",
            "meta": "Skills and Techniques",
            "subTitle": `In the second year, students are taught to master their critical evaluation and reasoning skills for written text, while also organizing and supporting arguments in a coherent and logical manner to substantiate arguments.`,
            "dataDelay": "100",
          },
          {
            "icon": "/images/site/icon/subject/reinforcement.png",
            "meta": "Revision and Reinforcement",
            "subTitle": `In the second year, revision will be focused on mastering the individual H1 GP paper requirements and honing time management skills through practice tests in simulated exam conditions. Discussion on key topics will be held to build students’ repository of general knowledge.`,
            "dataDelay": "200",
          }
        ],
      },
      {
        "subject": "H1/H2 Math",
        "description": "In the second year, emphasis is placed on the revision of pure mathematics and introduction to new statistics concepts. Revision for the entire ‘A’ Level syllabus will be carried out in the later half of the year.",
        "subjectIcon": "/images/site/icon/subject/jc-math.png",
        "content": [{
            "icon": "/images/site/icon/subject/mastery.png",
            "meta": "Content & Language Mastery",
            "subTitle": `In the second year, our JC math focuses on statical mathematics. In H1, topics such as computation of probability, binomial and normal distributions, sampling, and hypothesis testing as well as correlation and regression are covered. JC2 H2 math is highly similar with an additional topic in general and specific discrete distribution models.`,
            "dataDelay": "0",
          },
          {
            "icon": "/images/site/icon/subject/skills.png",
            "meta": "Skills and Techniques",
            "subTitle": `In the second year, we prioritise tutorials questions involving real-world situations that combines the knowledge of multiple concepts. We also practice using the GC for answering of statistics-related questions and learn to identify question trends and marking criteria through commonly tested exam questions.`,
            "dataDelay": "100",
          },
          {
            "icon": "/images/site/icon/subject/reinforcement.png",
            "meta": "Revision and Reinforcement",
            "subTitle": `In the second year, revision will be focused on mastering all the H1/2 Math topics through topical revision packages. Examination skills such as time management and error analysis will be honed through practice tests.`,
            "dataDelay": "200",
          }],
      },
      {
        "subject": "H1/H2 Chemistry",
        "description": "Our JC2 topics will be a continuation of the ‘A’ Level Chemistry syllabus. Revision for the entire ‘A’ Level syllabus will be conducted in the later half of the year.",
        "subjectIcon": "/images/site/icon/subject/jc-chemistry.png",
        "content": [{
            "icon": "/images/site/icon/subject/mastery.png",
            "meta": "Content & Language Mastery",
            "subTitle": `JC2 H1 Chemistry continue to touch on organic chemistry topics on halogens, carboxylic acid, hydroxy derivatives at a reduced level. H1 syllabus ends with the easier topics on polymer chemistry and nanomaterials while H2 Chemistry continues to explore electrochemistry and transition elements.`,
            "dataDelay": "0",
          },
          {
            "icon": "/images/site/icon/subject/skills.png",
            "meta": "Skills and Techniques",
            "subTitle": `In the second year, identification of topics and question requirements will still be a priority, along with practicing ample examination questions for exposure.`,
            "dataDelay": "100",
          },
          {
            "icon": "/images/site/icon/subject/reinforcement.png",
            "meta": "Revision and Reinforcement",
            "subTitle": `In the second year, revision will be focused on mastering all the H1/2 Chemistry topics through topical revision packages. Examination skills such as time management and error analysis will be honed through practice tests.`,
            "dataDelay": "200",
          }],
      },
      {
        "subject": "H1/H2 Physics",
        "description": "Our JC2 topics will be a continuation of the ‘A’ Level Chemistry syllabus. Revision for the entire ‘A’ Level syllabus will be conducted in the later half of the year.",
        "subjectIcon": "/images/site/icon/subject/jc-physics.png",
        "content": [{
            "icon": "/images/site/icon/subject/mastery.png",
            "meta": "Content & Language Mastery",
            "subTitle": `In JC2, Electricity and Magnetism as well as Modern Physics will be covered and revision for the final exams will commence. H1 Physics students will learn selected topics from these themes while H2 students will learn the entire syllabus.`,
            "dataDelay": "0",
          },
          {
            "icon": "/images/site/icon/subject/skills.png",
            "meta": "Skills and Techniques",
            "subTitle": `In the second year, identification of topics and question requirements will still be a priority, along with practicing ample examination questions for exposure.`,
            "dataDelay": "100",
          },
          {
            "icon": "/images/site/icon/subject/reinforcement.png",
            "meta": "Revision and Reinforcement",
            "subTitle": `In the second year, revision will be focused on mastering all the H1/2 Physics topics through topical revision packages. Examination skills such as time management and error analysis will be honed through practice tests.`,
            "dataDelay": "200",
          }],
      },
      {
        "subject": "H1/H2 Economics",
        "description": "JC2 syllabus will progress to macroeconomics analysis (national level). Extensive use of real-world examples and exposure to case-study analysis to prepare students for their final examinations.",
        "subjectIcon": "/images/site/icon/subject/jc-economics.png",
        "content": [{
            "icon": "/images/site/icon/subject/mastery.png",
            "meta": "Content & Language Mastery",
            "subTitle": `In the second year, H1 Economics students will cover basic macroeconomics analysis like Aggregate Demand, Aggregate Supply, Standard of Living, as well as 3 out of the 4 macroeconomic aims. H2 Economics focuses on macroeconomics analysis, international trade and globalisation. It provides an overview of the workings and linkages of the national and international economy. In addition, the impact of external trends and developments on the domestic regional and global economies, and their implications for policy choices and decisions of governments, are discussed.`,
            "dataDelay": "0",
          },
          {
            "icon": "/images/site/icon/subject/skills.png",
            "meta": "Skills and Techniques",
            "subTitle": `In the second year, carry-over writing, analytical and essay writing skills will be applied to the new topics taught. These skills will be refined as more practice questions are performed under timed situations. Links between the first year and second year topic will also be made clear.`,
            "dataDelay": "100",
          },
          {
            "icon": "/images/site/icon/subject/reinforcement.png",
            "meta": "Revision and Reinforcement",
            "subTitle": `In the second year, revision will be focused on mastering all the H1/2 Economics topics through topical revision packages. Examination skills such as time management and error analysis will be honed through practice tests.`,
            "dataDelay": "200",
          }],
      },
    ]
  },

];

const LevelSubjectFeature = (props) => {
  return (
    <>
      <Tabs>
        <TabList>
          {SubjectContent.find((item) => item.id===props.level).subjects.map((val) => (
            <Tab>
              <div className="d-flex align-items-center">
                <img className="fancy-feature-subject-icon" src={`${val.subjectIcon}`} alt="icon" />
                <p>{val.subject}</p>
              </div>
            </Tab>
          ))}
        </TabList>

        <div className="mt-50 sm-mt-30">
        {SubjectContent.find((item) => item.id===props.level).subjects.map(
          (obj, idx) => (
            <TabPanel>
              <div className="inner-container">
                <div className="container">
                  <div className="row">
                    <div className="col-12">
                      <div className="title-style-seven text-center mt-20">
                        <h2>
                        <img className="fancy-feature-subject-icon-header" src={`${obj.subjectIcon}`} alt="icon" /> <span>{obj.subject}</span>
                        </h2>
                        <p>{obj.description}</p>
                        <div className="inner-content">
                        <div className="row justify-content-center">
                          {(obj.content.map((val, i) => (
                            <div
                              className={`col-lg-${12 / obj.content.length} col-md-6`}
                              key={i}
                              data-aos="fade-up"
                              data-aos-duration="1200"
                              data-aos-delay={val.dataDelay}
                            >
                              <div className="block-style-five">
                                <div className="icon">
                                  <img src={`${val.icon}`} alt="icon" />
                                </div>
                                <h6 className="title">
                                  <span>{val.meta}</span>
                                </h6>
                                <p>{val.subTitle}</p>
                              </div>
                            </div>
                          )))}
                        </div>
                      </div>
                      <p><span className="fa fa-arrow-up"></span> Click on the tabs above to find out more about other subjects!</p>
                      <a href="https://wa.me/+6590625285?lang=en" target="_blank" rel="noreferrer"
                        className="theme-btn-five mt-50 md-mt-30"
                        onClick={() => AnalyticsEvent({
                          category: "WhatsAppEnquire",
                          action: "Click",
                          source: "/programmes/"+SubjectContent.find((item) => item.id===props.level).id,
                          label: props.level + "_" + obj.subject
                        })}
                      >
                        <i className="fa fa-whatsapp"></i> Enquire Now
                      </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </TabPanel>
        ))}
        </div>
        {/* /.pricing-table-area-six */}
      </Tabs>
    </>
  );
};

export default LevelSubjectFeature;