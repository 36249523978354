import React from "react";
import { Helmet } from "react-helmet";
import Header from "../../../components/shared/Header";
import Footer from "../../../components/shared/Footer";
import LevelSubjectFeature from "../../../components/programmes/level/LevelSubjectFeature";
import AnalyticsEvent from "../../../components/shared/AnalyticsEvent";

const LevelList = [
  {
    level: "primary-1",
    eventId: "p1",
    header: "Primary School Tuition",
    title: "Primary 1",
  },
  {
    level: "primary-2",
    eventId: "p2",
    header: "Primary School Tuition",
    title: "Primary 2",
  },
  {
    level: "primary-3",
    eventId: "p3",
    header: "Primary School Tuition",
    title: "Primary 3",
  },
  {
    level: "primary-4",
    eventId: "p4",
    header: "Primary School Tuition",
    title: "Primary 4",
  },
  {
    level: "primary-5",
    eventId: "p5",
    header: "Primary School Tuition",
    title: "Primary 5",
  },
  {
    level: "primary-6",
    eventId: "p6",
    header: "Primary School Tuition",
    title: "Primary 6",
  },
  {
    level: "secondary-1",
    eventId: "sec1",
    header: "Secondary School Tuition",
    title: "Secondary 1",
  },
  {
    level: "secondary-2",
    eventId: "sec2",
    header: "Secondary School Tuition",
    title: "Secondary 2",
  },
  {
    level: "secondary-3",
    eventId: "sec3",
    header: "Secondary School Tuition",
    title: "Secondary 3",
  },
  {
    level: "secondary-4",
    eventId: "sec4",
    header: "Secondary School Tuition",
    title: "Secondary 4",
  },
  {
    level: "junior-college-1",
    eventId: "jc1",
    header: "Junior College Tuition",
    title: "Junior College 1",
  },
  {
    level: "junior-college-2",
    eventId: "jc2",
    header: "Junior College Tuition",
    title: "Junior College 2",
  },
];

const Level = ({ level }) => {
  return (
    <div className="main-page-wrapper p0">
      <Helmet>
        <title>
          { LevelList.find((item) => item.level===level).title } | { LevelList.find((item) => item.level===level).header }
        </title>
      </Helmet>
      
      <Header />
      
      {/* 	=============================================
				Fancy Hero Three
			============================================== */}
      <div className="fancy-hero-three bg-transparent mb-100">
        <div className="shapes shape-one"></div>
        <div className="shapes shape-two"></div>
        <div className="shapes shape-three"></div>
        <div className="shapes shape-four"></div>
        <div className="shapes shape-five"></div>
        <div className="shapes shape-six"></div>
        <div className="bg-wrapper">
          <div className="container">
            <div className="row">
              <div className="col-xl-9 col-lg-10 col-md-10 m-auto">
                <h1 className="heading">{LevelList.find((item) => item.level===level).title}</h1>
                <p className="sub-heading">
                  Subjects offered by Eduz Tuition
                </p>
                <div className="heading">
                  <a href={`/curriculum-package/${LevelList.find((item) => item.level===level).eventId}`} className="btn" target="_blank" rel="noopener noreferrer"
                    onClick={() => AnalyticsEvent({
                      category: "CurriculumDownload",
                      action: "Click",
                      source: "/programmes/"+level,
                      label: LevelList.find((item) => item.level===level).eventId
                    })}
                    >
                      Download {LevelList.find((item) => item.level===level).title} Curriculum Package &nbsp;
                      <span className="fa fa-download"></span> 
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* /.bg-wrapper  */}
      </div>

      <div className="fancy-feature-subject" id="product">
        <div className="bg-wrapper">
          <div className="container">
            <div className="sldier-wrapper">
              <LevelSubjectFeature level={level} />
            </div>
            {/* /.sldier-wrapper */}
          </div>
        </div>
        {/* /.bg-wrapper */}
      </div>
      <Footer/>
    </div>
  );
};

export default Level;
