import React from "react";

const blog_202308 = [
  {
    "img": "/images/site/blog/20230730_building-stronger-work-relationships-1.png",
    "tag": "Lifestyle",
    "title": "",
    "slug": "",
    "date": "30 August 2023",
    "publishDate": "2023-08-30T12:00:00.000+08:00",
    "content":
    <React.Fragment>
    </React.Fragment>
  },
  {
    "img": "/images/site/blog/20230730_building-stronger-work-relationships-1.png",
    "tag": "Lifestyle",
    "title": "",
    "slug": "",
    "date": "27 August 2023",
    "publishDate": "2023-08-27T12:00:00.000+08:00",
    "content":
    <React.Fragment>
    </React.Fragment>
  },
  {
    "img": "/images/site/blog/20230730_building-stronger-work-relationships-1.png",
    "tag": "Lifestyle",
    "title": "",
    "slug": "",
    "date": "26 August 2023",
    "publishDate": "2023-08-26T12:00:00.000+08:00",
    "content":
    <React.Fragment>
    </React.Fragment>
  },
  {
    "img": "/images/site/blog/20230730_building-stronger-work-relationships-1.png",
    "tag": "Lifestyle",
    "title": "",
    "slug": "",
    "date": "23 August 2023",
    "publishDate": "2023-08-23T12:00:00.000+08:00",
    "content":
    <React.Fragment>
    </React.Fragment>
  },
  {
    "img": "/images/site/blog/20230730_building-stronger-work-relationships-1.png",
    "tag": "Lifestyle",
    "title": "",
    "slug": "",
    "date": "20 August 2023",
    "publishDate": "2023-08-20T12:00:00.000+08:00",
    "content":
    <React.Fragment>
    </React.Fragment>
  },
  {
    "img": "/images/site/blog/20230730_building-stronger-work-relationships-1.png",
    "tag": "Lifestyle",
    "title": "",
    "slug": "",
    "date": "19 August 2023",
    "publishDate": "2023-08-19T12:00:00.000+08:00",
    "content":
    <React.Fragment>
    </React.Fragment>
  },
  {
    "img": "/images/site/blog/20230730_building-stronger-work-relationships-1.png",
    "tag": "Lifestyle",
    "title": "",
    "slug": "",
    "date": "16 August 2023",
    "publishDate": "2023-08-16T12:00:00.000+08:00",
    "content":
    <React.Fragment>
    </React.Fragment>
  },
  {
    "img": "/images/site/blog/20230730_building-stronger-work-relationships-1.png",
    "tag": "Lifestyle",
    "title": "",
    "slug": "",
    "date": "13 August 2023",
    "publishDate": "2023-08-13T12:00:00.000+08:00",
    "content":
    <React.Fragment>
    </React.Fragment>
  },
  {
    "img": "/images/site/blog/20230730_building-stronger-work-relationships-1.png",
    "tag": "Lifestyle",
    "title": "",
    "slug": "",
    "date": "12 August 2023",
    "publishDate": "2023-08-12T12:00:00.000+08:00",
    "content":
    <React.Fragment>
    </React.Fragment>
  },
  {
    "img": "/images/site/blog/20230730_building-stronger-work-relationships-1.png",
    "tag": "Lifestyle",
    "title": "",
    "slug": "",
    "date": "9 August 2023",
    "publishDate": "2023-08-09T12:00:00.000+08:00",
    "content":
    <React.Fragment>
    </React.Fragment>
  },
  {
    "img": "/images/site/blog/20230730_building-stronger-work-relationships-1.png",
    "tag": "Lifestyle",
    "title": "",
    "slug": "",
    "date": "6 August 2023",
    "publishDate": "2023-08-06T12:00:00.000+08:00",
    "content":
    <React.Fragment>
    </React.Fragment>
  },
  {
    "img": "/images/site/blog/20230730_building-stronger-work-relationships-1.png",
    "tag": "Lifestyle",
    "title": "",
    "slug": "",
    "date": "5 August 2023",
    "publishDate": "2023-08-05T12:00:00.000+08:00",
    "content":
    <React.Fragment>
    </React.Fragment>
  },
  {
    "img": "/images/site/blog/20230730_building-stronger-work-relationships-1.png",
    "tag": "Lifestyle",
    "title": "",
    "slug": "",
    "date": "2 August 2023",
    "publishDate": "2023-08-02T12:00:00.000+08:00",
    "content":
    <React.Fragment>
    </React.Fragment>
  },
]


export default blog_202308