import React, {useState} from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import ReactGA from "react-ga4";

const ContactForm = () => {
  // for validation
  const validationSchema = Yup.object().shape({
    firstName: Yup.string().required("First name is required."),
    lastName: Yup.string().required("Last name is required."),
    message: Yup.string().required("Message cannot be empty."),
    email: Yup.string()
      .required("Email is required.")
      .email("Entered value does not match email format."),
  });

  const formOptions = { resolver: yupResolver(validationSchema) };
  // get functions to build form with useForm() hook
  const { register, handleSubmit, formState } = useForm(formOptions);
  const { errors } = formState;

  const [toastText, setToastText] = useState('Send Message')
  const [disabled, setDisabled] = useState(false)
  const url = 'https://pgl8khuqh5.execute-api.ap-southeast-1.amazonaws.com/v1/enquiry'


  function onSubmit(data, e) {
    // display form data on success
    e.preventDefault();
    setToastText('Sending')
    setDisabled(true)

    const form = e.target;
    const payload = {
      firstName: form.firstName.value,
      lastName: form.lastName.value,
      email: form.email.value,
      mobile: form.mobile.value,
      branch: form.branch.value,
      level: form.level.value,
      message: form.message.value,
    }

    post(url, payload, function (err) {
        if (err) {
            return onError(err)
        }
        onSuccess(form)
    })
    console.log("Message submited: " + JSON.stringify(data));
    e.target.reset();
  }

  const onSuccess = (form) => {
    setToastText('Message sent!')
    setDisabled(true)
    ReactGA.event("ContactForm", {
      event_action: "submit",
      event_category: "ContactForm",
      event_source: "contact",
      event_label: form.level.value
    })
  }

  const onError = (err) => {
    setToastText('Message failed to send.')
    setDisabled(false)
    console.log(err)
  }

  function post(url, body, callback) {
    const req = new XMLHttpRequest();
    req.open("POST", url, true);
    req.setRequestHeader("Content-Type", "application/json");
    req.addEventListener("load", function () {
      if (req.status < 400) {
          callback(null, JSON.parse(req.responseText));
      } else {
          callback(new Error("Request failed: " + req.statusText));
      }  
    });
    
    req.send(JSON.stringify(body));
  }           

  return (
    <form className="mt-50" id="contact-form" onSubmit={handleSubmit(onSubmit)}>
      <div className="messages"></div>
      <div className="row controls">
        <div className="col-md-6">
          <div className="input-group-meta form-group mb-60">
            <label>First Name</label>
            <input
              type="text"
              placeholder="First name"
              name="firstName"
              {...register("firstName")}
              className={`${errors.firstName ? "is-invalid" : ""}`}
            />
            {errors.firstName && (
              <div className="invalid-feedback">
                {errors.firstName?.message}
              </div>
            )}
          </div>
        </div>
        {/* End .col */}

        <div className="col-md-6">
          <div className="input-group-meta form-group mb-60">
            <label>Last Name</label>
            <input
              type="text"
              placeholder="Last name"
              name="lastName"
              {...register("lastName")}
              className={`${errors.lastName ? "is-invalid" : ""}`}
            />
            {errors.lastName && (
              <div className="invalid-feedback">{errors.lastName?.message}</div>
            )}
          </div>
        </div>
        {/* End .col */}

        <div className="col-6">
          <div className="input-group-meta form-group mb-60">
            <label>Email Address</label>
            <input
              placeholder="Enter your email"
              name="email"
              type="text"
              {...register("email")}
              className={` ${errors.email ? "is-invalid" : ""}`}
            />
            {errors.email && (
              <div className="invalid-feedback">{errors.email?.message}</div>
            )}
          </div>
        </div>
        <div className="col-6">
          <div className="input-group-meta form-group mb-60">
            <label>Mobile Phone (Optional)</label>
            <input
              placeholder="Mobile phone number"
              name="mobile"
              type="text"
              className={` ${errors.mobile ? "is-invalid" : ""}`}
            />
          </div>
        </div>
        {/* End .col */}

        <div className="col-6">
          <div className="input-group-meta form-group mb-40">
            <label>Branch</label>
            <select name="branch">
              <option value="Not applicable">Not applicable</option>
              <option value="Choa Chu Kang">Choa Chu Kang</option>
              <option value="Woodlands">Woodlands</option>
              <option value="Admiralty">Admiralty</option>
              <option value="Online">Online</option>
            </select>
          </div>
        </div>

        <div className="col-6">
          <div className="input-group-meta form-group mb-40">
            <label>Level</label>
            <select name="level">
              <option value="NA">Not applicable</option>
              <option value="Lower Primary">Lower Primary (P1-3)</option>
              <option value="Upper Primary">Upper Primary (P4-6)</option>
              <option value="Lower Secondary">Lower Secondary (Sec 1-2)</option>
              <option value="Upper Secondary">Upper Secondary (Sec 3-5)</option>
              <option value="Junior College">Junior College</option>
            </select>
          </div>
        </div>

        <div className="col-12">
          <div className="input-group-meta form-group lg mb-40">
            <label>Message</label>
            <textarea
              placeholder="Your message goes here..."
              name="sendMessage"
              type="text"
              {...register("message")}
              className={`${errors.sendMessage ? "is-invalid" : ""}`}
            ></textarea>
            {errors.sendMessage && (
              <div className="invalid-feedback">
                {errors.sendMessage?.message}
              </div>
            )}
          </div>
        </div>
        {/* End .col */}

        <div className="col-12">
          <button type="submit" className="theme-btn-five" disabled={disabled}>
            {toastText}
          </button>
        </div>
        {/* End .col */}
      </div>
    </form>
  );
};

export default ContactForm;
