import React from "react";
import { Helmet } from "react-helmet";
import Header from "../../../components/shared/Header";
import Footer from "../../../components/shared/Footer";
import AnalyticsEvent from "../../../components/shared/AnalyticsEvent";
import PrimaryFeature from "../../../components/programmes/level/LevelFeature";

const LowerPrimary = [
  {
    icon: "/images/site/icon/level/pri-1.png",
    title: "Primary 1",
    subTitle: `Our P1 program focuses on laying the right foundation for a smoother learning later on. Students' interest and curiosity are aroused as they are introduced to a new milestone in life and support is provided by our tutors for this specific transition.`,
    dataDelay: "0",
    link: "/programmes/primary-1",
    eventId: "p1",
  },
  {
    icon: "/images/site/icon/level/pri-2.png",
    title: "Primary 2",
    subTitle: `Our P2 program focuses on the exploration and basics of each subject. Students’ knowledge will be deepened with more connection between what they learn and the world around them while encouraging communication and ideation at this age.`,
    dataDelay: "100",
    link: "/programmes/primary-2",
    eventId: "p2",
  },
  {
    icon: "/images/site/icon/level/pri-3.png",
    title: "Primary 3",
    subTitle: `Our P3 program focuses on the introduction of a new subject, Science, and the relevant scientific skills like answering to point and logical reasoning. Students will also be introduced to more complex questions as they prepare for their upper level.`,
    dataDelay: "300",
    link: "/programmes/primary-3",
    eventId: "p3",
  },
];

const UpperPrimary = [
  {
    icon: "/images/site/icon/level/pri-4.png",
    title: "Primary 4",
    subTitle: `Our P4 program focuses on all-round development as students cope with increased complexity and academic demands. Students (and parents) will be encouraged to set academic and personal goals and understand the relevant paths available to them.`,
    dataDelay: "0",
    link: "/programmes/primary-4",
    eventId: "p4",
  },
  {
    icon: "/images/site/icon/level/pri-5.png",
    title: "Primary 5",
    subTitle: `Our P5 program focuses on sharpening examination skills like time management and concise answering. Students are entrusted with more independent work while continuing to grasp new academic concepts.`,
    dataDelay: "100",
    link: "/programmes/primary-5",
    eventId: "p5",
  },
  {
    icon: "/images/site/icon/level/pri-6.png",
    title: "Primary 6",
    subTitle: `Our P6 program focuses on meeting the expectation of the PSLE. Students will revisit old concepts, clarify doubts and enter their examinations with boosted morale.`,
    dataDelay: "300",
    link: "/programmes/primary-6",
    eventId: "p6",
  },
];

const Primary = () => {
  return (
    <div className="main-page-wrapper p0">
      <Helmet>
        <title>
          Primary School Tuition
        </title>
      </Helmet>
      
      <Header />
      
      {/* 	=============================================
				Fancy Hero Three
			============================================== */}
      <div className="fancy-hero-three bg-transparent mb-100">
        <div className="shapes shape-one"></div>
        <div className="shapes shape-two"></div>
        <div className="shapes shape-three"></div>
        <div className="shapes shape-four"></div>
        <div className="shapes shape-five"></div>
        <div className="shapes shape-six"></div>
        <div className="bg-wrapper">
          <div className="container">
            <div className="row">
              <div className="col-xl-9 col-lg-10 col-md-10 m-auto">
                <h1 className="heading">Primary School Tuition</h1>
                <p className="sub-heading">
                  Tailored focus on various aspects from Lower Primary to Upper Primary.
                </p>
                <a href="https://wa.me/+6590625285?lang=en" target="_blank" rel="noreferrer"
                  className="theme-btn-five mt-50 md-mt-30"
                  onClick={() => AnalyticsEvent({
                    category: "WhatsAppEnquire",
                    action: "Click",
                    source: "/programmes/primary",
                    label: "primaryClickWhatsAppEnquire"
                  })}
                >
                  <i className="fa fa-whatsapp"></i> Enquire Now
                </a>
              </div>
            </div>
          </div>
        </div>
        {/* /.bg-wrapper  */}
      </div>

      
      <div className="level-text-block-right level-lower-primary pt-50 pb-120 md-pt-50 md-pb-80">
        <div className="container">
          <div className="row">
            <div className="col-xl-7 col-lg-8">
              <div className="title-style-one mb-40 md-mb-20">
                <h2>Lower Primary School</h2>
                <p className="font-rubik">
                Our Lower Primary programs focus on building fundamental knowledge and arousing students’ 
                interest in linking what they learn with what is around them. Beginner learning techniques 
                like asking questions, solving simple sums and practicing communication skills are our focus. 
                <br/><br/>Our Lower Primary subjects include English, Math, Chinese and Science (P3 only).
                </p>
              </div>
            </div>
          </div>

          <div className="wrapper">
            <div className="row justify-content-center">
              <PrimaryFeature levelData={LowerPrimary} levelCols={4} levelStyle="block-style-level-primary"/>
            </div>
          </div>
        </div>
      </div>

      <div className="level-text-block-left level-upper-primary pt-130 pb-160 md-pt-100 md-pb-100">
        <div className="container">
          <div className="row">
            <div className="col-lg-7 ml-auto">
              <div className="title-style-one mb-50 md-mb-30">
                <h2>Upper Primary School</h2>
                <p className="font-rubik">
                Our Upper Primary programs help encourage a well-rounded student. While they prepare for the PSLE, continued interest in 
                the relevant subjects are encouraged and roles and responsibilities are also entrusted. Study tips are shared, and guidance 
                is provided to find the optimum studying technique for each and every student. Our Upper Primary subjects include English, 
                Math, Chinese and Science. 
                </p>
              </div>
            </div>
          </div>

          <div className="wrapper">
            <div className="row justify-content-center">
              <PrimaryFeature levelData={UpperPrimary} levelCols={4} levelStyle="block-style-level-primary"/>
            </div>
          </div>
        </div>
      </div>

      <Footer/>
    </div>
  );
};

export default Primary;
