import React from "react";

const quote = [
    {
        remarks: "Hello Mr Dave, really thank you for boosting my confidence in AMath. F9 got me to hate it but really your guidance during this final milestone really helped me a lot!! Thank you!! Also, without your interesting stories in class and your help I would not have that much fun and helped me achieved this A2 grade. THANKS CHER!!!",
        name: "Zoe",
        relationship: "Student",
        img: "quote-1"
    },
    {
        remarks: "I would like to thank him (Mr Christopher) as I have improved tremendously just from one month of tuition. I was doing very badly for chemistry one month prior to Os too.",
        name: "Kaylee",
        relationship: "Student",
        img: "quote-2"
    },
    {
        remarks: "Mr Dave has the entire syllabus at the back of his hand, recognising all the patterns and commonalities in the A Level syllabus, allowing you to learn effectively and efficiently. Thank you!",
        name: "Enzo",
        relationship: "Student",
        img: "quote-3"
    },
    {
        remarks: "Tutor Dave has increased both my grades and my confidence in Math. He always takes into consideration. each student's strengths and weaknesses when carrying out his lessons.",
        name: "Alice",
        relationship: "Student",
        img: "quote-4"
    },
    {
        remarks: "Mr Dave is a very kind teacher that always prioritises students' doubts and never fails to make the effort to simplify his explanations so that everyone can understand.",
        name: "Xavier",
        relationship: "Student",
        img: "quote-5"
    },
    {
        remarks: "Before joining Mr Dave's h1 economics lesson I got U for econs in myct as I did not understand a lot of concepts and did not know how to answer essay type questions. However after joining my content and exam techniques improved and got A for econs in promos and was able to pass consistently in JC2 examinations. This was the same for math where I witnessed improvements in my H2 math in JC2 as he went through a lot of key techniques in tackling math questions which helped me solidify my math content and answering skills.",
        name: "Yan Ting",
        relationship: "Student",
        img: "quote-6"
    },
    {
        remarks: "I will like to give a big thanks to Mr John.....as my daughter has considerably gained confidence in her GP. I have seen a remarkable change in her GP grade. His teaching is very detail and he is very helpful as well. He is a very dedicated and committed tutor.",
        name: "Hwee Bee",
        relationship: "Mother of Elaine",
        img: "quote-7"
    },
    {
        remarks: "I would like to thank Ms Dorothy for helping me with my English. She is very patient with me and would try her best to help me improve my English. She gave me a lot of confidence in my English result. I am very thankful for her. Without the help from Ms Dorothy, I wouldn't have achieve a decent grade :)",
        name: "Serene",
        relationship: "Student",
        img: "quote-8"
    },
    {
        remarks: "First off, please do convey our much gratitude and appreciation to Pauline and John. He scored well with an Aggregate 261. 3A* and 1A(English). He is the top student for P6 cohort this year 2018.",
        name: "Mr Lee",
        relationship: "Father of Alan",
        img: "quote-9"
    },
    {
        remarks: "Managed to score B for GP, from D, in 9 lessons. Please help me in expressing my thanks to Mr Jonathan for his guidance.",
        name: "Kaylee",
        relationship: "Student",
        img: "quote-10"
    },
    {
        remarks: "I want to thank Mr Dave for my results. Thank you for teaching me for around 2 years and it has been a joy to be able to taught under you. Thank you for cracking good and bad jokes occasionally to make us laugh and also allowing us to come for extra class on school holidays.",
        name: "Ariel",
        relationship: "Student",
        img: "quote-11"
    },
    {
        remarks: "H1 GP - A, H1 Economics - A, H2 Chemistry - A, H2 Mathematics - B",
        name: "Jacob",
        relationship: "Student",
        img: "quote-12"
    },
    {
        remarks: "H1 GP, H2 Physics, H2 Economics, H2 Mathematics, H2 Literature all A!",
        name: "Jeanette",
        relationship: "Student",
        img: "quote-13"
    },
    {
        remarks: "H1 GP, H2 Chemistry, H2 Physics, H2 Economics, H2 Mathematics all A!",
        name: "Mikael",
        relationship: "Student",
        img: "quote-14"
    },
]

const Quote = () => {
    return (
        <>
            {quote.map((item, i) => (i % 2) ?
            (
                <div className="row align-items-center mb-50">
                    <div className="col-lg-6">
                        <div className="quote-wrapper">
                            <blockquote className="font-rubik">
                            {item.remarks}
                            </blockquote>
                            <h6>
                            {item.name} <span>{item.relationship}</span>
                            </h6>
                        </div>
                        </div>
                    <div className="col-lg-6 col-md-7 col-sm-8 m-auto">
                        <img src={`images/site/testimonials/${item.img}.webp`} alt="quote" />
                    </div>
                </div>
            ) :
            (
                <div className="row align-items-center mb-50">
                    <div className="col-lg-6 col-md-7 col-sm-8 m-auto">
                    <img src={`images/site/testimonials/${item.img}.webp`} alt="quote" />
                    </div>
                    <div className="col-lg-6">
                        <div className="quote-wrapper">
                            <blockquote className="font-rubik">
                            {item.remarks}
                            </blockquote>
                            <h6>
                            {item.name} <span>{item.relationship}</span>
                            </h6>
                        </div>
                    </div>
                </div>
            )
            )}
        </>

    );
};

export default Quote;