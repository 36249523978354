import React from "react";

const FeatureContent = [
  {
    icon: "61",
    meta: "Multiple Subjects Discount",
    subTitle: `$20 off if you take 2 subjects. $60 off if you take 3 subjects or more`,
  },
  {
    icon: "62",
    meta: "Referral Bonus",
    subTitle: `Refer a friend and both of you get 1 free lesson`,
  },
];

const FeaturesEight = () => {
  return (
    <div className="row justify-content-center">
      {FeatureContent.map((val, i) => (
        <div
          className="col-lg-6 col-md-6"
          key={i}
          data-aos="fade-up"
          data-aos-duration="1200"
        >
          <div className="block-style-thirteen">
            <div className="icon">
              <img src={`images/icon/${val.icon}.svg`} alt="icon" />
            </div>
            <div className="title font-rubik">{val.meta}</div>
            <p>{val.subTitle}</p>
          </div>
        </div>
      ))}
    </div>
  );
};

export default FeaturesEight;
