import React from "react";
import AnalyticsEvent from "../../shared/AnalyticsEvent";

const blog_202303 = [
  {
    "img": "/images/site/blog/20230329_EAE-process_2.png",
    "tag": "News",
    "title": "EAE and PFP application process",
    "slug": "eae-and-pfp-application-process",
    "date": "29 Mar 2023",
    "publishDate": "2023-03-29T12:00:00.000+08:00",
    "content": 
    <React.Fragment>
      <div className="post-meta pb-100"><a href="/blog" className="theme-btn-five"><i className="fa fa-arrow-left"></i> Back</a>
          <div className="tag">29 Mar 2023</div>
          <h3 className="title">
            EAE and PFP application process
          </h3>
        <p>Reading in English can be an effective way to improve your language skills. Not only does it help you expand your vocabulary and improve your grammar, but it also provides other benefits that can enhance your language learning experience. Here are some of the benefits of reading in English:</p>
        <br/>
        <img
            src="/images/site/blog/20230319_reading-in-english.png"
            alt="Essay writing"
            style={{ maxHeight: '450px', marginLeft: 'auto', marginRight: 'auto', paddingTop: '20px', paddingBottom: '20px', borderRadius: '20%'}}
        />
        <ol>
            <li>Vocabulary Expansion: Reading in English exposes you to a wide range of words and expressions that you may not encounter in other contexts. This exposure can help you expand your vocabulary and learn new words in context.</li>
            <li>Grammar Improvement: Reading in English can help you improve your grammar and sentence structure. By reading well-written texts, you can learn to recognize correct grammar and syntax.</li>
            <li>Cultural Knowledge: Reading in English can also provide insights into the culture and history of English-speaking countries. By reading literature and other texts written by English speakers, you can gain a deeper understanding of their traditions, values, and beliefs.</li>
            <li>Improved Writing Skills: Reading in English can also help you improve your writing skills. By studying the writing styles of English authors, you can learn to write more clearly, concisely, and effectively.</li>
            <li>Better Pronunciation: Reading in English can also improve your pronunciation. By reading out loud, you can practice your pronunciation and intonation, and learn how to pronounce new words correctly.</li>
            <li>Improved Critical Thinking: Reading in English can also help you develop critical thinking skills. By analyzing and interpreting texts, you can learn to think more critically and develop your analytical skills.</li>
        </ol>
        <p>In conclusion, reading in English is a valuable tool for improving your language skills. It can help you expand your vocabulary, improve your grammar, learn about the culture and history of English-speaking countries, improve your writing skills, improve your pronunciation, and develop critical thinking skills. To get the most out of reading in English, choose texts that are at the right level for you, read regularly, and practice reading out loud.</p>
        <p></p>
        <div style={{ textAlign: 'center' }}>
            <p>Keen to learn more about Eduz Tuition?</p>
            <a href="https://wa.me/+6590625285?lang=en"
            target="_blank"
            rel="noreferrer"
            className="theme-btn-five"
            data-aos="fade-in"
            data-aos-duration="1200"
            data-aos-delay="200"
            onClick={() => AnalyticsEvent({
              category: "WhatsAppEnquire",
              action: "Click",
              source: "/blog/eae-and-pfp-application-process",
              label: "blogClickWhatsAppEnquire"
            })}
        >
        <i className="fa fa-whatsapp"></i> Chat with us now
        </a>
        </div>
        <p></p>
      </div>
    </React.Fragment>
  },
  {
    "img": "/images/site/blog/20230326_teen-burnout.png",
    "tag": "Lifestyle",
    "title": "How to Avoid Burnout: Tips and Strategies",
    "slug": "how-to-avoid-burnout-tips-and-strategies",
    "date": "26 Mar 2023",
    "publishDate": "2023-03-26T12:00:00.000+08:00",
    "content":
    <React.Fragment>
      <div className="post-meta pb-100"><a href="/blog" className="theme-btn-five"><i className="fa fa-arrow-left"></i> Back</a>
        <div className="tag">26 Mar 2023</div>
        <h3 className="title">
          How to Avoid Burnout: Tips and Strategies
        </h3>
        <img
          src="/images/site/blog/20230326_teen-burnout.png"
          alt="Teen burn out"
          style={{ maxHeight: '450px', marginLeft: 'auto', marginRight: 'auto', paddingTop: '20px', paddingBottom: '20px'}}
        />
        <p>Burnout is a state of emotional, physical, and mental exhaustion caused by prolonged stress and overwork. It can lead to decreased productivity, feelings of cynicism, and a lack of motivation. If you are feeling overwhelmed and on the brink of burnout, here are some tips and strategies to consider:</p>
        <br/>
        <ol>
          <li>Prioritize self-care: Self-care activities such as exercise, healthy eating, and adequate sleep can help you manage stress and prevent burnout. Make time for activities that you enjoy and that help you relax, such as reading, taking a bath, or spending time with loved ones.</li>
          <li>Set boundaries: Learn to say no and prioritize your workload. Set realistic goals and deadlines and communicate them to your colleagues and supervisors. Avoid taking on too much work, especially if it interferes with your personal life and well-being.</li>
          <li>Take breaks: Taking regular breaks, such as a short walk or a quick stretch, can help you recharge and refocus. Avoid working through lunch or skipping breaks, as this can lead to mental and physical exhaustion.</li>
          <li>Practice stress-reducing activities: Activities such as meditation, deep breathing, and yoga can help reduce stress levels and prevent burnout. Experiment with different stress-reducing techniques to find what works best for you.</li>
          <li>Seek support: Don't be afraid to reach out for help when needed. Talk to a trusted friend or family member, or seek professional support from a therapist or counselor.</li>
          <li>Take time off: Take time off from work when needed to recharge and prevent burnout. Use your vacation time, sick days, or personal days to take a break and engage in self-care activities.</li>
          <li>Evaluate your priorities: Take a step back and evaluate your priorities. Consider what is most important to you and how you can align your work and personal life with your values.</li>
        </ol>
        <p>Remember that burnout is a common experience and can happen to anyone. By prioritizing self-care, setting boundaries, and seeking support, you can prevent burnout and maintain your well-being.</p>
        <p></p>
        <div style={{ textAlign: 'center' }}>
          <p>Keen to learn more about Eduz Tuition?</p>
          <a href="https://wa.me/+6590625285?lang=en"
          target="_blank"
          rel="noreferrer"
          className="theme-btn-five"
          data-aos="fade-in"
          data-aos-duration="1200"
          data-aos-delay="200"
          onClick={() => AnalyticsEvent({
            category: "WhatsAppEnquire",
            action: "Click",
            source: "/blog/how-to-avoid-burnout-tips-and-strategies",
            label: "blogClickWhatsAppEnquire"
          })}
        >
        <i className="fa fa-whatsapp"></i> Chat with us now
      </a>
      </div>
      <p></p>
      </div>
    </React.Fragment>
  },
  {
    "img": "/images/site/blog/20230325_teen-happy.png",
    "tag": "Lifestyle",
    "title": "How to remain unbothered as a teen?",
    "slug": "how-to-remain-unbothered-as-a-teen",
    "date": "25 Mar 2023",
    "publishDate": "2023-03-25T12:00:00.000+08:00",
    "content":
    <React.Fragment>
      <div className="post-meta pb-100"><a href="/blog" className="theme-btn-five"><i className="fa fa-arrow-left"></i> Back</a>
        <div className="tag">25 Mar 2023</div>
        <h3 className="title">
          How to remain UNBOTHERED as a teen?
        </h3>
        <p>Hey there! Are you tired of feeling hurt, insecure, or betrayed by others? It's time to start loving yourself mentally and physically! When you're not wrapped up in drama, you feel powerful because you know you can achieve anything you set out to do. But let's be real, it's not always easy to just ignore the negative opinions and hurtful comments from others. That's why I recommend practising acceptance instead. By accepting yourself and others, you can overcome and eliminate those negative feelings and insecurities. It's much easier and healthier to choose acceptance and move on, rather than trying to ignore everything. So let's focus on the positive and leave the drama behind!</p>
        <br/>
        <img
          src="/images/site/blog/20230325_teen-stress.png"
          alt="Teen feeling stressed"
          style={{ maxHeight: '450px', marginLeft: 'auto', marginRight: 'auto', paddingTop: '20px', paddingBottom: '20px'}}
        />
        <h4 className="title">1. Dealing with hate</h4>
        <p>When someone throws hatred your way, it's tempting to ignore it. But what if there's a better way? Accepting criticism can be a powerful tool in your personal growth arsenal.</p>
        <p>It's important to acknowledge that hurtful words or actions can be painful, but also recognize that the person who said or did them might be dealing with their own struggles. Don't take it personally, and don't waste your energy trying to change their mind.</p>
        <p>Remember, it's not your job to make them see the error of their ways. Instead, focus on your own self-improvement and let their negativity roll off your back. With this mindset, you'll be able to handle any situation with grace and confidence.</p>
        <h4 className='title'>2. Don’t give explanations</h4>
        <p>You don't always have to explain yourself. Sometimes, staying silent is more powerful than any words you could say.</p>
        <p>It's okay if someone doesn't like you, you don't have to please everyone. Trying to convince them otherwise is a fruitless endeavour that will only drain you. So save your breath and move on to something more worth your time and energy!</p>
        <img
          src="/images/site/blog/20230325_comic.png"
          alt="Comic strip on silencing"
          style={{ maxHeight: '450px', marginLeft: 'auto', marginRight: 'auto', paddingTop: '20px', paddingBottom: '20px'}}
        />
        <h4 className='title'>3. Power of being unbothered</h4>
        <p>Want to make more meaningful friendships? Focus your energy on the people who matter most! By giving your time and attention to those you truly appreciate, you'll be amazed at how many great connections you can make. So stop trying to please everyone and start building relationships that really matter!</p>
        <h4 className='title'>4. You determine your success</h4>
        <p>When you're truly content and joyful, your inner strength is magnified! There's nothing that can hold you back from achieving your wildest dreams - not even your own doubts or fears. Remember, you have the power to create the life you desire, so go out there and make it happen!</p>
        <img
          src="/images/site/blog/20230325_teen-success.png"
          alt="Teens happy"
          style={{ maxHeight: '450px', marginLeft: 'auto', marginRight: 'auto', paddingTop: '20px', paddingBottom: '20px'}}
        />
        <h4 className='title'>5. Do not expect too much from others</h4>
        <p>Listen up, my friend! Here's a hard truth: not everyone in this world is going to be nice to you. Some people won't share the same opinion as you, and some people just won't like you - and that's okay! It's important to understand that expectations can give you a false sense of security. If you expect people to behave in a certain way or do what you want, you're setting yourself up for disappointment. It's like building a house of cards, and we all know how easily that can come crashing down.</p>
        <p>That's why it hurts so much when someone close to you lets you down - you expected more from them. But if you lower your expectations, you'll be less likely to feel hurt or disappointed. Don't get me wrong, it's great to have high hopes and aspirations, but when it comes to other people's actions, it's best to just take things as they come. So, let go of those expectations and live life with an open mind!</p>
        <h4 className='title'>6. Remove things that make you unhappy</h4>
        <p>You deserve to live a happy and fulfilling life, and that includes surrounding yourself with people and things that bring you joy. Don't be afraid to let go of things that don't serve you or make you unhappy - it's all about prioritising your own well-being. By spending time doing things you love and working towards your passions, you'll attract like-minded people who will enhance your life. Remember that every person who enters your life should be an asset, not a liability. Don't waste your precious time and energy on people who bring you down or are jealous of your success. Life is too short to settle for anything less than greatness!</p>
        <img
          src="/images/site/blog/20230325_teen-happy.png"
          alt="Teens happy"
          style={{ maxHeight: '450px', marginLeft: 'auto', marginRight: 'auto', paddingTop: '20px', paddingBottom: '20px', borderRadius: '40px'}}
        />
        <p></p>
        <div style={{ textAlign: 'center' }}>
          <p>Keen to learn more about Eduz Tuition?</p>
          <a href="https://wa.me/+6590625285?lang=en"
          target="_blank"
          rel="noreferrer"
          className="theme-btn-five"
          data-aos="fade-in"
          data-aos-duration="1200"
          data-aos-delay="200"
          onClick={() => AnalyticsEvent({
            category: "WhatsAppEnquire",
            action: "Click",
            source: "/blog/how-to-remain-unbothered-as-a-teen",
            label: "blogClickWhatsAppEnquire"
          })}
        >
        <i className="fa fa-whatsapp"></i> Chat with us now
      </a>
      </div>
      <p></p>
      </div>
    </React.Fragment>
  },
  {
    "img": "/images/site/blog/20230322_student-lab.png",
    "tag": "News",
    "title": "Change in PSLE syllabus: Everything you need to know",
    "slug": "change-in-psle-syllabus-everything-you-need-to-know",
    "date": "22 Mar 2023",
    "publishDate": "2023-03-22T12:00:00.000+08:00",
    "content":
    <React.Fragment>
      <div className="post-meta pb-100"><a href="/blog" className="theme-btn-five"><i className="fa fa-arrow-left"></i> Back</a>
        <div className="tag">22 Mar 2023</div>
        <h3 className="title">
        Change in PSLE syllabus: Everything you need to know
        </h3>
        <p>In 2021, a significant overhaul of the Primary School Leaving Examination (PSLE) scoring system was implemented to reduce the emphasis placed on academic outcomes. Consequently, the previous T-score system, which was familiar to most, will be replaced by a more diverse range of scores.</p>
        <br/>
        <h4 className="title">1. Old vs new T-score system</h4>
        <p>The updated PSLE scoring system, implemented in lieu of numerical T-scores or letter grades (A-star to F), assigns each examinable subject an Achievement Level (AL) with a score range of 1 to 8, where 1 represents the highest and 8 the lowest level of achievement. Each student will receive a total PSLE score between 4 and 32 based on the ALs obtained across all examinable subjects.</p>
        <p>It is worth noting that each AL is associated with a particular grade range, as indicated in the table below, reflecting a departure from the previous scoring system.</p>
        <img
          src="/images/site/blog/20230322_psle-grading.png"
          alt="Changes in PSLE grading"
          style={{ maxHeight: '450px', marginLeft: 'auto', marginRight: 'auto', paddingTop: '20px', paddingBottom: '20px'}}
        />
        <h4 className="title">2. Subject based banding</h4>
        <p>The Ministry of Education (MOE) has announced a significant change to the PSLE syllabus with the introduction of subject-based banding. Beginning in 2024, students entering secondary schools will be sorted into three groups based on their strengths in individual subjects, rather than relying solely on their overall scores. As a result, students who excel in specific subjects will be placed in higher-level classes for those subjects, irrespective of their overall PSLE score. This shift is intended to accommodate the diverse learning styles and strengths of students, allowing them to pursue their interests and develop their talents</p>
        <img
          src="/images/site/blog/20230322_student-lab.png"
          alt="Student in a lab"
          style={{ maxHeight: '250px', marginLeft: 'auto', marginRight: 'auto', paddingTop: '20px', paddingBottom: '20px', borderRadius: '50px'}}
        />
        <h4 className="title">3. Skills based approach</h4>
        <p>The updated PSLE syllabus features a significant shift towards a skills-based approach to learning, prioritising the development of students' critical thinking, problem-solving, and communication skills. This change aligns with the demands of the 21st century, which require individuals to be adaptable and flexible to succeed. Rather than focusing on memorization, students will be evaluated based on their practical application of skills in real-world contexts.</p>
        <p>Furthermore, the revised syllabus emphasises interdisciplinary learning, encouraging students to draw connections between different subjects and apply their knowledge in innovative and creative ways. This approach aims to foster creativity and promote the ability to think beyond the conventional boundaries of individual subjects.</p>
        <h4 className="title">Conclusion</h4>
        <p>In summary, the revisions to the PSLE syllabus signal a transformative departure from conventional educational practices in Singapore. By prioritising the cultivation of students' competencies and skills, the updated syllabus aims to equip them with the necessary tools to tackle future challenges. While the implementation of the new syllabus may pose challenges, the anticipated benefits are substantial. It is expected that the changes will lead to a generation of Singaporeans that are more versatile, resilient, and adaptable.</p>
        <p></p>
        <div style={{ textAlign: 'center' }}>
          <p>Keen to learn more about Eduz Tuition?</p>
          <a href="https://wa.me/+6590625285?lang=en"
          target="_blank"
          rel="noreferrer"
          className="theme-btn-five"
          data-aos="fade-in"
          data-aos-duration="1200"
          data-aos-delay="200"
          onClick={() => AnalyticsEvent({
            category: "WhatsAppEnquire",
            action: "Click",
            source: "/blog/change-in-psle-syllabus-everything-you-need-to-know",
            label: "blogClickWhatsAppEnquire"
          })}
        >
        <i className="fa fa-whatsapp"></i> Chat with us now
      </a>
      </div>
      <p></p>
      </div>
    </React.Fragment>
  },
  {
    "img": "/images/site/blog/20230319_reading-in-english.png",
    "tag": "Education",
    "title": "The Benefits of Reading in English: How It Can Help You Improve Your Language Skills",
    "slug": "the-benefits-of-reading-in-english-how-it-can-help-you-improve-your-language-skills",
    "date": "19 Mar 2023",
    "publishDate": "2023-03-19T12:00:00.000+08:00",
    "content":
    <React.Fragment>
       <div className="post-meta pb-100"><a href="/blog" className="theme-btn-five"><i className="fa fa-arrow-left"></i> Back</a>
        <div className="tag">19 Mar 2023</div>
        <h3 className="title">
          The Benefits of Reading in English: How It Can Help You Improve Your Language Skills
        </h3>
        <p>Reading in English can be an effective way to improve your language skills. Not only does it help you expand your vocabulary and improve your grammar, but it also provides other benefits that can enhance your language learning experience. Here are some of the benefits of reading in English:</p>
        <br/>
        <img
          src="/images/site/blog/20230319_reading-in-english.png"
          alt="Essay writing"
          style={{ maxHeight: '450px', marginLeft: 'auto', marginRight: 'auto', paddingTop: '20px', paddingBottom: '20px', borderRadius: '20%'}}
        />
        <ol>
          <li>Vocabulary Expansion: Reading in English exposes you to a wide range of words and expressions that you may not encounter in other contexts. This exposure can help you expand your vocabulary and learn new words in context.</li>
          <li>Grammar Improvement: Reading in English can help you improve your grammar and sentence structure. By reading well-written texts, you can learn to recognize correct grammar and syntax.</li>
          <li>Cultural Knowledge: Reading in English can also provide insights into the culture and history of English-speaking countries. By reading literature and other texts written by English speakers, you can gain a deeper understanding of their traditions, values, and beliefs.</li>
          <li>Improved Writing Skills: Reading in English can also help you improve your writing skills. By studying the writing styles of English authors, you can learn to write more clearly, concisely, and effectively.</li>
          <li>Better Pronunciation: Reading in English can also improve your pronunciation. By reading out loud, you can practice your pronunciation and intonation, and learn how to pronounce new words correctly.</li>
          <li>Improved Critical Thinking: Reading in English can also help you develop critical thinking skills. By analyzing and interpreting texts, you can learn to think more critically and develop your analytical skills.</li>
        </ol>
        <p>In conclusion, reading in English is a valuable tool for improving your language skills. It can help you expand your vocabulary, improve your grammar, learn about the culture and history of English-speaking countries, improve your writing skills, improve your pronunciation, and develop critical thinking skills. To get the most out of reading in English, choose texts that are at the right level for you, read regularly, and practice reading out loud.</p>
        <p></p>
        <div style={{ textAlign: 'center' }}>
          <p>Keen to learn more about Eduz Tuition?</p>
          <a href="https://wa.me/+6590625285?lang=en"
          target="_blank"
          rel="noreferrer"
          className="theme-btn-five"
          data-aos="fade-in"
          data-aos-duration="1200"
          data-aos-delay="200"
          onClick={() => AnalyticsEvent({
            category: "WhatsAppEnquire",
            action: "Click",
            source: "/blog/the-benefits-of-reading-in-english-how-it-can-help-you-improve-your-language-skills",
            label: "blogClickWhatsAppEnquire"
          })}
        >
        <i className="fa fa-whatsapp"></i> Chat with us now
      </a>
      </div>
      <p></p>
      </div>
    </React.Fragment>
  },
  {
    "img": "/images/site/blog/20230318_improve-essay.png",
    "tag": "Education",
    "title": "How to Improve Your Essay Writing Skills",
    "slug": "how-to-improve-your-essay-writing-skills",
    "date": "18 Mar 2023",
    "publishDate": "2023-03-18T12:00:00.000+08:00",
    "content":
    <React.Fragment>
      <div className="post-meta pb-100"><a href="/blog" className="theme-btn-five"><i className="fa fa-arrow-left"></i> Back</a>
        <div className="tag">18 Mar 2023</div>
        <h3 className="title">
          How to Improve Your Essay Writing Skills
        </h3>
        <p>
        Essay writing is an essential skill that is required for academic success and professional development. Whether you are a student, a researcher, or a professional, the ability to communicate your ideas effectively through writing is crucial. In this article, we will explore some practical tips on how to improve your essay writing skills.
        </p>
        <img
          src="/images/site/blog/20230318_improve-essay.png"
          alt="Essay writing"
          style={{ maxHeight: '350px', marginLeft: 'auto', marginRight: 'auto', paddingTop: '20px', paddingBottom: '20px', borderRadius: '20%'}}
        />
        <h4 className="title">1. Read and Analyse Good Essays</h4>
        <p>One of the best ways to improve your writing skills is to read good essays. Reading essays written by experts can give you an insight into how to structure your essay, how to develop your ideas, and how to write effectively. Analysing these essays can also help you identify the techniques and strategies used by the writers, which you can then incorporate into your own writing.</p>
        <h4 className="title">2. Plan and Organise Your Writing</h4>
        <p>Before you start writing your essay, it is important to plan and organise your thoughts. This can be done by creating an outline that will guide your writing process. An outline will help you to structure your essay in a logical and coherent manner, which will make it easier for your readers to follow your argument.</p>
        <h4 className="title">3. Write Clearly and Concisely</h4>
        <p>Writing clearly and concisely is essential to effective essay writing. Avoid using overly complicated words or phrases that may confuse your readers. Instead, use simple and clear language that conveys your ideas effectively. Also, try to be concise and avoid using unnecessary words or phrases that do not add value to your writing.</p>
        <h4 className="title">4. Use Evidence to Support Your Argument</h4>
        <p>In an essay, it is important to support your argument with evidence. This can be done by citing sources such as books, articles, or research papers. Using evidence not only strengthens your argument but also demonstrates your knowledge and understanding of the topic.</p>
        <h4 className="title">5. Revise and Edit Your Writing</h4>
        <p>Revision and editing are crucial steps in the essay writing process. After completing your essay, take a break and then come back to it with fresh eyes. This will help you to identify any errors or inconsistencies in your writing. Revise your essay to ensure that your ideas are well-organised and your arguments are clear and concise. Finally, proofread your essay for spelling and grammar errors.</p>
        <p>In conclusion, essay writing is a skill that can be developed through practice and determination. By following these practical tips, you can improve your essay writing skills and produce effective and engaging essays. Remember to read good essays, plan and organize your writing, write clearly and concisely, use evidence to support your argument, and revise and edit your writing.</p>
        <p></p>
        <div style={{ textAlign: 'center' }}>
          <p>Keen to learn more about Eduz Tuition?</p>
          <a href="https://wa.me/+6590625285?lang=en"
          target="_blank"
          rel="noreferrer"
          className="theme-btn-five"
          data-aos="fade-in"
          data-aos-duration="1200"
          data-aos-delay="200"
          onClick={() => AnalyticsEvent({
            category: "WhatsAppEnquire",
            action: "Click",
            source: "/blog/how-to-improve-your-essay-writing-skills",
            label: "blogClickWhatsAppEnquire"
          })}
          >
          <i className="fa fa-whatsapp"></i> Chat with us now
          </a>
        </div>
      <p></p>
      </div>
    </React.Fragment>
  },
  {
    "img": "/images/site/blog/20230303_new-subject-based-banding-1.png",
    "tag": "News",
    "title": "A guide to the new subject-based banding system for secondary school",
    "slug": "a-guide-to-the-new-subject-based-banding-system-for-secondary-school",
    "date": "3 Mar 2023",
    "publishDate": "2023-03-03T12:00:00.000+08:00",
    "content": 
    <React.Fragment>
      <div className="post-meta pb-100"><a href="/blog" className="theme-btn-five"><i className="fa fa-arrow-left"></i> Back</a>
        <div className="tag">3 Mar 2023</div>
        <h3 className="title">
          A guide to the new subject-based banding system for secondary school
        </h3>
        <p>
        In secondary school, there will no longer be Express, Normal Academic, and normal technical streams. 
        Instead, from 2024 onwards, Secondary 1 will be placed in a brand new subject-based branding system (Full SBB).
        </p>
        <img
          src="/images/site/blog/20230303_new-subject-based-banding-1.png"
          alt="Student arriving to class"
          style={{ maxHeight: '350px', marginLeft: 'auto', marginRight: 'auto', paddingTop: '20px', paddingBottom: '20px' }}
        />
        <p>
          With the new subject-based branding system, all students will be placed in subject classes - G1, G2 and G3 
          - according to their level of achievement. Depending on the student's PSLE performance, they will be offered 
          subjects at different levels. Think of G1 as the Normal Technical level, G2 as the Normal Academic level and 
          G3 as the Express Stream level. However, this is not a fixed level - if your child does well in certain subjects, 
          they can take them at a higher level in the next school year.
        </p>
        <p>
          Below shows a range of PSLE scores and their posting groups.
        </p>
        <img
          src="/images/site/blog/20230303_new-subject-based-banding-2.png"
          alt="Table for range of PSLE scores and their posting groups"
          style={{ maxHeight: '350px', marginLeft: 'auto', marginRight: 'auto', paddingTop: '20px', paddingBottom: '20px' }}
        />
        <p>
          This new branding system allows your child to take subjects based on their strengths and weaknesses. Unlike the old 
          streaming system where there were restrictions, your child can take subjects at a more challenging level if they perform well (and vice versa).
        </p>
        <p>
          From 2024, schools and classes will have a better mix of students from different posting groups. This will give students more opportunities to 
          connect with peers from different backgrounds, strengths and interests.
        </p>
        <h4 className="title">Subjects</h4>
        <p>
          Students entering Secondary 1 who face exceptional difficulties coping with Mother Tongue Language learning will have the option to take Mother 
          Tongue Language at the less demanding G1 or G2 levels. In addition, students who have taken English, Mathematics and/or Science at the basic level 
          in PSLE, but are otherwise eligible to take most of their subjects at G3 when they enter secondary school, may take English, Mathematics and/or 
          Science at the less demanding G2 level in consultation with their secondary schools.
        </p>
        <img
          src="/images/site/blog/20230303_new-subject-based-banding-3.png"
          alt="Students cheering"
          style={{ maxHeight: '250px', marginLeft: 'auto', marginRight: 'auto', paddingTop: '20px', paddingBottom: '20px' }}
        />
        <p>
          From 2026, students will also have the flexibility to customize their learning when they reach upper secondary levels, where they can choose 
          to take elective subjects, such as Additional Mathematics, Art, and Design & Technology, at more or less demanding levels.
        </p>
        <img
          src="/images/site/blog/20230303_new-subject-based-banding-4.png"
          alt="Computer engineers"
          style={{ maxHeight: '250px', marginLeft: 'auto', marginRight: 'auto', paddingTop: '20px', paddingBottom: '20px' }}
        />
        <h4 className="title">Computing</h4>
        <p>Computer Applications (CPA), which is only offered to students in the Normal Technical stream in lower secondary will be abolished from 2024 and replaced with G1 in 2026.</p>
        <p>By then, schools will offer G1 Computing as an elective for the Secondary 3 cohort. Schools will also introduce G2 Computing as a new elective for upper secondary students, while schools currently offering O-Level Computing will continue to offer G3 Computing.</p>
        <h4 className="title">Humanities</h4>
        <p>Social Studies for the N(T) stream will also no longer be offered starting from the 2024 Secondary 1 cohort onwards, according to MOE.</p>
        <p>It will be replaced by G1 Humanities, a subject that is also non-examinable.</p>
        <p>At the lower secondary level, G1 Humanities will include social studies and the Humanities Exposure Modules of Geography, History, and Literature in English.</p>
        <p>On the other hand, G1 Humanities at the upper secondary level will comprise Social Studies and one of three exposure modules of the student's choice.</p>
        <p>Lower secondary students who have been offered G2 and G3 Humanities will continue with existing subjects of Geography, History, and Literature in English.</p>
        <p>At the upper secondary level, students will continue with Social Studies and a Humanities Elective - Geography, History or Literature in English - of their choice.</p>
        <p>They may also choose to take an additional G2 or G3 Humanities subject - full Geography, full History, full Literature in English.</p>
        <h4 className="title">Secondary Education Certificate (SEC)</h4>
        <p>In Secondary 4, the entire cohort will take a standard national exam, replacing the current O and N level exams. At the end of secondary school, students will receive a 
        common certificate, the Singapore-Cambridge Secondary Education Certificate (SEC), which reflects their different subject combinations and levels. Students will take their 
        subject papers based on their level - G1 to G3. Those who want to spend a fifth year in secondary school to take subjects at a more demanding level will continue to be able to do so.</p>
        <p>Adapted from: </p>
        <ol style={{listStyleType:'lower-alpha', paddingLeft:'40px', paddingBottom:'20px'}}>
          <li><a href="https://sapgrp.com/a-guide-to-the-changes-to-psle-o-level-and-n-level-examinations-all-you-need-to-know/" target="_blank" rel="noopener noreferrer">https://sapgrp.com/a-guide-to-the-changes-to-psle-o-level-and-n-level-examinations-all-you-need-to-know/</a></li>
          <li><a href="https://mothership.sg/2023/03/full-sbb-posting-groups-announcement/" target="_blank" rel="noopener noreferrer">https://mothership.sg/2023/03/full-sbb-posting-groups-announcement/</a></li>
          <li><a href="https://www.straitstimes.com/singapore/politics/teachers-students-adapt-to-full-subject-based-banding-say-it-caters-to-diverse-interests-and-strengths" target="_blank" rel="noopener noreferrer">https://www.straitstimes.com/singapore/politics/teachers-students-adapt-to-full-subject-based-banding-say-it-caters-to-diverse-interests-and-strengths</a></li>
        </ol>
        <p></p>
        <div style={{ textAlign: 'center' }}>
          <p>Keen to learn more about Eduz Tuition?</p>
          <a href="https://wa.me/+6590625285?lang=en"
          target="_blank"
          rel="noreferrer"
          className="theme-btn-five"
          data-aos="fade-in"
          data-aos-duration="1200"
          data-aos-delay="200"
          onClick={() => AnalyticsEvent({
            category: "WhatsAppEnquire",
            action: "Click",
            source: "/blog/a-guide-to-the-new-subject-based-banding-system-for-secondary-school",
            label: "blogClickWhatsAppEnquire"
          })}
        >
        <i className="fa fa-whatsapp"></i> Chat with us now
      </a>
      </div>
      <p></p>
    </div>
    </React.Fragment>
  },
  {
    "img": "/images/site/blog/20230303_changes-curriculum.png",
    "tag": "News",
    "title": "Changes to A level curriculum",
    "slug": "changes-to-a-level-curriculum",
    "date": "3 Mar 2023",
    "publishDate": "2023-03-03T12:00:00.000+08:00",
    "content":
    <React.Fragment>
      <div className="post-meta pb-100"><a href="/blog" className="theme-btn-five"><i className="fa fa-arrow-left"></i> Back</a>
        <div className="tag">3 Mar 2023</div>
        <h3 className="title">
          Changes to A level curriculum 
        </h3>
        <h4 className="title">1. Fourth content-based subject not counted in university admissions</h4>
        <p>From 2026, students from junior colleges (JCs) and Millennia Institute (MI) will no longer be required 
        to count their fourth content-based subject in their university admissions, unless it improves their score.</p>
        <p>Currently, students from JCs and MI, who apply to autonomous universities, must include four content-based subjects, 
          General Paper and Project Work in their application.</p>
        <p>For a student who offers four H2 content-based subjects, the lowest-scoring subject at H2 level is considered as a subject 
          at H1 level when calculating the score. This will remain the case under the new system. </p>
        <p>Today, 20 points are awarded for each of three H2 subjects. General Paper, Project Work, and the remaining content subjects 
          account for 10 points each, bringing the highest possible score to 90 points. Mother Tongue, which also counts for 10 points, 
          is only considered if it improves the score, and the total score is then rebased to 90 points.</p>
        <p>Under the new system, 20 points will continue to be awarded for each of the three H2 subjects. The General Paper will also 
            account for 10 points, but both the remaining content subject and Mother Tongue will be included only if they improve the 
            total score, upon a total of 70 points.</p>

        <img
          src="/images/site/blog/20230303_changes-curriculum.png"
          alt="Changes to curriculum"
          style={{ maxHeight: '550px', marginLeft: 'auto', marginRight: 'auto', paddingTop: '20px', paddingBottom: '20px' }}
        />
        
        <h4 className="title">2. Removal of Mid-year exams</h4>
        <p>From 2024, mid-year exams will be gradually removed in JCs and MI. Fortunately, these changes do not mean more school-based 
          assessments. Schools are also imposed a cap of one weighted assessment per subject per term. These changes are part of the 
          ministry’s efforts to move away from an over-emphasis on testing and academic results.</p>

        <h4 className="title">3. Removal of PW from calculation of university admission score</h4>
        <p>To give students more leeway to pursue their interests without worrying about results, Project Work (PW) will be removed 
          from the calculation of their university admission grade for the JC1 intake in 2024. Instead of letter grades, they will 
          be graded pass or fail. Passing the subject is required for admission to autonomous universities.</p>

        <h4 className="title">4. Making General Paper compulsory</h4>
        <p>General Paper will be mandatory for all JC and MI students beginning in 2024 to provide students with opportunities to 
          develop skills such as critical thinking through the study of current affairs and contemporary issues.</p>
          <p>Knowledge and Inquiry, a subject that deals with the nature and construction of knowledge, may currently be offered 
            in place of General Paper or as a "contrasting subject." Students must take at least one contrasting subject instead 
            of all humanities or all science subjects.</p>

        <p>In summary, the main changes to the A level curriculum are:</p>
        <ol>
          <li>Fourth content-based subject not counted in university admissions unless it helps in the overall score</li>
          <li>Removal of mid-year examinations</li>
          <li>Removal of Project Work from calculation of university scores</li>
          <li>General Paper becomes mandatory</li>
        </ol>
        <p></p>
        <div style={{ textAlign: 'center' }}>
          <p>Keen to learn more about Eduz Tuition?</p>
          <a href="https://wa.me/+6590625285?lang=en"
          target="_blank"
          rel="noreferrer"
          className="theme-btn-five"
          data-aos="fade-in"
          data-aos-duration="1200"
          data-aos-delay="200"
          onClick={() => AnalyticsEvent({
            category: "WhatsAppEnquire",
            action: "Click",
            source: "/blog/changes-to-a-level-curriculum",
            label: "blogClickWhatsAppEnquire"
          })}
        >
        <i className="fa fa-whatsapp"></i> Chat with us now
      </a>
      </div>
      <p></p>
      </div>
    </React.Fragment>
  },
  {
    "img": "/images/site/blog/20230303_2023-calendar.png",
    "tag": "News",
    "title": `Must know dates for 2023`,
    "slug": "must-know-dates-for-2023",
    "date": "3 Mar 2023",
    "publishDate": "2023-03-03T12:00:00.000+08:00",
    "content":
    <React.Fragment>
      <div className="post-meta pb-100"><a href="/blog" className="theme-btn-five"><i className="fa fa-arrow-left"></i> Back</a>
        <div className="tag">3 Mar 2023</div>
        <h3 className="title">
        Must Know Dates For 2023
        </h3>
        <img
          src="/images/site/blog/20230303_2023-calendar.png"
          alt="2023 Calendar"
          style={{ maxHeight: '450px', marginLeft: 'auto', marginRight: 'auto', paddingTop: '20px', paddingBottom: '20px' }}
        />
        <p>Dear Parents, Students and Teachers,</p>
        <p>Below are the schooling schedule, public and school holidays for 2023. Mark your holidays!</p>
        <p><strong>School Calendar 2023</strong></p>
        <table className="styled-table">
        <thead>
            <tr>
                <th></th>
                <th>PRIMARY & SECONDARY</th>
                <th>JUNIOR COLLEGE (JC) Year 1</th>
                <th>JC Year 2 </th>
            </tr>
        </thead>
        <tbody>
            <tr className="active-row">
              <td colspan="4"><strong>Semester I</strong></td>
            </tr>
            <tr>
                <td><strong>Term I</strong></td>
                <td>Tue 3 Jan<sup>1</sup> to Fri 10 Mar</td>
                <td>Tue 3 Feb to Fri 10 Mar</td>
                <td>Mon 9 Jan to Fri 10 Mar</td>
            </tr>
            <tr>
              <td><strong>Term I Break</strong></td>
              <td colspan="3">Sat 11 Mar to Sun 19 Mar</td>
            </tr>
            <tr>
              <td><strong>Term II</strong></td>
              <td colspan="3">Mon 20 Mar to Fri 26 May</td>
            </tr>
            <tr>
              <td><strong>Term II Break</strong></td>
              <td colspan="3">Sat 27 May to Sun 25 Jun</td>
            </tr>
            <tr className="active-row">
              <td colspan="4"><strong>Semester II</strong></td>
            </tr>
            <tr>
              <td><strong>Term III</strong></td>
              <td colspan="3">Mon 26 Jun to Fri 1 Sep</td>
            </tr>
            <tr>
              <td><strong>Term III Break</strong></td>
              <td colspan="3">Sat 2 Sep to Sun 10 Sep</td>
            </tr>
            <tr>
                <td><strong>Term IV</strong></td>
                <td>Mon 11 Sep to Fri 17 Nov<sup>2</sup></td>
                <td>Mon 11 Sep to Fri 24 Nov</td>
                <td>Mon 11 Sep to end of GCE A-Level</td>
            </tr>
            <tr>
                <td><strong>Term IV Break</strong></td>
                <td>Sat 18 Nov to Sun 31 Dec</td>
                <td>Sat 25 Nov to Sun 31 Dec</td>
                <td>End of GCE A-Level to 31 Dec</td>
            </tr>
        </tbody>
        </table>
        <p><sup>1</sup><i>Primary 1 and Kindergarten 1 will start school on Tuesday, 3 January 2023. Primary 2 to 6 and Kindergarten 2 will start school on Wednesday, 4 January 2023.</i>
        <br/><sup>2</sup><i>The last day of the final school term for schools which will be used as venues for the GCE O-Level written examinations, will be Friday, 27 October 2023.</i></p>
      
        <p><strong>Public and School Holidays 2023</strong></p>
        <table className="styled-table">
          <tbody>
            <tr>
              <td rowspan="4"><strong>Term I</strong></td>
              <td>New Year’s Day</td>
              <td>Sun 1 Jan</td>
            </tr>
            <tr>
              <td>Day after New Year’s Day</td>
              <td>Mon 2 Jan</td>
            </tr>
            <tr>
              <td rowspan="2">Chinese New Year</td>
              <td>Sun 22 Jan</td>
            </tr>
            <tr>
              <td>Mon 23 Jan</td>
            </tr>
            <tr>
              <td rowspan="5"><strong>Term II</strong></td>
              <td>Good Friday</td>
              <td>Fri 7 Apr</td>
            </tr>
            <tr>
              <td>Hari Raya Puasa</td>
              <td>Sat 22 Apr</td>
            </tr>
            <tr>
              <td>Off-in-lieu for Hari Raya Puasa</td>
              <td>Mon 24 Apr</td>
            </tr>
            <tr>
              <td>Labour Day</td>
              <td>Mon 1 May</td>
            </tr>
            <tr>
              <td>Vesak Day</td>
              <td>Fri 2 Jun</td>
            </tr>
            <tr>
              <td rowspan="6"><strong>Term III</strong></td>
              <td>Hari Raya Haji</td>
              <td>Thu 29 Jun</td>
            </tr>
            <tr>
              <td>Youth Day</td>
              <td>Sun 2 Jul</td>
            </tr>
            <tr>
              <td>Day after Youth Day</td>
              <td>Mon 3 Jul</td>
            </tr>
            <tr>
              <td>National Day</td>
              <td>Wed 9 Aug</td>
            </tr>
            <tr>
              <td>Day after National Day</td>
              <td>Thu 10 Aug</td>
            </tr>
            <tr>
              <td>Teacher’s Day</td>
              <td>Fri 1 Sep</td>
            </tr>
            <tr>
              <td rowspan="4"><strong>Term IV</strong></td>
              <td>Children’s Day <i>(for primary schools and primary sections of full schools only)</i></td>
              <td>Fri 6 Oct</td>
            </tr>
            <tr>
              <td>Deepavali</td>
              <td>Sun 12 Nov</td>
            </tr>
            <tr>
              <td>Day after Deepavali</td>
              <td>Mon 13 Nov</td>
            </tr>
            <tr>
              <td>Christmas Day</td>
              <td>Mon 25 Dec</td>
            </tr>
          </tbody>
        </table>
        <p>The school terms and holidays for 2023 are also listed on the MOE's website at <a href="https://www.moe.gov.sg/calendar" target="_blank" rel="noopener noreferrer">https://www.moe.gov.sg/calendar</a>.</p>
        <p></p>
        <div style={{ textAlign: 'center' }}>
            <p>Keen to learn more about Eduz Tuition?</p>
            <a href="https://wa.me/+6590625285?lang=en"
            target="_blank"
            rel="noreferrer"
            className="theme-btn-five"
            data-aos="fade-in"
            data-aos-duration="1200"
            data-aos-delay="200"
            onClick={() => AnalyticsEvent({
              category: "WhatsAppEnquire",
              action: "Click",
              source: "/blog/must-know-dates-for-2023",
              label: "blogClickWhatsAppEnquire"
            })}
          >
          <i className="fa fa-whatsapp"></i> Chat with us now
        </a>
        </div>
        <p></p>
      </div>
    </React.Fragment>
  },
]


export default blog_202303
