import React from "react";
import { Helmet } from "react-helmet";
import Header from "../../components/shared/Header";
import Footer from "../../components/shared/Footer";
import BlogItem from "../../components/blog/BlogItem";
import BlogSidebar from "../../components/blog/BlogSidebar";
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion";
import Aos from "aos";


const handleClick = () => {
  console.log("clicked");
  setTimeout(() => { Aos.refresh(); }, 100);
}

const BlogList = () => {
  return (
    <div className="main-page-wrapper p0">
      <Helmet>
        <title>
            Blog | EduZ Tuition
        </title>
      </Helmet>
      {/* End Page SEO Content */}

      <Header />
      {/* End Header */}

      {/* =============================================
            Fancy Hero Two
        ==============================================  */}
      <div className="fancy-hero-five bg-white">
        <img
          src="/images/shape/95.svg"
          alt="shape"
          className="shapes shape-one"
        />
        <img
          src="/images/shape/96.svg"
          alt="shape"
          className="shapes shape-two"
        />
        <div className="bg-wrapper ">
          <div className="container">
            <div className="col-lg-10 m-auto text-center">
              <h6 className="page-title">Eduz Tuition Blog</h6>
              <h1 className="heading">
                <span>
                  Check out our latest news and updates
                </span>
              </h1>
            </div>
          </div>
        </div>
        <div className="bubble-one"></div>
        <div className="bubble-two"></div>
        <div className="bubble-three"></div>
        <div className="bubble-four"></div>
        <div className="bubble-five"></div>
        <div className="bubble-six"></div>
        {/* /.bg-wrapper */}
      </div>
      {/* /.fancy-hero-two */}

      {/* =====================================================
            Feature Blog One
        ===================================================== */}
    
    <div className="feature-blog-one blog-page-bg">
        <div className="shapes shape-one"></div>
        <div className="shapes shape-two"></div>
        <div className="shapes shape-three"></div>
        <div className="container">
          <div className="row">
            <div className="col-md-3 col-sm-12">
              <div
                className="accordion-style-four"
                data-aos="fade-up"
                data-aos-duration="1200"
              >
                <div className="faq-wrraper">
                  <Accordion preExpanded={['b']} allowZeroExpanded onClick={handleClick}>
                      <AccordionItem className="card" uuid='b'>
                        <AccordionItemHeading className="card-header">
                          <AccordionItemButton>
                            <h5 className="mb-0">
                              <button className="btn btn-link">Filters</button>{" "}
                            </h5>
                          </AccordionItemButton>
                        </AccordionItemHeading>
                        {/* Accordion Heading */}
                        <AccordionItemPanel className="card-body fadeInUp">
                          <BlogSidebar />
                        </AccordionItemPanel>
                        {/* Accordion Body Content */}
                      </AccordionItem>
                  </Accordion>
                </div>
              </div>
            </div>
            <div className="col-md-9 col-sm-12">
              <div className="row">
                <BlogItem />
              </div>
            </div>
            
          </div>
          {/* End .row */}
          <div
            className="text-center mt-30 md-mt-10"
            data-aos="fade-up"
            data-aos-duration="1200"
            data-aos-delay="50"
          >
          </div>
        </div>
      </div>
      {/* /.feature-blog-one */}

      <Footer/>
    </div>
  );
};

export default BlogList;
