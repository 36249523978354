import React from "react";
import AnalyticsEvent from "../shared/AnalyticsEvent";

const awardsContent = [
  {
    classCol: "col-lg-3 col-md-4 col-6",
    boxClass: "bx-a",
    img: "ratings",
    link: "https://rating.sg/choa-chu-kang/professional-services/education/eduz-tuition/",
    animationDelay: "",
  },
  {
    classCol: "col-lg-3 col-md-4 col-6",
    boxClass: "bx-c",
    img: "ProductNationChoice",
    link: "https://productnation.co/sg/27876/best-tuition-centre-singapore/#p-66819",
    animationDelay: "50",
  },
  {
    classCol: "col-xl-3 col-lg-3 col-md-4 col-6",
    boxClass: "bx-d",
    img: "BestInSingapore-FE",
    link: "https://www.funempire.com/sg/blog/tuition-centre-singapore/#12_EduZ_Tuition",
    animationDelay: "100",
  },
  {
    classCol: "col-xl-3 col-lg-3 col-md-4 col-6",
    boxClass: "bx-e",
    img: "BestInSingapore-3",
    link: "https://www.bestinsingapore.co/best-chinese-tutors-singapore/#14_EduZ_Tuition",
    animationDelay: "150",
  },
  {
    classCol: "col-xl-3 col-lg-3 col-md-4 col-6",
    boxClass: "bx-f",
    img: "TopInSingapore",
    link: "https://mediaonemarketing.com.sg/top-maths-tuition-centres-singapore/#eduz",
    animationDelay: "200",
  },
  {
    classCol: "col-xl-3 col-lg-3 col-md-4 col-6",
    boxClass: "bx-g",
    img: "bell",
    link: "https://schoolbell.sg/best-tuition-centres-in-woodlands/",
    animationDelay: "250",
  },
  {
    classCol: "col-xl-3 col-lg-3 col-md-4 col-6",
    boxClass: "bx-h",
    img: "mirchelley",
    link: "https://www.mirchelleymuses.com/best-tuition-centers-singapore/#EduZ-Tuition",
    animationDelay: "300",
  },
  {
    classCol: "col-xl-3 col-lg-3 col-md-4 col-6",
    boxClass: "bx-h",
    img: "smartsingapore",
    link: "#",
    animationDelay: "350",
  },
];

const Awards = () => {
  return (
    <div className="row justify-content-center">
      {awardsContent.map((val, i) => (
        <div
          className={val.classCol}
          data-aos="fade-up"
          data-aos-duration="1200"
          data-aos-delay={val.animationDelay}
          key={i}
        >
          <div className={`img-box ${val.boxClass}`}>
            <a href={val.link} target="_blank" rel="noreferrer"
              onClick={() => AnalyticsEvent({
                category: "AwardsClick",
                action: "Click",
                source: "/",
                label: val.link
              })}
            >
              <img src={`images/site/homepage/awards/${val.img}.webp`} alt="logo" />
            </a>
          </div>
          {/* /.img-box */}
        </div>
      ))}

      {/* End .col */}
    </div>
  );
};

export default Awards;
