import React from "react";
import { Link } from "react-router-dom";
import blog_202210 from "../../components/blog/content/blog_202210";
import blog_202303 from "../../components/blog/content/blog_202303";
import blog_202304 from "../../components/blog/content/blog_202304";
import blog_202305 from "../../components/blog/content/blog_202305";
import blog_202306 from "../../components/blog/content/blog_202306";
import blog_202307 from "../../components/blog/content/blog_202307";
import blog_202311 from "../../components/blog/content/blog_202311";

const blogList = []
blogList.push.apply(blogList, blog_202311);
blogList.push.apply(blogList, blog_202307);
blogList.push.apply(blogList, blog_202306);
blogList.push.apply(blogList, blog_202305);
blogList.push.apply(blogList, blog_202304);
blogList.push.apply(blogList, blog_202303);
blogList.push.apply(blogList, blog_202210);

const blogKeyword = ["News", "Lifestyle", "Education"];

const recentNews = blogList.slice(0, 5);

const BlogSidebar = () => {
    const search = window.location.search;
    const params = new URLSearchParams(search);
    const k = params.get('keyword')

    return (
        <>
        <div className="blog-sidebar-one">
            {/* /.sidebar-search-form */}

            <div className="sidebar-keyword mb-85 sm-mb-60">
            <h4 className="sidebar-title">Category</h4>
            <ul className="clearfix">
                {blogKeyword.map((val, i) => (
                <li key={i}>
                    <Link to={"/blog/?keyword="+val} className={k===val? "selected": ""}> {val}</Link>
                </li>
                ))}
            </ul>
            </div>
            {/* /.sidebar-keyword */}

            <div className="sidebar-recent-news mb-85 sm-mb-60">
            <h4 className="sidebar-title">Recent News</h4>
            <ul className="unstyled">
                {recentNews.map((news, i) => (
                <li key={i}>
                    <Link to={"/blog/"+news.slug}>
                    <span className="title">{news.title}</span>
                    <span className="date">{news.date}</span>
                    </Link>
                </li>
                ))}
            </ul>
            </div>
            {/* /.sidebar-recent-news */}
        </div>
        {/* /.blog-sidebar-one  */}
        </>
    );
};

export default BlogSidebar;
