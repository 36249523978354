import React from "react";
import { useParams, Redirect } from "react-router-dom";
import { Helmet } from "react-helmet";
import Header from "../../components/shared/Header";
import Footer from "../../components/shared/Footer";
import blog_202210 from "../../components/blog/content/blog_202210";
import blog_202303 from "../../components/blog/content/blog_202303";
import blog_202304 from "../../components/blog/content/blog_202304";
import blog_202305 from "../../components/blog/content/blog_202305";
import blog_202306 from "../../components/blog/content/blog_202306";
import blog_202307 from "../../components/blog/content/blog_202307";
import blog_202308 from "../../components/blog/content/blog_202308";
import blog_202311 from "../../components/blog/content/blog_202311";

const blogList = []
blogList.push.apply(blogList, blog_202311);
blogList.push.apply(blogList, blog_202308);
blogList.push.apply(blogList, blog_202307);
blogList.push.apply(blogList, blog_202306);
blogList.push.apply(blogList, blog_202305);
blogList.push.apply(blogList, blog_202304);
blogList.push.apply(blogList, blog_202303);
blogList.push.apply(blogList, blog_202210);

const BlogDetail = () => {  
  const params = useParams();
  const blog = blogList.find(item => item.slug === params.slug)
  console.log(blog)
    if (blog) {
    return (
        <div className="main-page-wrapper p0">
          <Helmet>
            <title>
              { blog.title } | Eduz Tuition Blog
            </title>
          </Helmet>
          {/* End Page SEO Content */}
    
          <Header />
          {/* End Header */}
    
          {/* =============================================
                Fancy Hero Two
            ==============================================  */}
          <div className="fancy-hero-five bg-white">
            <img
              src="/images/shape/95.svg"
              alt="shape"
              className="shapes shape-one"
            />
            <img
              src="/images/shape/96.svg"
              alt="shape"
              className="shapes shape-two"
            />
            <div className="bg-wrapper ">
              <div className="container">
                <div className="col-lg-10 m-auto text-center">
                  <h6 className="page-title">Eduz Tuition Blog</h6>
                  <h1 className="heading">
                    { blog.title }
                  </h1>
                </div>
              </div>
            </div>
            {/* /.bg-wrapper */}
          </div>
          {/* /.fancy-hero-two */}
    
          {/* =====================================================
                Feature Blog One
            ===================================================== */}
          <div className="blog-page-bg">
            <div className="container">
              <div clasName="row">
              </div>
              <div className="row">
                <div className="col-xl-10 offset-xl-1 col-lg-12 feature-blog-one width-lg blog-details-post-v1">
                  { blog.content }
                </div>
                {/* End .col */}
              </div>
              {/* End .row */}
            </div>
          </div>
          {/* /.feature-blog-one */}
    
          <Footer/>
        </div>
      );
    }
    else {
        return <Redirect to="/404"/>
    }
  };

  
  export default BlogDetail;
  