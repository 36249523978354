import React from "react";
import { Helmet } from "react-helmet";
import Header from "../../components/shared/Header";
import Footer from "../../components/shared/Footer";

import PortfolioAcrylic from "../../components/programmes/art-lessons/PortfolioAcrylic";
import PortfolioDigital from "../../components/programmes/art-lessons/PortfolioDigital";

const ArtClasses = () => {  
  return (
    <div className="main-page-wrapper p0">
      <Helmet>
        <title>
          Art Classes
        </title>
      </Helmet>
      <Header />
      {/* End HeaderFour */}

      
      {/* 	=============================================
				Fancy Hero Three
			============================================== */}
      <div className="fancy-hero-three bg-transparent">
        <div className="shapes shape-one"></div>
        <div className="shapes shape-two"></div>
        <div className="shapes shape-three"></div>
        <div className="shapes shape-four"></div>
        <div className="shapes shape-five"></div>
        <div className="shapes shape-six"></div>
        <div className="bg-wrapper">
          <div className="container">
            <div className="row">
              <div className="col-xl-9 col-lg-10 col-md-10 m-auto">
                <h1 className="heading">Art Classes</h1>
                <p className="sub-heading">
                "Every artist was once an amateur."<br/>- Ralph Waldo Emerson
                </p>
              </div>
            </div>
          </div>
        </div>
        {/* /.bg-wrapper  */}
      </div>
      {/* /.fancy-hero-three */}

      {/* 
      =============================================
          Fancy Three Text Blokc
      ============================================== */}
        <div className="fancy-feature-eight pt-50 md-pt-50">
          <div className="container">
            <div className="block-style-twelve">
              <div className="row">
                <div
                  className="col-lg-6 col-md-8 ml-auto order-lg-last"
                  data-aos="fade-left"
                  data-aos-duration="1200"
                >
                  <div className="illustration-holder">
                    <img src="images/site/programmes/ac-teacher-1.webp" alt="illustration" />
                  </div>
                </div>
                <div
                  className="col-lg-5 order-lg-first"
                  data-aos="fade-right"
                  data-aos-duration="1200"
                >
                  <div className="text-wrapper">
                    <h6>Art Instructor</h6>
                    <h2 className="font-rubik title">Gwen</h2>
                    <p>
                    A multidisciplinary artist who started practicing art since 6.<br/><br/>
                    She has previously clinched the Platinum Award in the prestigious UOB Painting of the Year as well as Silver and Gold with Honours Award in the Singapore Youth Festival.<br/><br/>
                    She was also a Ministry of Education Art Elective Scholar. Prior to teaching at Arts@EduZ, she did an intensive foundation year at the distinguished School of the Art Institute of Chicago, USA. 
                    </p>
                  </div>
                  {/* /.text-wrapper */}
                </div>
              </div>
            </div>
            {/* /.block-style-twelve */}

            <div className="block-style-twelve">
              <div className="row">
                <div
                  className="col-lg-6 col-md-8 mr-auto"
                  data-aos="fade-right"
                  data-aos-duration="1200"
                >
                  <div className="illustration-holder">
                    <img src="images/site/programmes/ac-teacher-2.webp" alt="illustration" />
                  </div>
                </div>
                <div
                  className="col-lg-5"
                  data-aos="fade-left"
                  data-aos-duration="1200"
                >
                  <div className="text-wrapper">
                    <h6>Art Instructor</h6>
                    <h2 className="font-rubik title">Kim</h2>
                    <p>
                    A digital artist with over 5 years of experience using various digital software such as Photoshop.<br/><br/>
                    Prior to her art career at Arts@EduZ, she has been teaching children at tuition centres as well as kids' playpens and is keen to share her love for digital art with kids.
                    </p>
                  </div>
                  {/* /.text-wrapper */}
                </div>
              </div>
            </div>
            {/* /.block-style-twelve */}
          </div>
        </div>
        {/* /.fancy-feature-eight */}

        {/*  =============================================
            Fancy Portfolio Two
        ==============================================  */}
      <div className="fancy-portfolio-four lg-container">
        <div className="container">
          <div className="top-border bottom-border pb-130 md-pb-90">
            <PortfolioAcrylic />
          </div>
        </div>
      </div>
      {/* /.fancy-portfolio-two */}

      {/*  =============================================
            Fancy Portfolio Two
        ==============================================  */}
      <div className="fancy-portfolio-four lg-container">
        <div className="container">
          <div className="top-border bottom-border pb-130 md-pb-90">
            <PortfolioDigital />
          </div>
        </div>
      </div>
      {/* /.fancy-portfolio-two */}

      <Footer/>
    </div>
  );
};

export default ArtClasses;
