import React, {useState} from 'react'

const url = 'https://pgl8khuqh5.execute-api.ap-southeast-1.amazonaws.com/v1/subscribe'

const CallToActionTwo = () => {
  const [toastText, setToastText] = useState('Subscribe')
  const [disabled, setDisabled] = useState(false)

  const onSuccess = (form) => {
    setToastText('Subscribed!')
    setDisabled(true)
  }

  const handleSubmit = (event) => {
    event.preventDefault();

    const form = event.target;
    if (form.email.value === null || form.email.value.match(/^ *$/) !== null || form.email.value.match(
      /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    ) === null){
      return;
    }

    setToastText('Sending')
    setDisabled(true)

    const payload = {
        email: form.email.value,
    }

    post(url, payload, function (err) {
      if (err) {
          return onError(err)
      }
      onSuccess(form)
    })
  };

  const onError = (err) => {
    setToastText('Subscribe failed!')
    setDisabled(false)
    console.log(err)
  }

  function post(url, body, callback) {
    const req = new XMLHttpRequest();
    req.open("POST", url, true);
    req.setRequestHeader("Content-Type", "application/json");
    req.addEventListener("load", function () {
      if (req.status < 400) {
          callback(null, JSON.parse(req.responseText));
      } else {
          callback(new Error("Request failed: " + req.statusText));
      }  
    });
    
    req.send(JSON.stringify(body));
  }           

  return (
    <div className="row align-items-center">
      <div className="col-lg-6">
        <div className="title">
          <h6>Want to hear more about us?</h6>
          <h2>Subscribe to our Newsletter</h2>
        </div>
      </div>
      {/* End .col */}

      <div className="col-lg-6">
        <div className="form-wrapper">
          <form onSubmit={handleSubmit}>
            <input type="text" name="email" placeholder="Email address" />
            <button type="submit" disabled={disabled}>{toastText}</button>
          </form>
          {/* End form */}
        </div>
        {/* /.form-wrapper */}
      </div>
      {/* End .col */}
    </div>
  );
};

export default CallToActionTwo;
