import React from "react";

const FeatureContent = [
  {
    icon: "clarity",
    title: "Greater clarity",
    subTitle: `Students can view the whiteboard at close range from their tablets (provided by us). They are able to refer to texts or diagrams during lessons easily instead of looking all over their papers searching for what the teachers are referring to.`,
    dataDelay: "0",
  },
  {
    icon: "reference",
    title: "Faster reference",
    subTitle: `Teachers can recover concepts taught on digital whiteboard by flipping to the previous pages! No more fumbling through notes or confusing recaps. Besides, can you un-erase a traditional whiteboard?`,
    dataDelay: "100",
  },
  {
    icon: "efficient",
    title: "Boost communication",
    subTitle: `Easy annotations that are on everyone's screen helps bring everyone on the same page. When questions are asked, Students are able to follow and join in the same discussion for interactive learning.
    `,
    dataDelay: "300",
  },
];

const FeatureFive = () => {
  return (
    <div className="row justify-content-center">
      {FeatureContent.map((val, i) => (
        <div
          className="col-lg-4 col-md-6"
          key={i}
          data-aos="fade-up"
          data-aos-duration="1200"
          data-aos-delay={val.dataDelay}
        >
          <div className="block-style-four">
            <div className="icon">
              <img src={`images/site/icons/${val.icon}.svg`} alt="icon" />
            </div>
            <h4>{val.title}</h4>
            <p>{val.subTitle}</p>
          </div>
        </div>
      ))}
    </div>
  );
};

export default FeatureFive;
