import React, {useState} from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";

const TrialForm = () => {
  // for validation
  const validationSchema = Yup.object().shape({
    firstName: Yup.string().required("First Name is required."),
    lastName: Yup.string().required("Last name is required."),
    email: Yup.string().required("Email is required.").email("Entered value does not match email format."),
    school: Yup.string().required("School is required."),
    mobile: Yup.string().required("Mobile is required."),
  });

  const formOptions = { resolver: yupResolver(validationSchema) };
  // get functions to build form with useForm() hook
  const { register, handleSubmit, formState } = useForm(formOptions);
  const { errors } = formState;

  const [toastText, setToastText] = useState('Sign me up!')
  const [disabled, setDisabled] = useState(false)
  const url = 'https://pgl8khuqh5.execute-api.ap-southeast-1.amazonaws.com/v1/trial'


  function onSubmit(data, e) {
    // display form data on success
    e.preventDefault();
    setToastText('Sending')
    setDisabled(true)

    const form = e.target;
    const payload = {
      firstName: form.firstName.value,
      lastName: form.lastName.value,
      email: form.email.value,
      mobile: form.mobile.value,
      branch: form.branch.value,
      level: form.level.value,
      subject: form.subject.value,
      school: form.school.value,
    }

    post(url, payload, function (err) {
        if (err) {
            return onError(err)
        }
        onSuccess(form)
    })
    console.log("Free trial request submitted: " + JSON.stringify(data));
    e.target.reset();
  }

  const onSuccess = (form) => {
    setToastText('Successfully signed up!')
    setDisabled(true)
  }

  const onError = (err) => {
    setToastText('Sign up failed. Please try again.')
    setDisabled(false)
    console.log(err)
  }

  function post(url, body, callback) {
    const req = new XMLHttpRequest();
    req.open("POST", url, true);
    req.setRequestHeader("Content-Type", "application/json");
    req.addEventListener("load", function () {
      if (req.status < 400) {
          callback(null, JSON.parse(req.responseText));
      } else {
          callback(new Error("Request failed: " + req.statusText));
      }  
    });
    
    req.send(JSON.stringify(body));
  }           

  return (
    <form className="mt-50" id="contact-form" onSubmit={handleSubmit(onSubmit)}>
      <div className="messages"></div>
      <div className="row controls">
        <div className="col-md-6">
          <div className="input-group-meta form-group mb-60">
            <label>First Name</label>
            <input
              type="text"
              placeholder="Enter First Name"
              name="firstName"
              {...register("firstName")}
              className={`${errors.firstName ? "is-invalid" : ""}`}
            />
            {errors.firstName && (
              <div className="invalid-feedback">
                {errors.firstName?.message}
              </div>
            )}
          </div>
        </div>
        {/* End .col */}

        <div className="col-md-6">
          <div className="input-group-meta form-group mb-60">
            <label>Last Name</label>
            <input
              type="text"
              placeholder="Enter Last Name"
              name="lastName"
              {...register("lastName")}
              className={`${errors.lastName ? "is-invalid" : ""}`}
            />
            {errors.lastName && (
              <div className="invalid-feedback">{errors.lastName?.message}</div>
            )}
          </div>
        </div>
        {/* End .col */}

        <div className="col-12">
          <div className="input-group-meta form-group mb-60">
            <label>Email Address</label>
            <input
              placeholder="Enter Your Email"
              name="email"
              type="text"
              {...register("email")}
              className={` ${errors.email ? "is-invalid" : ""}`}
            />
            {errors.email && (
              <div className="invalid-feedback">{errors.email?.message}</div>
            )}
          </div>
        </div>
        <div className="col-6">
          <div className="input-group-meta form-group mb-60">
            <label>Mobile Phone</label>
            <input
              placeholder="Enter Mobile number"
              name="mobile"
              type="text"
              {...register("mobile")}
              className={` ${errors.mobile ? "is-invalid" : ""}`}
            />
            {errors.mobile && (
              <div className="invalid-feedback">{errors.mobile?.message}</div>
            )}
          </div>
        </div>
        {/* End .col */}

        <div className="col-6">
          <div className="input-group-meta form-group mb-40">
            <label>Branch</label>
            <select name="branch">
              <option value="Not applicable">Not applicable</option>
              <option value="Choa Chu Kang">Choa Chu Kang</option>
              <option value="Woodlands">Woodlands</option>
              <option value="Admiralty">Admiralty</option>
              <option value="Online">Online</option>
            </select>
          </div>
        </div>

        <div className="col-6">
          <div className="input-group-meta form-group mb-40">
            <label>Level</label>
            <select name="level">
              <option value="NA">Not applicable</option>
              <option value="Lower Primary (P1-3)">Lower Primary (P1-3)</option>
              <option value="Upper Primary (P4-6)">Upper Primary (P4-6)</option>
              <option value="Lower Secondary (Sec 1-2)">Lower Secondary (Sec 1-2)</option>
              <option value="Upper Secondary (Sec 3-5)">Upper Secondary (Sec 3-5)</option>
              <option value="Junior College">Junior College</option>
            </select>
          </div>
        </div>
        <div className="col-6">
          <div className="input-group-meta form-group mb-40">
            <label>Subject</label>
            <select name="subject">
              <option value="English">English</option>
              <option value="Chinese">Chinese</option>
              <option value="Higher Chinese">Higher Chinese</option>
              <option value="Mathematics">Mathematics</option>
              <option value="A Math">A Math</option>
              <option value="Physics">Physics</option>
              <option value="Chemistry">Chemistry</option>
              <option value="Biology">Biology</option>
              <option value="Others">Others</option>
            </select>
          </div>
        </div>
        {/* End .col */}
        <div className="col-12">
          <div className="input-group-meta form-group mb-40">
            <label>School</label>
            <input
              placeholder="Enter your School"
              name="school"
              type="text"
              {...register("school")}
              className={` ${errors.school ? "is-invalid" : ""}`}
            />
            {errors.school && (
              <div className="invalid-feedback">{errors.school?.message}</div>
            )}
          </div>
        </div>

        <div className="col-12">
          <button type="submit" className="theme-btn-five" disabled={disabled}>
            {toastText}
          </button>
        <br/>*Term & conditions apply
        </div>
        {/* End .col */}
      </div>
    </form>
  );
};

export default TrialForm;
