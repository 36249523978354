import ReactGA from "react-ga4";

const AnalyticsEvent = ({
    category,
    action,
    label,
    source
  }) => {
    ReactGA.event(category, {
        event_category: category,
        event_action: action,
        event_label: label,
        event_source: source
      });
  }
  
export default AnalyticsEvent;