import React from "react";
import CountUp from "react-countup";
import VisibilitySensor from "react-visibility-sensor";

const counterUpContent = [
  {
    startCount: "0",
    endCount: "93",
    endPointText: "%",
    countMeta: "students achieved A/B",
    meta: "across all subjects for A Levels",
    animationDelay: "0",
  },
  {
    startCount: "0",
    endCount: "94",
    endPointText: "%",
    countMeta: "students achieved A1/A2",
    meta: " across all subjects for O Levels",
    animationDelay: "100",
  },
  {
    startCount: "0",
    endCount: "92",
    endPointText: "%",
    countMeta: "students achieved AL1-AL3",
    meta: "across all subjects for PSLE",
    animationDelay: "200",
  },
];

const CounterFive = () => {
  const [focus, setFocus] = React.useState(false);
  return (
    <div className="row justify-content-center">
      {counterUpContent.map((val, i) => (
        <div
          className="col-lg-4 col-sm-6"
          data-aos="fade-up"
          data-aos-duration="1200"
          data-aos-delay={val.animationDelay}
          key={i}
        >
          <div className="counter-box-two">
            <h2 className="number">
              <span className="timer">
                {" "}
                <CountUp
                  start={focus ? val.startCount : null}
                  end={val.endCount}
                  duration={1}
                  redraw={true}
                >
                  {({ countUpRef }) => (
                    <VisibilitySensor
                      onChange={(isVisible) => {
                        if (isVisible) {
                          setFocus(true);
                        }
                      }}
                    >
                      <span ref={countUpRef} />
                    </VisibilitySensor>
                  )}
                </CountUp>
              </span>
              {val.endPointText}
            </h2>
            <em>{val.countMeta}</em>
            <p className="font-rubik">{val.meta}</p>
          </div>
        </div>
      ))}
    </div>
  );
};

export default CounterFive;
