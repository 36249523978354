import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

export default function TestimonialThree() {
  const settings = {
    dots: false,
    arrow: true,
    infinite: true,
    speed: 900,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: false,
    margin: 30,
  };

  const TestimonilContent = [
    {
      desc: `I'm thankful for eduz tuition. my boy joined for their math tuition starting this year. and he got huge improvement within the year!!!! Really thankful for the dedicated tutor mr Dave who was very patient and did all he could to assist my boy. He also made the classes very interesting and my boy really enjoyed his lessons every week. Big thanks!`,
      reviewerName: "Jun Hong",
      designation: "ASRJC",
    },
    {
      desc: `I joined Eduz tuition in June last year in preparation for my promos and my results improved almost instantaneously. With the help of Eduz, I cleared promos with flying colours. Thank you Eduz. :)`,
      reviewerName: "Siti",
      designation: "CJC",
    },
    {
      desc: `The teachers here are very patient. I have always scored badly in school.. I started tuition in mid-february here at EduZ Tuition. I took e-maths, my grades really improved a lot`,
      reviewerName: "Sin Yee",
      designation: "Crest Secondary School",
    },
    {
      desc: `I will like to give a big thanks to Mr John.....as my daughter has considerably gained confidence in her GP. I have seen a remarkable change in her GP grade. His teaching is very detail and he is very helpful as well. He is a very dedicated and committed tutor.`,
      reviewerName: "Hwee Bee",
      designation: "Mother of Elaine",
    },
    {
      desc: `First off, please do convey our much gratitude and appreciation to Pauline and John. He scored well with an Aggregate 261. 3A* and 1A(English). He is the top student for P6 cohort this year 2018.`,
      reviewerName: "Mr Lee",
      designation: "Father of Alan",
    },
  ];

  return (
    <Slider {...settings}>
      {TestimonilContent.map((val, i) => (
        <div
          className="item"
          key={i}
          data-aos="fade-up"
          data-aos-duration="1200"
          data-aos-delay="100"
        >
          <p>{val.desc}</p>
          <h6 className="name">{val.reviewerName}</h6>
          <span className="desig">{val.designation}</span>
        </div>
      ))}
    </Slider>
  );
}
