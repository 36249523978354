import React from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import AnalyticsEvent from "../shared/AnalyticsEvent";

const DocEditorTabs = () => {
  return (
    <>
      <Tabs>
        <TabList>
          <Tab>
            <div className="nav-link d-flex align-items-center justify-content-center">
              <span
                className="current tran3s"
              >Choa Chu Kang</span>
              <span
                className="hover tran3s"
              >Choa Chu Kang</span>
            </div>
          </Tab>
          <Tab>
            <div className="nav-link d-flex align-items-center justify-content-center ">
              <span
                className="current tran3s"
              >Woodlands</span>
              <span
                className="hover tran3s"
              >Woodlands</span>
            </div>
          </Tab>
          <Tab>
            <div className="nav-link d-flex align-items-center justify-content-center ">
              <span
                className="current tran3s"
              >Admiralty</span>
              <span
                className="hover tran3s"
              >Admiralty</span>
            </div>
          </Tab>
        </TabList>

        <div className="mt-60 sm-mt-40 tab-content">
          <TabPanel>
            <div className="row no-gutters align-items-center">
              <div className="col-md-6">
                <div className="img-holder">
                  <img src="images/site/contact/centre-choa-chu-kang.webp" alt="" />
                  <div className="ripple-box d-flex align-items-center justify-content-center">
                    <p>Choa Chu Kang</p>
                  </div>
                </div>
                {/* /.img-holder */}
              </div>
              <div className="col-md-6">
                <div className="text-wrapper">
                  <h4>Choa Chu Kang</h4>
                  <p>
                  5 mins walk from Choa Chu Kang MRT<br/>
                  3 mins walk from South View LRT<br/>
                  (In front of basketball court)<br/>
                  </p>
                  <a href="https://youtu.be/iFmY5TB9Pbc"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="theme-btn-five mt-50"
                    onClick={() => AnalyticsEvent({
                      category: "YouTubeVideoView",
                      action: "Click",
                      source: "/contact",
                      label: "clickedCCKDirectionsVideo"
                    })}
                  >
                    {" "}
                    Guide me there
                  </a>
                </div>
                {/* /.text-wrapper */}
              </div>
            </div>
          </TabPanel>
          <TabPanel>
            <div className="row no-gutters align-items-center">
              <div className="col-md-6">
                <div className="img-holder">
                  <img src="images/site/contact/centre-woodlands.webp" alt="" />
                  <div className="ripple-box d-flex align-items-center justify-content-center">
                    <p>Woodlands</p>
                  </div>
                </div>
                {/* /.img-holder */}
              </div>
              <div className="col-md-6">
                <div className="text-wrapper">
                  <h4>Woodlands</h4>
                  <p>
                  5 mins walk from Marsiling MRT<br/>
                  (Above Phillip Investor Centre)<br/>
                  </p>
                  <a href="https://youtu.be/jOabaYrZB9o"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="theme-btn-five mt-50"
                    onClick={() => AnalyticsEvent({
                      category: "YouTubeVideoView",
                      action: "Click",
                      source: "/contact",
                      label: "clickedWoodlandsDirectionsVideo"
                    })}
                  >
                    {" "}
                    Guide me there
                  </a>
                </div>
                {/* /.text-wrapper */}
              </div>
            </div>
          </TabPanel>
          <TabPanel>
            <div className="row no-gutters align-items-center">
              <div className="col-md-6">
                <div className="img-holder">
                  <img src="images/site/contact/centre-admiralty.webp" alt="" />
                  <div className="ripple-box d-flex align-items-center justify-content-center">
                    <p>Admiralty</p>
                  </div>
                </div>
                {/* /.img-holder */}
              </div>
              <div className="col-md-6">
                <div className="text-wrapper">
                  <h4>Admiralty</h4>
                  <p>
                    10 mins walk from Admiralty MRT
                    (Beside Circle Green Park giant spider playground)
                  </p>
                  
                  <a href="https://youtu.be/GA8u4glMfxs"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="theme-btn-five mt-50"
                    onClick={() => AnalyticsEvent({
                      category: "YouTubeVideoView",
                      action: "Click",
                      source: "/contact",
                      label: "clickedAdmiraltyDirectionsVideo"
                    })}
                  >
                    {" "}
                    Guide me there
                  </a>
                </div>
                {/* /.text-wrapper */}
              </div>
            </div>
          </TabPanel>
        </div>
        {/* /.pricing-table-area-six */}
      </Tabs>
    </>
  );
};

export default DocEditorTabs;
