import React from "react";
import AnalyticsEvent from "../shared/AnalyticsEvent";

const Newsletter = () => {
  return (
    <div className="row">
      <div
        className="col-lg-6 order-lg-last"
        data-aos="fade-left"
        data-aos-duration="1200"
      >
        <div className="text-wrapper pl-3">
          <div className="title-style-three mb-35 md-mb-20">
            <h6>EduZ Tuition Newsletter Q4 2024</h6>
            <h2> 
              <span>
                Ace Your Studies{" "}
                <img src="images/shape/line-shape-7.svg" alt="shape" />
              </span>
              with EduZ's Ultimate Study Hacks
            </h2>
          </div>
          {/*  /.title-style-two */}
          <p>
          In this quarter's newsletter, learn about fun ways to utilise your muscle memory. 
          Employ the Feynman Technique to aid you in understanding complex subjects like 
          Mathematics and Economics! Unveil a treasure trove of study hacks and resources 
          to skyrocket your academic performance.  We've also included self-care tips, 
          a spotlight on teacher Dave who specialises in Mathematics and Economics, and much more!
          </p>
          <p className="mt-30">
          <a href="/docs/newsletter/EduZTuition-Q4-2024-Newsletter.pdf"
                target="_blank"
                rel="noreferrer"
                className="theme-btn-five"
                data-aos="fade-up"
                data-aos-duration="1200"
                data-aos-delay="200"
                onClick={() => AnalyticsEvent({
                  category: "NewsletterDownload",
                  action: "Click",
                  source: "/",
                  label: "homepageNewsletterButton-q4-2024"
                })}
              >
                <i className="fa fa-file-pdf-o"></i> Download
              </a>
            </p>
        </div>
      </div>
      <div className="col-lg-6 order-lg-first">
        <div className="img-gallery">
        <a href="/docs/newsletter/EduZTuition-Q4-2024-Newsletter.pdf"
            target="_blank"
            rel="noreferrer"
            data-aos="fade-up"
            data-aos-duration="1200"
            data-aos-delay="200"
            onClick={() => AnalyticsEvent({
              category: "NewsletterDownload",
              action: "Click",
              source: "/",
              label: "homepageNewsletterImage-q4-2024"
            })}
          >
            <img
              src="images/site/homepage/newsletter/q4-2024-newsletter-1.webp"
              alt="media"
              data-aos="fade-right"
              data-aos-duration="1200"
              style={{border: "2px black solid"}}
            />
          </a>
          <img
            src="images/shape/49.svg"
            alt="shape"
            className="shapes shape-one"
          />
          <img
            src="images/shape/54.svg"
            alt="shape"
            className="shapes shape-three"
          />
        </div>
        {/* /.img-gallery */}
      </div>
    </div>
  );
};

export default Newsletter;
