import React from "react";
import { Helmet } from "react-helmet";
import Header from "../../components/shared/Header";
import Footer from "../../components/shared/Footer";
import ScrollspyNav from "react-scrollspy-nav";

const TermsConditions = () => {
  return (
    <div className="doc-container main-page-wrapper">
      <Helmet>
        <title>
          Terms and Conditions for Registration of Classes
        </title>
      </Helmet>
      {/* End Page SEO Content */}

      <Header />
      {/* End Header */}

      {/* =====================================================
				Privacy Policy
			===================================================== */}

      <div className="terms_and_policy">
        <div className="container">
          <ScrollspyNav
            scrollTargetIds={["opt1", "opt2", "opt3", "opt4", "opt5", "opt6", "opt7", "opt8", "opt9", "opt10", "opt11", "opt12", "opt13", "opt14", "opt15", "opt16", "opt17", "opt18"]}
            activeNavClass="active"
            offset={170}
            scrollDuration="300"
          >
            <div className="row align-items-start">
              <div className="col-lg-4 sidenav-sticky">
                <ul className="nav nav-tabs">
                  <li className="nav-item">
                    <a className="nav-link active" href="#opt1">
                      Interpretation
                  </a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link" href="#opt2">
                      Conditions of Continued Enrolment
                    </a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link" href="#opt3">
                      Conduct of Classes
                    </a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link" href="#opt4">
                      Payment and Refunds
                    </a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link" href="#opt5">
                      Intellectual Property & Media Rights
                    </a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link" href="#opt6">
                      Personal Data Protection and Privacy Policy
                    </a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link" href="#opt7">
                      Liability
                    </a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link" href="#opt8">
                      Limitation of Liability
                    </a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link" href="#opt9">
                      Indemnity
                    </a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link" href="#opt10">
                      Force Majeure
                    </a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link" href="#opt11">
                      No Warranty
                    </a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link" href="#opt12">
                      Communications
                    </a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link" href="#opt13">
                      Determinations
                    </a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link" href="#opt14">
                      Confidentiality
                    </a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link" href="#opt15">
                      Entire Agreement
                    </a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link" href="#opt16">
                      Severability
                    </a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link" href="#opt17">
                      Third Party Rights
                    </a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link" href="#opt18">
                      Governing Law and Dispute Resolution
                    </a>
                  </li>
                </ul>
              </div>

              <div className="col-lg-8">
                {/* Tab panes */}
                <div className="tab-content">
                  <div id="opt1">
                    <h2 className="font-gilroy-bold">Eduz Tuition Terms and Conditions for Registration of Classes</h2>
                    <div className="update-date">LAST UPDATED: 3<sup>rd</sup> Mar 2023</div>
                    <h3>Interpretation</h3>
                    <p>1. In these Terms & Conditions, except where the context otherwise requires:</p>
                      <ol style={{listStyleType:'lower-alpha', paddingLeft:'40px', paddingBottom:'20px'}}>
                        <li>“Client”, “you”, or “your” means the individual(s) who has accepted or proposes to accept products or services by EduZ Tuition;</li>
                        <li>“Contract” shall mean collectively these Terms & Conditions, the Registration Form and the EduZ Tuition Privacy Policy and such other policies, guidelines, codes of conduct and/or regulations of EduZ Tuition as are informed and provided to the Client, Parents and Student (and as amended, supplemented and varied) from time to time;</li>
                        <li>“Core Programmes” mean all regular classes, courses, workshops and programmes developed, offered and conducted by EduZ Tuition and/or its Staff for the Student during each Term other than the Holiday Programmes or Special Programmes;</li>
                        <li>“Course Materials” means all materials, documents, worksheets, or instruments (whether in physical or digital copy) used, distributed or otherwise circulated in relation to the Student’s enrolment with EduZ Tuition under the Core Programmes, Holiday Programmes or Special Programmes, including but not limited to all audio or video recordings of classes, and quizzes/tests and activities held in respect thereof;</li>
                        <li>“Class” or “Classes” whether or not such term has been capitalised, mean all classes, courses, workshops and programmes and conducted by EduZ Tuition and/or its Staff for the Student, in respect of the Core Programmes, Holiday Programmes or Special Programmes;</li>
                        <li>“Holiday Programmes” and “Special Programmes” mean all classes, courses, workshops and programmes developed, offered and conducted by EduZ Tuition and/or its Staff for the Student, independent and outside of the Core Programmes;</li>
                        <li>“Infectious Disease” means the definition assigned to it under the Infectious Disease Act (Chapter 137) of Singapore;</li>
                        <li>“Parents” mean the parents of the Student and any person or guardian who has or has accepted parental or legal responsibility for the Student. Parents are responsible, individually and jointly, for complying with their obligations under these Terms and Conditions;</li>
                        <li>“Personal Data” means the definition assigned to it under the Personal Data Protection Act 2012, and shall include any data about you, the Parents or Student, who can be identified from that data and/or other information to which EduZ Tuition has or is likely to have access to.</li>
                        <li>“Programmes” whether or not such term has been capitalised, mean the Core Programmes, Special Programmes and Holiday Programmes, and “Programme” shall mean any one of them;</li>
                        <li>“Registration Form” refers to EduZ Tuition’s registration form pursuant to which the Student is enrolled in EduZ Tuition;</li>
                        <li>“Services” means the classes, courses, workshops and programmes developed, offered and conducted by EduZ Tuition and/or its Staff for the Student under the Contract, and shall include the Core Programmes, Special Programmes and Holiday Programmes;</li>
                        <li>“Staff” means any officer, teacher (whether permanent or temporary), employee, agent, service provider, representative or contractor of EduZ Tuition, whether located in Singapore or outside of Singapore;</li>
                        <li>“Student” means the individual(s) who is the recipient of Services provided by EduZ Tuition;</li>
                        <li>“Term” means each period of the academic year during which EduZ Tuition regularly conducts classes;</li>
                        <li>“Terms & Conditions” mean the terms and conditions contained herein and applicable to the provision of all Services;</li>
                        <li>“EduZ Tuition Privacy Policy” refers to the EduZ Tuition Privacy Policy (as amended, supplemented and varied from time to time)</li>
                      </ol>
                    <p>2. These Terms & Conditions apply to the provision of all Services by EduZ Tuition to the Student upon the engagement of the Client, and are to be complied with by the Client, 
                      Parents and Student at all times and are deemed incorporated in the engagement of EduZ Tuition and the provision of the Services. EduZ Tuition may revise these Terms & Conditions 
                      and the Client, Parent and Student shall continue to be subject to such Terms & Conditions as amended, modified and/or substituted by EduZ Tuition from time to time and published 
                      on EduZ Tuition’s website at www.eduztuition.com. The utilisation and continued utilisation (upon an amendment, modification and/or substitution of the Terms & Conditions) of the 
                      Services by the Student shall be deemed acceptance of these Terms and Conditions by the Client, Parents and Student whether or not the Terms & Conditions (and any subsequent amendment, 
                      modification and/or substitution) are signed and returned to EduZ Tuition by the Client.</p>
                    <p>3. The headings in these Terms & Conditions are for convenience only and shall not affect the interpretation hereof.</p>
                  </div>
                  <div id="opt2">
                    <h3>Conditions of Continued Enrolment</h3>
                    <p>4. The Client and Parents agree to fully disclose all information relevant to the Student’s enrolment and continued enrolment in EduZ Tuition. In the event that EduZ Tuition 
                      subsequently becomes aware that information considered reasonable for consideration for enrolment to EduZ Tuition has been withheld, falsified or is inaccurate, EduZ Tuition 
                      has the right to suspend or terminate the Student from any or all classes.</p>
                    <p>5. EduZ Tuition reserves the right to transfer, suspend and/or terminate the Student with immediate effect from or to any or all classes in the event that the Client, Parents 
                      and/or Student breaches any provisions of the Contract, or EduZ Tuition deems, in its sole discretion, that the Client, Parents and/or Student demonstrates behaviour that is 
                      detrimental to the welfare or safety of the Staff or other parents and students of EduZ Tuition, or prejudicial to good order or the reputation of EduZ Tuition, or for any 
                      other reason that EduZ Tuition deems fit and proper in its absolute discretion. In the event that the Student is terminated from EduZ Tuition pursuant to this clause, 
                      EduZ Tuition will not be under any obligation to return any deposit or fees paid to EduZ Tuition. Without prejudice to the generality of this clause, examples of when 
                      EduZ Tuition may exercise its right include (but is not limited to) cases where the Client, Parents and/or Student:</p>
                      <ol style={{listStyleType:'lower-alpha', paddingLeft:'40px', paddingBottom:'20px'}}>
                        <li>cause or threaten to cause physical injury to other parents, students and/or Staff for any reason;</li>
                        <li>use threatening, abusive or insulting words or behaviour towards other parents, students and/or Staff;</li>
                        <li>harass, cause alarm or distress to other parents, students and/or Staff;</li>
                        <li>fail to comply with any policy, guidelines, code of conduct, regulation, notice, direction or instruction given by EduZ Tuition or any Staff in relation to the conduct of classes or the use of EduZ Tuition’s premises or items or any matter relating to the provision of the Services;</li>
                        <li>persistently behave in a manner that is disruptive and prevents the continuation of classes or the provision of the Services;</li>
                        <li>use, disclose or disseminate any of EduZ Tuition’s trademarks or copyright material without obtaining EduZ Tuition’s prior written consent; or</li>
                        <li>do not pay any fees payable to EduZ Tuition and which are outstanding and remain unpaid as at the first week of commencement of the Term.</li>
                      </ol>
                      <p>Should any investigations be deemed by EduZ Tuition (in its sole discretion) to be necessary or appropriate, the Student may be transferred, suspended or terminated from any or all classes pending such investigation.</p>
                  </div>
                  <div id="opt3">
                    <h3>Conduct of Classes</h3>
                    <p>6. EduZ Tuition reserves the right to do any or all of the following, in its sole discretion, as it may from time to time deem necessary:</p>
                    <ol style={{listStyleType:'lower-alpha', paddingLeft:'40px', paddingBottom:'20px'}}>
                      <li>make all changes to the conduct of classes, including but not limited to, transferring, combining, dividing or dissolving a class, changing the teachers or specialist trainers, rescheduling classes, and varying the manner in which classes are conducted, such variation to include the conducting of classes online (“EClasses”),</li>
                      <li>refuse entry to the Student if the Student is deemed to be unwell, or if EduZ Tuition has reason to believe that the Student has been in contact with or has been exposed to another Student or person who is harbouring or is likely to or is suspected to harbour the agents of an Infectious Disease; and</li>
                      <li>amend or vary the venues and contents of the classes, according to programme priorities and student needs. EduZ Tuition may at its discretion, where feasible, give written notice to the Client, Parent and/or Student prior to effecting or carrying out any of the above changes.</li>
                    </ol>
                    <p>7. EduZ Tuition does not guarantee that a Student will be able to attend any particular class or timeslot or to receive classes from any particular Staff. EduZ Tuition 
                      reserves the right to place a Student in an appropriate class or timeslot (in its absolute discretion) depending on the availability of classes, the class capacity, 
                      available Staff and/or EduZ Tuition’s assessment (in its absolute discretion) of the suitability of the Student in ability, conduct, temperament or any other particular 
                      reason for any particular class.</p>
                    <p>8. The Student shall not be entitled to receive any Course Materials for any classes which he or she has not registered or paid for. In the event that the Student 
                      commences classes mid-way during any programme or Term, the Student shall only be entitled to have access to Course Materials utilised or distributed from the date 
                      on which the Student begins attending the classes. For the purposes of this clause 8, the phrase “Course Materials” shall not include any online or electronic Course 
                      Materials, access to which may be granted to the Student at EduZ Tuition’s sole discretion.</p>
                    <p>9. Fees for unattended lessons are strictly non-refundable. With respect to the Core Programmes, Students may be offered, subject to availability and at EduZ Tuition’s 
                      sole discretion, up to a maximum of 3 replacement classes each Term (the “Replacement Limit”). With respect to the Holiday/Special Programmes, requests made for 
                      replacement classes will be decided on a case-by-case basis subject to availability and at EduZ Tuition’s sole discretion. In the event that it is not possible to arrange 
                      a replacement class due to a lack of vacancies in other classes, the class fees for the class missed shall not be refunded or pro-rated. This clause shall apply notwithstanding 
                      that the Student has valid reason(s) for missing the class. EduZ Tuition will provide the Student with the Course Materials for the particular class which the Student failed to 
                      attend the following week.</p>
                    <p>10. The Replacement Limit shall not apply to Students who are unable to attend a class under the Core Programme (“Core Classes”) as a result of a clash in the timings between 
                      the Core Class and a class held under the Holiday Programme or Special Programme. In such an event, EduZ Tuition may (but is not obliged to) in its discretion: (i) provide the 
                      Student with worksheets for the Core Class(es) missed without an obligation to refund any fees applicable, or (ii) provide replacement Core Class(es) in excess of the Replacement Limit.</p>
                    <p>11. There will be no classes held on </p>
                    <ol style={{listStyleType:'lower-roman', paddingLeft:'40px', paddingBottom:'20px'}}>
                      <li>public holidays (whether general, gazetted or otherwise declared or observed in Singapore),</li>
                      <li>if a public holiday falls on a Sunday, the day next following (not being itself a public holiday),</li>
                      <li>such other days as designated by EduZ Tuition as a non-schooling day for any reason (including without limitation weather/emergency/safety reasons, for the purposes of school retreats/excursions or other administrative nonworking days etc.). </li>
                    </ol>
                      <p>Unless otherwise indicated, no replacement classes will be scheduled and no refund of class fees will be given in lieu of any classes which would otherwise have been held but for the same. EduZ Tuition will however either provide the Student with the Course Materials for that particular class on the prior or following week.</p>
                  </div>
                  <div id="opt4">
                    <h3>Payment and Refunds</h3>
                    <p>12. In consideration of EduZ Tuition providing the Services to the Student, the Client shall pay the applicable fees, together with the applicable Goods & Services Tax (“GST”) or any other tax that may be levied by the Government of Singapore from time to time.</p>
                    <p>13. The applicable fees will be reviewed from time to time and may be increased or decreased by such amount as EduZ Tuition, in its sole discretion, deems fit.</p>
                    <p>14. New Client, Parents and/or Student undertakes to pay the applicable course fees, including the deposit if applicable, upon signing the Registration Form and in any event within three (3) working days from the date of the invoice issued by EduZ Tuition.</p>
                    <p>15. Existing Client, Parents and/or Student undertakes to pay all other applicable fees to EduZ Tuition within two (2) weeks from the date of the invoice issued by EduZ Tuition.</p>
                    <p>16. A Student cannot commence or continue classes with EduZ Tuition whilst any fees are outstanding and unpaid. A Student’s place in any particular Core Programme, Holiday Programme and/or Special Programme shall not be confirmed until and unless full payment of all applicable fees is received by EduZ Tuition.</p>
                    <p>17. Payments to EduZ Tuition may be made by any of the following methods:</p>
                    <ol style={{listStyleType:'lower-alpha', paddingLeft:'40px', paddingBottom:'20px'}}>
                      <li>Cheques must be made payable to “EduZ Tuition” and sent to EduZ Tuition, 223 Choa Chu Kang Central, #01-237, Singapore 680223, by mail or hand;</li>
                      <li>Cashless payment transactions such as PayNow are accepted at the front desk at any outlet during opening hours;</li>
                      <li>Internet banking transfer is available for DBS/POSB/OCBC/UOB account holders.</li>
                    </ol>
                    <p>18. In the event that a Student commences a Core Programme class after the Term has commenced, fees for the current and following Term are payable within three (3) days of EduZ Tuition’s confirmation of available vacancy in the relevant class. EduZ Tuition reserves the right not to accept the Student and/or to cancel or terminate any classes for which payment has not been made by the prescribed due date, and offer the Student’s slot in such classes to other students without further notice to the Student or Parent.</p>
                    <p>19. In the event that a Student’s application to enrol in Holiday Programme or Special Programme is approved by EduZ Tuition, fees for the Holiday/ Special Classes shall be payable within three (3) days of EduZ Tuition’s confirmation of available vacancy in the relevant class(es). EduZ Tuition reserves the right not to accept the Student and/or to cancel or terminate any classes for which payment has not been made by the prescribed due date, and offer the Student’s slot in such classes to other students without further notice to the Student or Parent.</p>
                    <p>20. Fees paid for the Core Programmes, Holiday Programmes or Special Programmes are strictly non-refundable following confirmation from EduZ Tuition that such fees have been duly received by them. For the avoidance of doubt, EduZ Tuition shall be under no obligation to refund any fees paid in the event that a decision is made by the Student or Parent to withdraw from EduZ Tuition at any time before or after the commencement of each Term, Holiday Programme or Special Programme (as the case may be), but after confirmation of payment has been issued by EduZ Tuition.</p>
                    <p>21. [This section is only applicable to Students enrolled in the Core Programmes] A deposit of “4 lessons fee” for Core Programmes shall be payable upon registration and is refundable provided that all the following conditions are fulfilled to EduZ Tuition’s satisfaction:</p>
                    <ol style={{listStyleType:'lower-alpha', paddingLeft:'40px', paddingBottom:'20px'}}>
                      <li>one calendar month’s written notice of withdrawal is given to EduZ Tuition before the last day of the Term. In the event that less than one calendar month notice is given, EduZ Tuition shall not be obliged to refund any part of the deposit;</li>
                      <li>a duly completed withdrawal form is submitted to EduZ Tuition no later than one (1) calendar month before the last day of the Term;</li>
                      <li>Deposit may be used to offset final month’s lesson fee.</li>
                    </ol>
                    <p>22. The amount of deposit is subject to change at any time and without prior notice. For the avoidance of doubt, no deposit is payable in respect of registration for Holiday Programmes or Special Programmes.</p>
                    <p>23. Clause 31 shall not apply, and the deposit will be forfeited, in the event that the Student does not attend the first class of the Core Programme after the commencement of the same.</p>
                    <p>24. Unless otherwise stated, all applicable fees paid are strictly non-refundable and the credit therein is nontransferable to any third party or other Student. The provisions of this clause shall have effect notwithstanding that the relevant class has not commenced or that the Student has not attended any classes.</p>
                    <p>25. EduZ Tuition may in its sole discretion, deem that the Client is eligible for a refund of any applicable fee paid or part thereof and in such event, EduZ Tuition will pay the refunded amount using such payment methods as it may in its sole discretion determine. EduZ Tuition shall be under no obligation to pay the refunded amount via the same payment method used to make the initial payment.</p>
                  </div>
                  <div id="opt5">
                    <h3>Intellectual Property & Media Rights</h3>
                    <p>26. The Client, Parents and Students acknowledge that EduZ Tuition own all rights, title and interest in and to all lessons, classes (including E-Classes), tutorials, courses, and Course Materials, all of which are protected by the relevant copyright and intellectual property laws, and shall not be shared, sold, copied, recorded, reproduced or disclosed by the Client, Parents and/or Students by any means or for any purpose without the express written consent of EduZ Tuition. The Client, Parents and/or Students hereby acknowledge and agree that all copies (whether physical or digital) of the Course Materials, including all additional online or electronic materials provided by EduZ Tuition pursuant to Clause 8 above, are provided by way of loan and not sale. EduZ Tuition hereby grants a non-exclusive licence to the Client, Parents and Students to use the Course Materials for the purposes contemplated under this Contract, and for no other purpose, and the Client, Parents and Students shall not sell, assign, gift, sub-license or otherwise transfer to any third party any rights in the Course Materials without the prior written consent of EduZ Tuition.</p>
                    <p>27. EduZ Tuition further reserves all rights and interests over any intellectual property created arising as a result of the work or actions of the Student in conjunction with any of EduZ Tuition’S staff and/or other students for a purpose, class, event, course and/or activity associated with or offered by EduZ Tuition (“Student’s Work”). To the extent that the Student’s Work includes any copyright or other intellectual property rights entitled to protection under any copyright, trade mark and/or other intellectual property laws of Singapore or elsewhere, the Student and the Parents on behalf of the Student hereby assign and agree to assign to EduZ Tuition absolutely and free from encumbrances the Student’s Work. Any use of any such intellectual property rights by a Client, Parents and/or Student is subject to the prior written consent of EduZ Tuition upon such terms and conditions as may be specified by EduZ Tuition. EduZ Tuition may, at its discretion, allow the Student’s role in creation/development of intellectual property rights to be acknowledged.</p>
                    <p>28. The Client, Parents and Student further acknowledge that it shall have no right to use any trademark owned or used by EduZ Tuition without the express written consent of EduZ Tuition.</p>
                    <p>29. Any unauthorised use, sharing, sale, copying, recording, reproduction or disclosure of the Course Materials (or any part thereof) or EduZ Tuition’s intellectual property rights, including any unauthorised use of any trademark owned or used by EduZ Tuition, shall, in addition to any other rights and remedies available to EduZ Tuition at law, in equity or under statute, be valid grounds for the suspension or termination of a Student.</p>
                    <p>30. Client, Parents and Student consent to EduZ Tuition using the Student’s name, work, photographs, academic records, photographs, audio/visual recordings and other material for promotional and other purposes such as publicising EduZ Tuition and its students’ accomplishments to internal and external audiences, including in print and online, whether during the course of the Student’s enrolment with EduZ Tuition or thereafter.</p>
                  </div>
                  <div id="opt6">
                    <h3>Personal Data Protection and Privacy Policy</h3>
                    <p>31. The Client, Parents and Student consent to the collection, use and disclosure of their Personal Data for the registration and continued enrolment of the Student in EduZ Tuition, and such other purposes as described in the EduZ Tuition Privacy Policy. The Client, Parents and Student consent to any Personal Data collected by EduZ Tuition in accordance with the terms of the EduZ Tuition Group Privacy Policy. EduZ Tuition Privacy Policy can be found online at www.eduztuition.com/terms</p>
                  </div>
                  <div id="opt7">
                    <h3>Liability</h3>
                    <p>32. In consideration of EduZ Tuition and/or its Staff providing the Services to Students, the Client, Parents and Student hereby agree to undertake all the risk and liability arising from or incidental to the provision of the Services to the Students, whether the provision of such Services takes place on EduZ Tuition’s premises or otherwise, and, to the fullest extent permitted by law, neither EduZ Tuition nor its Staff shall be liable for any loss, damages, expenses, personal injury or death of any person which may arise as a result of the Client, Parents or the Student availing of the Services or the use of EduZ Tuition’s premises whether direct or indirect and whether reasonably foreseeable or not, unless such loss, damage, expense, personal injury or death is directly caused solely by the negligence of EduZ Tuition and not attributable at all to any fault, negligence or lack of care on the part of the Client, Parents or the Student.</p>
                    <p>33. The Client, Parents and Student shall be responsible for the security, safety and use of their own personal property on EduZ Tuition’s premises or while otherwise availing of the Services, and EduZ Tuition and/or its Staff shall not be held liable for hurt, injury, loss or damage to the Client, Parents and Student and any of their personal property arising therefrom.</p>
                    <p>34. The Client, Parents and Student shall be solely responsible for the medical, allergies, dietary or any other special conditions or needs (whether physical, mental or emotional) of the Student. While EduZ Tuition will be happy to accommodate, as far as possible, any special requests relating to the Student in respect of the same, EduZ Tuition and/or its Staff accepts no responsibility for ensuring that the Student complies with or obeys any restriction in respect of and/or is provided with any item or accommodation or does or is prevented from doing anything in respect of the same nor does EduZ Tuition and/or its Staff accept any responsibility in respect of any hurt, injuries or illness to the Student arising therefrom.</p>
                    <p>35. EduZ Tuition is not a child care centre and does not provide care, custody, crèche, nursery, babysitting or such similar services. EduZ Tuition and/or its Staff accepts no responsibility whatsoever for the custody or care of any Student whether the Student is travelling to, attending or leaving EduZ Tuition’s premises for the purposes of the Services. It is the responsibility of the Client and/or Parents to ensure that the Student is sent to, attends at and leaves EduZ Tuition’s premises in a timely, safe and orderly fashion.</p>
                  </div>
                  <div id="opt8">
                    <h3>Limitation of Liability</h3>
                    <p>36. Notwithstanding anything to the contrary in the Contract:</p>
                    <ol style={{listStyleType:'lower-alpha', paddingLeft:'40px', paddingBottom:'20px'}}>
                      <li>EduZ Tuition’s sole liability and the maximum extent of such liability (if at all EduZ Tuition’s liability is established) to the Client, Parents and/or Students and the Client, Parents and/or Student’s sole remedy for damages from EduZ Tuition (whether in respect of one claim or a series of connected claims) howsoever caused arising out of the furnishing or the failure to furnish or to adequately furnish the Services or in respect of any obligation of EduZ Tuition under or arising out of the Contract and the provision of the Services is limited to the aggregate sum of all payments of fees made by the Client for the material Term (in the case of Core Programmes) or the Holiday Programme or Special Programme in respect of which such liability arose;</li>
                      <li>indemnify EduZ Tuition against any loss, cost, claim and expense (including legal costs on any indemnity basis) that EduZ Tuition may suffer or incur in protecting or enforcing any rights of EduZ Tuition under this Contract against the Client, Parents and/or Student.</li>
                    </ol>
                  </div>
                  <div id="opt9">
                    <h3>Indemnity</h3>
                    <p>37. The Client shall:</p>
                    <ol style={{listStyleType:'lower-alpha', paddingLeft:'40px', paddingBottom:'20px'}}>
                      <li>indemnify, defend and hold harmless EduZ Tuition and its Staff from and against all loss, claims, demands or causes of action and any liabilities, damages, costs or expenses resulting therefrom (including expenses and legal fees on an indemnity basis) caused by, arising out of or relating to the breach of or non- compliance with any terms of the Contract and these Terms & Conditions on the part of the Client, Parents and/or Student or arising out of any wilful default, misconduct or negligence on the part of the Client, Parents and/or Student;</li>
                      <li>EduZ Tuition shall in no event be liable to the Client, Parents and/or Student for any special, incidental, indirect, consequential or punitive damages, or any loss of profits, opportunity, savings, revenues, business, goodwill or information, whether in contract or in tort or under any other cause of action absolutely, and whether or not caused by acts or omission or negligence of EduZ Tuition or its Staff, and regardless of whether such damages are foreseeable as at the date the Contract was entered into or from time to time.</li>
                    </ol>
                  </div>
                  <div id="opt10">
                    <h3>Force Majeure</h3>
                    <p>38. EduZ Tuition shall not be liable if it is prevented, hindered or delayed from carrying out its obligations to the Client, Parents and/or Student by reason of a Force Majeure event, and EduZ Tuition shall be released from such obligations to the extent that EduZ Tuition is affected by the circumstances of the Force Majeure event and for the period during which those circumstances exist.</p>
                    <p>39. During the period of the Force Majeure event, to ensure its compliance with applicable laws and regulations, EduZ Tuition shall be entitled to make all necessary changes or adjustments to the manner in which its operations and Services are carried out, without the need to amend these Terms & Conditions, or give any prior notification to the Client, Parents and/or Students.</p>
                    <p>40. For the purposes of this clause, “Force Majeure” shall mean any event or act occurring beyond the reasonable control of EduZ Tuition, including but not limited to any of the following events: </p>
                    <ol style={{listStyleType:'lower-alpha', paddingLeft:'40px', paddingBottom:'20px'}}>
                      <li>war, invasion, rebellion, revolution, insurrection or civil war;</li>
                      <li>act of Government;</li>
                      <li>earthquakes, fire, lightning, storms, floods, haze or any other occurrence caused by the operation of the forces of nature;</li>
                      <li>strikes, lockouts, boycotts or labour disputes;</li>
                      <li>terrorism, sabotage or arson;</li>
                      <li>pandemics or epidemics of infectious diseases; or</li>
                      <li> any other event similar to any of the foregoing.</li>
                    </ol>
                  </div>
                  <div id="opt11">
                    <h3>No Warranty</h3>
                    <p>41. EduZ Tuition does not warrant or guarantee that any Services or any assigned teacher or tutor or any course, workshop, class or programme developed and/or conducted by EduZ Tuition will help improve the Student’s academic or other performance and the Client, Parents and/or Student hereby irrevocably agree and undertake to fully waive and indemnify EduZ Tuition and its Staff against all consequences arising from any act or omission on the part of EduZ Tuition, including without limitation any act or omission in respect of EduZ Tuition’s conduct of classes, allocation of Staff and students, marking of worksheets, Core Programme, Holiday Programme and Special Programme Course Materials and conduct of Staff, other students and parents.</p>
                  </div>
                  <div id="opt12">
                    <h3>Communications</h3>
                    <p>42. For the purposes of the provision of the Services and/or any matter relating to the Student:</p>
                    <ol style={{listStyleType:'lower-alpha', paddingLeft:'40px', paddingBottom:'20px'}}>
                      <li>EduZ Tuition will and is entitled to communicate with and take instructions from the Client and all parties who have signed the Registration Form indicating that they are a contact person unless notice in writing to the contrary is subsequently given by the Client;</li>
                      <li>EduZ Tuition is entitled (but is not otherwise obliged) not to communicate or correspond with any person or party (including a Parent) who has not signed the Registration Form indicating that they are a contact person or who has not otherwise subsequently been expressly authorised in writing by the Client; and</li>
                      <li>where EduZ Tuition deems it necessary in the case of urgency or for the purposes of an emergency, EduZ Tuition will contact and communicate with any contact person or party whose details or particulars are known to EduZ Tuition.</li>
                    </ol>
                    <p>43. It is the responsibility of the Client and Parents to update EduZ Tuition in a timely manner in respect of any change in status or particulars of the Client, Parents and/or the Student. </p>
                    <p>44. Unless otherwise provided for to the Contract, notices which the Client, Parents and/or Student are required to give to EduZ Tuition under the Contract must be in writing, addressed to “EduZ Tuition” and sent to 223 Choa Chu Kang Central, #01-237, Singapore 680223.</p>
                  </div>
                  <div id="opt13">
                    <h3>Determinations</h3>
                    <p>45. EduZ Tuition shall be empowered and entitled to make any determination or issue any notification concerning any matters in relation to the Contract and the provision of the Services (including the manner in which the Services are provided and/or the suspension and/or termination of the Services to any Client or Student) which shall (in the absence of manifest error) be conclusive evidence as to that matter and shall be binding on the Client, Parents and/or Student.</p>
                  </div>
                  <div id="opt14">
                    <h3>Confidentiality</h3>
                    <p>46. The Client, Parents and Student shall treat and keep all Terms & Conditions of the Contract and any matter relating to the provision of the Services and all matters or disputes arising out of or in respect of the Contract and/or the provision of Services strictly private and confidential and shall not under any circumstances directly or indirectly through any other person disclose, communicate or publish the same to any third parties (unless required by regulatory or judicial authorities and/or with the express prior written consent of EduZ Tuition) and shall indemnify EduZ Tuition against any breach of or default in respect of the same.</p>
                    <p>47. Under no circumstances shall EduZ Tuition be required to disclose to the Client, Parents, Students or any other party any Personal Data (as defined in the Personal Data Protection Act 2012), personal information, details or particulars, confidential information or the identities of other students, parents, Staff or third parties.</p>
                    <p>48. The obligations of the Client, Parents and Student with respect to Clauses 53 and 54 above shall survive the termination of these Terms and Conditions and/or Contract (as the case may be).</p>
                  </div>
                  <div id="opt15">
                    <h3>Entire Agreement</h3>
                    <p>49. The Contract (comprising these Terms & Conditions, the EduZ Tuition Group Privacy Policy and the Registration Form) shall constitute the entire agreement between the parties and shall supersede any other prior agreements, either oral or in writing, between the Client and/or the Parents and/or the Student and EduZ Tuition. The Client, Parents and Student acknowledge that no representation, inducements, promises or agreements, orally or otherwise, have been made by EduZ Tuition which are not embodied herein.</p>
                  </div>
                  <div id="opt16">
                    <h3>Severability</h3>
                    <p>50. Any term, condition, stipulation, provision or undertaking in the Contract which is or may become illegal, void, prohibited or unenforceable in any respect under any law shall be ineffective to the extent of such illegality, voidness, prohibition or unenforceability but shall not otherwise invalidate or render illegal, void or unenforceable any other term, condition, stipulation, provision or undertaking contained in the Contract.</p>
                  </div>
                  <div id="opt17">
                    <h3>Third Party Rights</h3>
                    <p>51. Save for Staff, a person who is not a party to the Contract has no rights under the Contracts (Right of Third Parties) Act (Chapter 53B) of Singapore to enforce or enjoy the benefit of the terms of the Contract.</p>
                  </div>
                  <div id="opt18">
                    <h3>Governing Law and Dispute Resolution</h3>
                    <p>52. The Contract shall be governed and construed in accordance with the laws of the Republic of Singapore.</p>
                    <p>53. Any dispute arising out of or in connection with the Contract must first be negotiated in good faith between the parties with a view to a resolution of such dispute. Each of the parties hereto irrevocably agrees that, if the dispute is not resolved within 30 days of the date of the dispute first arising, the courts of Singapore shall have exclusive jurisdiction to hear and determine any suit, action or proceeding and to settle any disputes which may arise out of or in connection with the Contract and, for such purposes, irrevocably submits to the exclusive jurisdiction of such courts.</p>
                    <p>54. All disputes and the resolution of such disputes (whether conducted through negotiation, litigation or otherwise) shall be strictly private and confidential between the parties.</p>
                  </div>
                  
                </div>
                

                
                {/*  /.tab-content */}
              </div>
            </div>
          </ScrollspyNav>
        </div>
      </div>
      <Footer/>
    </div>
  );
};

export default TermsConditions;
