import React from "react";
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion";

const FaqContent = [
  {
    title: "Recordings for reference",
    desc: `Have you ever encountered that moment when you remember the teacher has gone through a particular type of question, but can't exactly remember the method to solve it? That’s why some of our selected lessons are recorded for easy reference. You can view them if you miss class too!`,
    expand: "a",
  },
  {
    title: "For the environment",
    desc: `Imagine all the ink, markers and dusters saved- this translates to lower tuition fee for you in the long run. Hardcopy teaching materials are still provided for students. We do not collect registration fee or material fee.`,
    expand: "b",
  },
  {
    title: "Real-time interaction",
    desc: `Students will be able to use the digital whiteboards to present their answers for discussion in the class. This allows everybody to benefit and learn from one another. Teachers are able to write on students’ answers on the whiteboard in real time too!`,
    expand: "c",
  },
];

const Faq = () => {
  return (
    <div className="accordion-style-two pr-5">
      <div className="faq-wrraper">
        <Accordion preExpanded={["a"]} allowZeroExpanded>
          {FaqContent.map((item, i) => (
            <AccordionItem className="card" key={i} uuid={item.expand}>
              <AccordionItemHeading className="card-header">
                <AccordionItemButton>
                  <h5 className="mb-0">
                    <button className="btn btn-link">{item.title}</button>{" "}
                  </h5>
                </AccordionItemButton>
              </AccordionItemHeading>
              {/* Accordion Heading */}
              <AccordionItemPanel className="card-body fadeInUp">
                <p>{item.desc}</p>
              </AccordionItemPanel>
              {/* Accordion Body Content */}
            </AccordionItem>
          ))}
        </Accordion>
      </div>
    </div>
  );
};

export default Faq;
