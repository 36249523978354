import React from "react";
import AnalyticsEvent from "../../shared/AnalyticsEvent";

const LevelFeature = (props) => {
  return (
    <div className="row justify-content-center">
      {props.levelData.map((val, i) => (
        <div
          className={`col-lg-${props.levelCols} col-md-6`}
          key={i}
          data-aos="fade-up"
          data-aos-duration="1200"
          data-aos-delay={val.dataDelay}
        >
          <div className={`block-style-level ${props.levelStyle}`}>
            <div className="icon">
              <img src={`${val.icon}`} alt="icon" />
            </div>
            <h4>{val.title}</h4>
            <p className="font-rubik" dangerouslySetInnerHTML={{__html: val.subTitle}}></p>
            <a href={`/curriculum-package/${val.eventId}`} className="btn" target="_blank" rel="noopener noreferrer"
              onClick={() => AnalyticsEvent({
                category: "CurriculumDownload",
                action: "Click",
                source: "/programmes/"+val.title.startsWith("Primary") ? "primary" : val.title.startsWith("Secondary") ? "secondary" : val.title.startsWith("Junior") ? "junior-college" : "",
                label: val.eventId
              })}
              >
                Download Curriculum Package &nbsp;
                <span className="fa fa-download"></span> 
            </a>
            <a href={val.link} className="btn"
              onClick={() => AnalyticsEvent({
                category: "LearnMore",
                action: "Click",
                source: "/programmes/"+val.title.startsWith("Primary") ? "primary" : val.title.startsWith("Secondary") ? "secondary" : val.title.startsWith("Junior") ? "junior-college" : "",
                label: val.eventId
              })}
              >
                Learn more &nbsp;
                <span className="fa fa-arrow-circle-right"></span> 
            </a>
          </div>
        </div>
      ))}
    </div>
  );
};

export default LevelFeature;
