import React from "react";
import { Link } from "react-router-dom";
import AnalyticsEvent from "../shared/AnalyticsEvent"

const tuitionProgsContent = [
  {
    img: "primary",
    titleTextOne: "",
    titleTextTwo: "Primary School",
    link: "/programmes/primary"
  },
  {
    img: "secondary",
    titleTextOne: "",
    titleTextTwo: "Secondary School",
    link: "/programmes/secondary"
  },
  {
    img: "junior-college",
    titleTextOne: "",
    titleTextTwo: "Junior College",
    link: "/programmes/junior-college"
  },
];

const TuitionProgrammes = () => {
  return (
    <div className="row">
      {tuitionProgsContent.map((val, i) => (
        <div className="col-6 col-lg-4 col-md-4" key={i}>
          <Link to={val.link} style={{ display: 'block' }}
            onClick={() => AnalyticsEvent({
              category: "HomePageProgrammeClick",
              action: "Click",
              source: "/",
              label: "link-"+val.link
            })}
          >
          <div className="block-style-thirtyFour">
            <img
              src={`images/site/homepage/${val.img}.webp`}
              alt="gallery"
              className="w-100 tran5s"
            />
            <div className="hover-content">
              <h4 className="font-gordita">
                <Link to={val.link}
                  onClick={() => AnalyticsEvent({
                    category: "HomePageProgrammeClick",
                    action: "Click",
                    source: "/",
                    label: "image-"+val.link
                  })}
                >
                  {" "}
                  {val.titleTextOne} <br /> {val.titleTextTwo}
                </Link>
              </h4>

              <Link to={val.link} className="arrow"
                onClick={() => AnalyticsEvent({
                  category: "HomePageProgrammeClick",
                  action: "Click",
                  source: "/",
                  label: "arrow-"+val.link
                })}
              >
                <i className="flaticon-right-arrow-1"></i>{" "}
              </Link>
            </div>
            {/* <!-- /.hover-content --> */}
          </div>
          {/* <!-- /.block-style-thirtyFour --> */}
          </Link>
        </div>
      ))}
    </div>
  );
};

export default TuitionProgrammes;
