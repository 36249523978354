import React from "react";
import AnalyticsEvent from "../shared/AnalyticsEvent";

const HomeBanner = (props) => {
  return (
    <div className="home-banner">
      <img
        src="images/shape/100.svg"
        alt="shape"
        className="shapes shape-four"
      />
      <img
        src="images/shape/101.svg"
        alt="shape"
        className="shapes shape-five"
      />
      <img
        src="images/shape/102.svg"
        alt="shape"
        className="shapes shape-six"
      />
      <img
        src="images/shape/103.svg"
        alt="shape"
        className="shapes shape-seven"
      />

      <div className="container">
        <div className="row">
          <div className="col-lg-6 order-lg-last">
            <div className="illustration-holder">
              <video autoPlay loop muted playsInline controls className="w-full h-full object-cover" 
              onClick={() => AnalyticsEvent({
                category: "EduzVideoView",
                action: "Click",
                source: "/",
                label: "clickedEduzVideo"
              })}>
                <source src="videos/eduz-tuition-opt.mp4" type="video/mp4" />
                Your browser does not support the video tag.
              </video>
              {/* <img
                src={props.bannerImg}
                alt="shape"
                className="illustration"
              />
              <img
                src="images/assets/ils_14.1.svg"
                alt="shape"
                className="shapes shape-one"
              />
              <img
                src="images/assets/ils_14.2.svg"
                alt="shape"
                className="shapes shape-two"
              />
              <img
                src="images/assets/ils_14.3.svg"
                alt="shape"
                className="shapes shape-three"
              /> */}
            </div>
          </div>

          <div className="col-lg-6 order-lg-first">
            <div className="text-wrapper">
              <h1 data-aos="fade-up" data-aos-duration="1200">
                <h3>{props.bannerHeader}</h3>
                <div dangerouslySetInnerHTML={{__html: props.bannerText}}/>
              </h1>
              <p
                className="sub-text"
                data-aos="fade-up"
                data-aos-duration="1200"
                data-aos-delay="100"
              >
                <span dangerouslySetInnerHTML={{__html: props.bannerSubText}}/>
              </p>
              <a href="https://wa.me/+6590625285?lang=en"
                target="_blank"
                rel="noreferrer"
                className="theme-btn-five"
                data-aos="fade-up"
                data-aos-duration="1200"
                data-aos-delay="200"
                onClick={() => AnalyticsEvent({
                  category: "WhatsAppEnquire",
                  action: "Click",
                  source: "/",
                  label: "eduzVideoClickWhatsAppEnquire"
                })}
              >
                <i className="fa fa-whatsapp"></i> Enquire Now
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomeBanner;
