import React from "react";
import AnalyticsEvent from "../shared/AnalyticsEvent";

const ContactAddress = () => {
  return (
    <div className="row justify-content-center">
      <div className="col-lg-4 col-sm-6 d-lg-flex">
        <div className="address-info">
          <div className="icon d-flex align-items-end">
            <img src="images/icon/44.svg" alt="icon" />
          </div>
          <div className="title">Choa Chu Kang</div>
          <h4 className="font-rubik">
          <i className={`fa fa-phone`}></i>< a href='tel:+6597973786'
            onClick={() => AnalyticsEvent({
              category: "Call",
              action: "Click",
              source: "/contact",
              label: "CCK - tel:+6597973786"
            })}
          > 9797 3786</a> <br/>
            223 Choa Chu Kang Central <br/>
            #01-237B <br/>
            Singapore 680223
          </h4>
        </div>{" "}
        {/* /.address-info  */}
      </div>
      {/* End .col */}

      <div className="col-lg-4 col-sm-6 d-lg-flex">
        <div className="address-info">
          <div className="icon d-flex align-items-end">
            <img src="images/icon/44.svg" alt="icon" />
          </div>
          <div className="title">Woodlands</div>
          <h4 className="font-rubik">
          <i className={`fa fa-phone`}></i>< a href='tel:+6590625285'
            onClick={() => AnalyticsEvent({
              category: "Call",
              action: "Click",
              source: "/contact",
              label: "Woodlands - tel:+6590625285"
            })}
          > 9062 5285</a><br/>
          306 Woodlands St 31<br/>
          #02-35 <br/>
          Singapore 730306          
          </h4>
        </div>{" "}
        {/* /.address-info  */}
      </div>
      {/* End .col */}

      <div className="col-lg-4 col-sm-6 d-lg-flex">
      <div className="address-info">
          <div className="icon d-flex align-items-end">
            <img src="images/icon/44.svg" alt="icon" />
          </div>
          <div className="title">Admiralty</div>
          <h4 className="font-rubik">
          <i className={`fa fa-phone`}></i>< a href='tel:+6589407170'
            onClick={() => AnalyticsEvent({
              category: "Call",
              action: "Click",
              source: "/contact",
              label: "Admiralty - tel:+6589407170"
            })}
          > 8940 7170</a><br/>
          736 Woodlands Circle<br/>
          #01-513<br/>
          Singapore 730736
          </h4>
        </div>{" "}
        {/* /.address-info  */}
      </div>
      {/* End .col */}
    </div>
  );
};

export default ContactAddress;
