import React from "react";
import { Helmet } from "react-helmet";
import Header from "../../components/shared/Header";
import ContactAddress from "../../components/contact/ContactAddress";
import ContactForm from "../../components/contact/ContactForm";
import Footer from "../../components/shared/Footer";
import DocEditorTabs from "../../components/contact/DocEditorTabs";

const Contact = () => {
  return (
    <div className="main-page-wrapper p0">
      <Helmet>
        <title>
          Contact Us
        </title>
      </Helmet>

      <Header />
      <div className="fancy-hero-four bg-event space-fix">
        <div className="bg-wrapper">
          <div className="container">
            <div className="row">
              <div className="col-xl-9 col-lg-11 col-md-10 m-auto">
                <h6>Contact us</h6>
                <h2>Feel free to contact us or just say hi!</h2>
              </div>
            </div>
          </div>
        </div>
        {/* /.bg-wrapper */}
      </div>
      {/* /.fancy-hero-four */}

      <div className="contact-style-two mb-150">
        <div className="container">
          <div className="contact-info-wrapper">
            <ContactAddress />
            <img
              src="images/shape/64.svg"
              alt="shape"
              className="shapes shape-one"
            />
            <img
              src="images/shape/65.svg"
              alt="shape"
              className="shapes shape-two"
            />
          </div>
          {/* /.contact-info-wrapper */}

          <div className="mt-100" style={{"minHeight":500}}>
            <iframe title="Eduz Tuition Location" src="https://www.google.com/maps/d/embed?mid=13JPfgh7EqFXZeSKOeqp2JQYJEwqmrveC" width="100%" height="500px"></iframe>
          </div>

          <div className="react-tb mt-130 md-mt-80" id="feature">
            <DocEditorTabs />
            <img src="images/shape/175.svg" alt="" className="shapes shape-right" />
            <img src="images/shape/176.svg" alt="" className="shapes shape-left" />
          </div>

          <div className="row mt-50 md-mt-80">
            <div className="col-lg-6 mb-50">
              <div className="form-style-classic ">
                <h3>Opening hours</h3>
                <table>
                  <br/>
                  <tr>
                    <td>Mon - Fri</td>
                    <td style={{"paddingLeft":50}}>2:00pm – 10:00pm</td>
                  </tr>
                  <tr>
                    <td>Sat - Sun</td>
                    <td style={{"paddingLeft":50}}>9:00am – 9:00pm</td>
                  </tr>
                </table>
              </div>
              {/* /.contact-style-two */}
            </div>
            <div className="col-lg-6 mb-50">
              <div className="form-style-classic ">
                <h3>Send us an enquiry</h3>
                <ContactForm />
              </div>
              {/* /.contact-style-two */}
            </div>
          </div>
        </div>
        
      </div>
      <Footer />
    </div>
  );
};

export default Contact;
