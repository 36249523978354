import React from "react";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { Helmet } from "react-helmet";
import Header from "../../../components/shared/Header";
import Footer from "../../../components/shared/Footer";
import AnalyticsEvent from "../../../components/shared/AnalyticsEvent";
import SubjectLevelFeature from "../../../components/programmes/subject/SubjectLevelFeature";

const SubjectList = [
  {
    "subject": "english",
    "title": "English",
  },
  {
    "subject": "chinese",
    "title": "Chinese",
  },
  {
    "subject": "math",
    "title": "Mathematics",
  },
  {
    "subject": "science",
    "title": "Science",
  },
  {
    "subject": "econs",
    "title": "Economics",
  },
]
const Subject = ({ subject }) => {
  const targetId = useLocation();
  console.log(targetId);
  useEffect(() => {
    const el = document.getElementById(targetId.hash.substring(1));
    console.log(el);
    if (el) {
      el.scrollIntoView();
    }
    else{
      const top = document.getElementById("root")
      top.scrollIntoView();
    }
  }, [targetId]);

  return (
    <div className="main-page-wrapper p0">
      <Helmet>
        <title>
        { SubjectList.find((item) => item.subject===subject).title } Programme | EduZ Tuition
        </title>
      </Helmet>
      
      <Header />
      
      {/* 	=============================================
				Fancy Hero Three
			============================================== */}
      <div className="fancy-hero-three bg-transparent mb-100">
        <div className="shapes shape-one"></div>
        <div className="shapes shape-two"></div>
        <div className="shapes shape-three"></div>
        <div className="shapes shape-four"></div>
        <div className="shapes shape-five"></div>
        <div className="shapes shape-six"></div>
        <div className="bg-wrapper">
          <div className="container">
            <div className="row">
              <div className="col-xl-9 col-lg-10 col-md-10 m-auto">
                <h1 className="heading">{ SubjectList.find((item) => item.subject===subject).title }</h1>
                <p className="sub-heading">
                { SubjectList.find((item) => item.subject===subject).title } programme across levels
                </p>
                <a href="https://wa.me/+6590625285?lang=en" target="_blank" rel="noreferrer"
                  className="theme-btn-five mt-50 md-mt-30"
                  onClick={() => AnalyticsEvent({
                    category: "WhatsAppEnquire",
                    action: "Click",
                    source: "/programmes/"+subject,
                    label: "topClickWhatsAppEnquire"+subject
                  })}
                >
                  <i className="fa fa-whatsapp"></i> Enquire Now
                </a>
              </div>
            </div>
          </div>
        </div>
        {/* /.bg-wrapper  */}
      </div>

      <div className="fancy-feature-subject" id="product">
        <div className="bg-wrapper">
          <div className="container">
            <div className="row">
              <div className="col-12">
                  <SubjectLevelFeature subject={subject} layout="2-columns"/>
              </div>
            </div>
            {subject === "math" ? 
            (
              <>
              <div className="row">
                <div className="col-lg-6 col-md-12">
                  <div id="e-math" className="vertical-timeline-header one-column-right">E. Math</div>
                  <SubjectLevelFeature subject="e-math" layout="1-column-right"/>
                </div>
                <div className="col-lg-6 col-md-12">
                  <div id="a-math" className="vertical-timeline-header one-column-left">A. Math</div>
                  <SubjectLevelFeature subject="a-math" layout="1-column-left"/>
                </div>
              </div>
              <div className="row">
                <div id="jc-math" className="vertical-timeline-header two-column">H1/2 Math</div>
                <div className="col-12">
                    <SubjectLevelFeature subject="h12-math" layout="2-columns"/>
                </div>
              </div>
              </>
            ):(<></>)
            }
            {subject === "science" ? 
            (
              <>
              <div className="row">
                <div className="col-lg-4 col-md-12">
                  <div id="combined-phy-chem" className="vertical-timeline-header one-column-right">Combined Phy/Chem</div>
                  <SubjectLevelFeature subject="comb" layout="1-column-right"/>
                </div>
                <div className="col-lg-4 col-md-12">
                  <div id="pure-physics" className="vertical-timeline-header one-column-right">Pure Physics</div>
                  <SubjectLevelFeature subject="phy" layout="1-column-right"/>
                </div>
                <div className="col-lg-4 col-md-12">
                  <div id="pure-chemistry" className="vertical-timeline-header one-column-left">Pure Chemistry</div>
                  <SubjectLevelFeature subject="chem" layout="1-column-left"/>
                </div>
              </div>
              <div className="row">
              <div className="col-lg-6 col-md-12">
                  <div id="jc-physics" className="vertical-timeline-header one-column-right">H1/2 Physics</div>
                  <SubjectLevelFeature subject="h12-phy" layout="1-column-right"/>
                </div>
                <div className="col-lg-6 col-md-12">
                  <div id="jc-chemistry" className="vertical-timeline-header one-column-left">H1/2 Chemistry</div>
                  <SubjectLevelFeature subject="h12-chem" layout="1-column-left"/>
                </div>
              </div>
              </>
            ):(<></>)
            }
            {subject === "english" ? 
            (
              <div className="row">
              <div className="col-12">
                  <div id="gp" className="vertical-timeline-header two-column">H1 General Paper</div>
                  <SubjectLevelFeature subject="gp" layout="2-columns"/>
              </div>
            </div>
            ):(<></>)
            }
            {subject === "chinese" ? 
            (
              <div className="row">
              <div className="col-12">
                  <div id="jc-chinese" className="vertical-timeline-header two-column">H1 Chinese</div>
                  <SubjectLevelFeature subject="h1-chinese" layout="2-columns"/>
              </div>
            </div>
            ):(<></>)
            }
          </div>
          <div className="row">
            <div className="col-12 mb-100 text-center">
              <a href="https://wa.me/+6590625285?lang=en" target="_blank" rel="noreferrer"
                className="theme-btn-five mt-50"
                onClick={() => AnalyticsEvent({
                  category: "WhatsAppEnquire",
                  action: "Click",
                  source: "/programmes/"+subject,
                  label: "bottomClickWhatsAppEnquire"+subject
                })}
              >
                <i className="fa fa-whatsapp"></i> Enquire Now
              </a>
            </div>
          </div>
        </div>
        {/* /.bg-wrapper */}
      </div>
      <Footer/>
    </div>
  );
};

export default Subject;
