import React from "react";

const FeatureContent = [
  {
    icon: "convenience",
    title: "Convenience & Ease Of Access",
    subTitle: `No travelling required - don't need to worry about your child loitering outside before or after lessons. Easy to get started with just laptop/smartphone, wifi and a set of earphone with mic.`,
    dataDelay: "0",
  },
  {
    icon: "supervision",
    title: "Ease of Supervision & Comfort",
    subTitle: `Your child can study at the comfort of their rooms with everything within easy reach. They will never forget to bring anything to 'class' anymore. You can even supervise them during lessons too.`,
    dataDelay: "100",
  },
  {
    icon: "small-class",
    title: "Quality Tutors with small Class Size",
    subTitle: `Our tutors have conducted countless lessons online. Some have done lecture recordings for schools. Class size is kept small so tutors can focus on each student even though it is online.`,
    dataDelay: "300",
  },
];

const FeatureFive = () => {
  return (
    <div className="row justify-content-center">
      {FeatureContent.map((val, i) => (
        <div
          className="col-lg-4 col-md-6"
          key={i}
          data-aos="fade-up"
          data-aos-duration="1200"
          data-aos-delay={val.dataDelay}
        >
          <div className="block-style-four">
            <div className="icon">
              <img src={`images/site/icons/${val.icon}.svg`} alt="icon" />
            </div>
            <h4>{val.title}</h4>
            <p>{val.subTitle}</p>
          </div>
        </div>
      ))}
    </div>
  );
};

export default FeatureFive;
