import React from "react";
import { Helmet } from "react-helmet";
import Header from "../../components/shared/Header";
import Footer from "../../components/shared/Footer";
import HomeBanner from "../../components/homepage/HomeBanner";
import TestimonialThree from "../../components/homepage/TestimonialThree";
import CallToActionTwo from "../../components/about/about-us/CallToActionTwo";
import Features from "../../components/homepage/Features";
import Awards from "../../components/homepage/Awards";
import TuitionProgrammes from "../../components/homepage/TuitionProgrammes";
import Newsletter from "../../components/homepage/Newsletter";

var props = {
  bannerHeader: "Priority Registration | Academic Year 2025",
  bannerText: "<span>$0 registration</span> and material fee for the entire year<br/><span>Free trial class*!</span>",
  bannerSubText: "EduZ Welcome Study Pack worth $58<br/><span style='font-size:50%; font-style:italic'>*T&C applies.</span>",
  eventLabel: "homepageBannerClickEnquire-FreeRegistrationAndMaterialFee",
  bannerImg: "images/site/homepage/parent-happy.webp"
}

const Homepage = () => {
  return (
    <div className="main-page-wrapper p0">
      <Helmet>
        <title>
          EduZ Tuition
        </title>
      </Helmet>

      <Header />

      <HomeBanner {...props}/>
      
      <div className="art-splash-brown mt-120 sm-mt-100 md-mt-20 mb-50">
        <div className="container">
          <div className="title-style text-center pb-50 md-pb-30">
            <h2>Tuition programmes</h2>
            <p className="pt-20">
              Learn more about our specially designed tuition programmes
            </p>
          </div>
          <TuitionProgrammes />
        </div>
      </div>
      
      <div className="plain-yellow-splash pt-130 pb-120 md-pt-100 md-pb-80">
        <div className="container">
          <div className="row">
            <div className="col-xl-7 col-lg-8">
              <div className="title-style-one mb-40 md-mb-20">
                <h2>Why Eduz Tuition?</h2>
                <p className="font-rubik">
                EduZ Tuition is a top notch learning centre providing quality education with a team of dedicated staff and a conducive learning environment.
                </p>
              </div>
            </div>
          </div>

          <div className="wrapper">
            <div className="row justify-content-center">
              <Features />
            </div>
          </div>
        </div>
      </div>
      
      <div className="partner-section mt-170 md-mt-90 pb-120 md-pb-80">
        <div className="container">
          <div className="title-style-nine text-center mb-100">
            <h6>Awards & Achievements</h6>
            <h2>
              <span>Trusted and proven <img src="images/shape/201.svg" alt="shape" /></span>{" "}
              track record.
            </h2>
          </div>
          <Awards />
        </div>

        <img
          src="images/shape/202.svg"
          alt="shape"
          className="shapes shape-one"
        />
        <img
          src="images/shape/203.svg"
          alt="shape"
          className="shapes shape-two"
        />
        <img
          src="images/shape/204.svg"
          alt="shape"
          className="shapes shape-three"
        />
        <img
          src="images/shape/205.svg"
          alt="shape"
          className="shapes shape-four"
        />
        <img
          src="images/shape/206.svg"
          alt="shape"
          className="shapes shape-five"
        />
        <img
          src="images/shape/207.svg"
          alt="shape"
          className="shapes shape-six"
        />
        <img
          src="images/shape/208.svg"
          alt="shape"
          className="shapes shape-seven"
        />
        <img
          src="images/shape/209.svg"
          alt="shape"
          className="shapes shape-eight"
        />
      </div>

      <div
        className="client-feedback-slider-seven mt-50 mb-150"
        id="feedback"
      >
        <div className="inner-container">
          <div className="container">
            <div className="row">
              <div className="col-lg-8 m-auto">
                <img src="images/icon/143.svg" alt="icon" className="m-auto" />
                <div className="title-style-eleven text-center mt-30">
                  <h2>Check out what some of our students and parents say about us.</h2>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-xl-8 col-lg-10 m-auto">
                <div className="clientSliderFive  mt-80 md-mt-50">
                  <TestimonialThree />
                </div>
                <img
                  src="images/shape/156.svg"
                  alt="shape"
                  className="shapes shape-one"
                />
                <img
                  src="images/shape/157.svg"
                  alt="shape"
                  className="shapes shape-two"
                />
                <img
                  src="images/shape/158.svg"
                  alt="shape"
                  className="shapes shape-three"
                />
              </div>
            </div>
          </div>
        </div>
        <img src="images/site/homepage/testimonials-shape.svg" alt="shape" className="bg-image" />
      </div>
      
      {/* 
     =============================================
				Call to Action
		============================================== */}
    <div className="fancy-text-block-eleven pt-50">
        <div className="container">
          <Newsletter />
        </div>
      </div>
      <div className="call-to-action-four  mb-170 md-mb-80">
        <div className="container">
          <div className="bg-wrapper">
            <CallToActionTwo />
          </div>
          {/* /.bg-wrapper */}
        </div>
        {/* /.container */}
      </div>

      <Footer/>
    </div>
  );
};

export default Homepage;
