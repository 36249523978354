import React, { useState } from "react";
import { Link } from "react-router-dom";
import blog_202210 from "../../components/blog/content/blog_202210";
import blog_202303 from "../../components/blog/content/blog_202303";
import blog_202304 from "../../components/blog/content/blog_202304";
import blog_202305 from "../../components/blog/content/blog_202305";
import blog_202306 from "../../components/blog/content/blog_202306";
import blog_202307 from "../../components/blog/content/blog_202307";
import blog_202311 from "../../components/blog/content/blog_202311";

const blogList = []
blogList.push.apply(blogList, blog_202311);
blogList.push.apply(blogList, blog_202307);
blogList.push.apply(blogList, blog_202306);
blogList.push.apply(blogList, blog_202305);
blogList.push.apply(blogList, blog_202304);
blogList.push.apply(blogList, blog_202303);
blogList.push.apply(blogList, blog_202210);

const BlogItem = () => {
  const [keyword, setKeyword] = useState("");
  var newBlogList = "";

  const search = window.location.search;
  const params = new URLSearchParams(search);
  const k = params.get('keyword')
  if (keyword !== k){
    setKeyword(k);
  }

  if (keyword){
      newBlogList = blogList.filter(b => b.tag === keyword);
  }
  else{
    newBlogList = blogList
  }
  return (
    <>
      {newBlogList.map((item, i) => (
        item.slug !== "" && (item.publishDate === "" || (new Date(item.publishDate) <= Date.now())) ? 
        (
        <div
          className="col-lg-6 col-sm-12"
          data-aos="fade-up"
          data-aos-duration="600"
          key={i}
          data-aos-delay={i*10}
        >
          <div className="post-meta">
            <Link to={"/blog/"+item.slug} className="title">
              <img
                src={`${item.img}`}
                alt="media"
                className="image-meta"
              />
            </Link>
            <div className="tag">{item.tag} | { item.date }</div>
            <h3>
              <Link to={"/blog/"+item.slug} className="title">
                {item.title}
              </Link>
            </h3>
            <Link
              to={"/blog/"+item.slug}
              className="read-more d-flex justify-content-between align-items-center"
            >
              <span>Read More</span>
              <i className="flaticon-right-arrow"></i>
            </Link>
          </div>
          
          {/* /.post-meta */}
        </div>
        ):(<></>)
      ))}
    </>
  );
};

export default BlogItem;
