import React from "react";
import { Helmet } from "react-helmet";
import Header from "../../components/shared/Header";
import TrialForm from "../../components/misc/TrialForm";
import Footer from "../../components/shared/Footer";

const Trial = () => {
  return (
    <div className="main-page-wrapper p0">
      <Helmet>
        <title>
          Free Trial
        </title>
      </Helmet>

      <Header />
      <div className="fancy-hero-three bg-transparent">
        <div className="shapes shape-one"></div>
        <div className="shapes shape-two"></div>
        <div className="shapes shape-three"></div>
        <div className="shapes shape-four"></div>
        <div className="shapes shape-five"></div>
        <div className="shapes shape-six"></div>
        <div className="bg-wrapper">
          <div className="container">
            <div className="row">
              <div className="col-xl-9 col-lg-10 col-md-10 m-auto">
                <h2 className="heading">Sign up now</h2>
                <p className="sub-heading">
                  and receive 1 FREE trial lesson!*
                </p>
              </div>
            </div>
          </div>
        </div>
        {/* /.bg-wrapper  */}
      </div>
      {/* /.fancy-hero-four */}

      <div className="contact-style-two mb-150 mt-200">
        <div className="container">
          <div className="contact-info-wrapper">
            <img
              src="images/shape/64.svg"
              alt="shape"
              className="shapes shape-one"
            />
            <img
              src="images/shape/65.svg"
              alt="shape"
              className="shapes shape-two"
            />
          </div>
          {/* /.contact-info-wrapper */}

          <div className="row mt-50 md-mt-80">
            <div className="col-lg-6 mb-50">
              <div className="form-style-classic ">
              <img
                    src="images/site/misc/trial.webp"
                    alt="Free trial"
                  />
              </div>
              {/* /.contact-style-two */}
            </div>
            <div className="col-lg-6 mb-50">
              <div className="form-style-classic ">
                <TrialForm />
              </div>
              {/* /.contact-style-two */}
            </div>
          </div>
        </div>
        
      </div>
      <Footer />
    </div>
  );
};

export default Trial;
