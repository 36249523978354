import React from "react";
import { Helmet } from "react-helmet";
import Header from "../../components/shared/Header";
import Footer from "../../components/shared/Footer";
import ScrollspyNav from "react-scrollspy-nav";
import AnalyticsEvent from "../../components/shared/AnalyticsEvent";

const PrivacyPolicy = () => {
  return (
    <div className="doc-container main-page-wrapper">
      <Helmet>
        <title>
          Privacy Policy
        </title>
      </Helmet>
      {/* End Page SEO Content */}

      <Header />
      {/* End Header */}

      {/* =====================================================
				Privacy Policy
			===================================================== */}

      <div className="terms_and_policy">
        <div className="container">
          <ScrollspyNav
            scrollTargetIds={["opt1", "opt2", "opt3", "opt4", "opt5", "opt6", "opt7", "opt8", "opt9", "opt10", "opt11", "opt12"]}
            activeNavClass="active"
            offset={170}
            scrollDuration="300"
          >
            <div className="row align-items-start">
              <div className="col-lg-4 sidenav-sticky">
                <ul className="nav nav-tabs">
                  <li className="nav-item">
                    <a className="nav-link active" href="#opt1">
                      1. Introduction
                  </a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link" href="#opt2">
                      2. Types of Information Collected and Used
                    </a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link" href="#opt3">
                      3. How we collect Information
                    </a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link" href="#opt4">
                      4. Purposes for which Information is Collected, Used and Disclosed
                    </a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link" href="#opt5">
                      5. Transfer of Information
                    </a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link" href="#opt6">
                      6. Retention of Information
                    </a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link" href="#opt7">
                      7. Right of Access
                    </a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link" href="#opt8">
                      8. Use of Cookies
                    </a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link" href="#opt9">
                      9. Consent
                    </a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link" href="#opt10">
                      10. Disclaimer
                    </a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link" href="#opt11">
                      11. Updates to the Privacy Policy
                    </a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link" href="#opt12">
                      12. Governing Law
                    </a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link" href="#opt12">
                      13. Contact Us
                    </a>
                  </li>
                </ul>
              </div>

              <div className="col-lg-8">
                {/* Tab panes */}
                <div className="tab-content ">
                  <div id="opt1">
                    <h2 className="font-gilroy-bold">Eduz Tuition Privacy Policy</h2>
                    <div className="update-date">LAST UPDATED: 3<sup>rd</sup> Mar 2023</div>
                    <h3>1. Introduction</h3>
                    <p>
                    This Privacy Policy relates to Personal Data collected and the circumstances under which it may be used or disclosed by EduZ Tuition.
                    <br/><br/>EduZ Tuition is committed to respecting your privacy and we aim to maintain a high level of trust with all clients and prospective clients by adhering to the principles of applicable data protection and privacy laws in each country of operation.
                    <br/><br/>For the purposes of this Privacy Policy <strong>“Personal Data”</strong> shall carry the definition assigned to it under the Personal Data Protection Act 2012, and shall include any data about you, the Parents or Student, who can be identified from that data and/or other information to which EduZ Tuition has or is likely to have access to.
                    </p>
                  </div>
                  <div id="opt2">
                    <h3>2. Types of Information Collected and Used</h3>
                    <p>
                      EduZ Tuition collects Personal Data so that we can provide an experience that is responsive and customised to your needs. Examples of Personal Data we collect include:
                      <ol>
                        <li>
                          Personal information about you and your family members, which may include names, identification particulars, contact details, preferences, health information, family background, educational information, demographic data, payment details and transaction history;
                        </li>
                        <li>
                        Information and data generated in the course of any continual relationship with EduZ Tuition, for example, instructions given by you to arrange class enrolments, transfers, replacements, withdrawals, and other information and records you choose to provide by various modes of communication including mail, telephone, SMS, fax, email, internet and market research;
                        </li>
                        <li>
                          Information from cookies, other technologies deployed for the analysis of website usage or other information technology applications used by EduZ Tuition;
                        </li>
                      </ol>
                      <br/>
                      EduZ Tuition may also collect video footage, still images, or audio recordings of you (<strong>“Audio Visual Material”</strong>) recorded in the course of any classes, programmes or lessons conducted, or other services rendered by EduZ Tuition and its staff. The Audio Visual Material form part of the Personal Data collected by EduZ Tuition.
                    </p>
                  </div>
                  <div id="opt3">
                    <h3>3. How we collect Information</h3>
                    <p>
                      EduZ Tuition collects Personal Data from you, your family members, your authorised representatives, publicly available sources, our website, mobile or online services and other channels including emails, phone conversations and face-to-face interactions with our employees.
                    </p>
                  </div>
                  <div id="opt4">
                    <h3>4. Purposes for which Information is Collected, Used and Disclosed</h3>
                    <p>
                    The purposes (collectively referred to as <strong>“Allowed Purposes”</strong>) for which EduZ Tuition collects, uses and discloses Personal Data include:
                    <ol>
                      <li>
                      To provide, operate and administer EduZ Tuition products and services, or to process applications for EduZ Tuition products and services, business or financial transactions, and to maintain service quality levels and train staff;
                      </li>
                      <li>
                        To facilitate and administer the Services enrolled by you, including but not limited to providing you with learning or educational material;
                      </li>
                      <li>
                        To provide product-related services and support, including the provision of administrative support, technical assistance and customer alerts;
                      </li>
                      <li>
                        To facilitate operational processes including but not limited to student profile assessments, class and student management, payment administration and statistical analysis;
                      </li>
                      <li>
                        To maintain accurate client information and enable customised experiences that meet your preferences and needs;     
                      </li>
                      <li>
                        To manage EduZ Tuition’s relationship with you, which may include providing information on EduZ Tuition’s products and services, where specifically consented to or where permissible under applicable laws and regulations;
                      </li>
                      <li>
                        Sending you marketing information about our services, including but not limited to cross-marketing between EduZ Tuition and notifying you of our marketing events, initiatives and promotions;
                      </li>
                      <li>
                        For use in promotional and marketing material distributed by EduZ Tuition whether online or offline, including but not limited to posts on social media, print or electronic copies of newsletters and brochures, promotional video advertisements and print advertisements;
                      </li>
                      <li>
                        To facilitate product development and service refinements via customer feedback, data analysis and market research;
                      </li>
                      <li>
                        To perform internal management, to operate management information systems, to carry out and enable internal and external audits;
                      </li>
                      <li>
                      To monitor and record calls and electronic communications with clients for record-keeping, quality control, training and case investigations;
                      </li>
                      <li>
                        To enforce or defend the rights of EduZ Tuition and its employees;
                      </li>
                      <li>
                        To comply with any applicable laws, regulations, codes of practice, guidelines, or rules or to assist in law enforcement and investigations conducted by any governmental and/or regulatory authority;
                      </li>
                      <li>
                        For safety, legal and regulatory compliance; and
                      </li>
                      <li>
                        Any other incidental business purpose related to or in connection with the above.
                      </li>
                    </ol>
                    <br/>
                    EduZ Tuition may disclose Personal Data for any of the Allowed Purposes to:
                    <ol>
                      <li>
                        Professional advisors, third party service providers, agents or contractors engaged to perform any of the above listed purposes for EduZ Tuition under a duty of confidentiality;
                      </li>
                      <li>
                        Any person or entity which is part of EduZ Tuition although only to the extent necessary to fulfil the Allowed Purpose;
                      </li>
                      <li>
                        Any person to whom disclosure is required under applicable laws or regulations;
                      </li>
                      <li>
                        Any court, tribunal, regulator, or other authority to whom disclosure is necessary under applicable laws or regulations.
                      </li>
                    </ol>
                    <br/>
                    If EduZ Tuition needs to use your Personal Data for any other purposes, we will notify you and obtain your consent beforehand.
                    <br/><br/>You will be given the opportunity to withhold or withdraw your consent for the use of your personal data for these other purposes.
                    </p>
                  </div>
                  <div id="opt5">
                    <h3>5. Transfer of Information</h3>
                    <p>
                      Personal Data may be transmitted by EduZ Tuition to data storage facilities or third party service providers outside Singapore. Regardless of the location where Personal Data is transferred or stored, EduZ Tuition will make reasonable security arrangements to prevent unauthorised access, collection, use, disclosure, copying, modification, disposal or similar risks to your Personal Data. Please note, however, that we cannot be held responsible for unauthorised or unintended use, access or disclosure that is beyond our control.
                    </p>
                  </div>
                  <div id="opt6">
                    <h3>6. Retention of Information</h3>
                    <p>
                      We retain Personal Data in accordance with legal, regulatory, business and operational obligations. 
                    </p>
                  </div>
                  <div id="opt7">
                    <h3>7. Right of Access</h3>
                    <p>
                      You have the right to request a copy of your Personal Data held by us and to request for any inaccurate Personal Data to be rectified. A request can be submitted in writing, by mail or email (details in the “Contact Us” section).
                      <br/><br/>
                      Please note that we may be prevented by law from complying with any request that you may make. We may also decline any request that you may make if the law permits us to do so.
                      <br/><br/>
                      EduZ Tuition reserves the right to charge an administrative fee for providing a copy of your Personal Data. If a fee is to be charged, we will inform you of the amount beforehand and respond to your request after payment is received.
                    </p>
                  </div>
                  <div id="opt8">
                    <h3>8. Use of Cookies</h3>
                    <ol>
                      <li>
                        EduZ Tuition's website collects "cookies" to enhance your experience while browsing the website. A cookie is a text file that is placed on your browser to store and track information about your use of our website such as the frequency of use, profiles of users and their preferred sites. While this cookie can tell us when you enter our sites and which pages you visit, it cannot read data off your hard disk.
                      </li>
                      <li>
                        EduZ Tuition uses cookies for the following purposes:
                        <ol>
                          <li>To build up a profile of how you and other users use our websites and/or other third party websites;</li>
                          <li>To establish usage statistics;</li>
                          <li>To analyse your access to the website in order to improve operability or to identify the cause of problems on EduZ Tuition website;</li>
                          <li>Measure and research the effectiveness of our interactive online content, features, advertisements, and other communications;</li>
                          <li>Make your online experience more efficient and enjoyable.</li>
                        </ol>
                      </li>
                      <li>
                        You can choose to accept or decline cookies. Most web browsers automatically accept cookies, but you can usually modify your browser setting to decline cookies if you prefer. This may prevent you from taking full advantage of the website.
                      </li>
                      <li>
                        Advertisements on our websites may be provided by third party advertisers and their agencies. These may generate cookies to track how many people have seen a particular advertisement (or use the services of third parties to view a particular advertisement), and to track how many people have seen it more than once.
                      </li>
                      <li>
                        Our website may, from time to time, contain links to and from third party websites. If you follow a link to any of these websites, please note that these websites may or may not have their own have their own privacy policies and that we do not accept any responsibility or liability for these policies. Please check these policies before you submit any personal data to these websites.
                      </li>
                    </ol>
                  </div>
                  <div id="opt9">
                    <h3>9. Consent</h3>
                    <p>
                      This Policy applies to all Personal Data that you may provide to us and the Personal Data we hold about you. By providing us with your Personal Data or by accessing, using or viewing the applicable website or any of its services, functions or contents (including transmitting, caching or storing of any such Personal Data), you shall be deemed to have agreed to each and all the terms, conditions, and notices in this Policy.
                      <br/><br/>
                      You may submit a request to withdraw your consent at any time by contacting us in writing, via mail or email.
                      <br/><br/>
                      Do note, in many circumstances, we need to use your Personal Data in order for us to provide you with products or services which you require or have requested. If you do not provide us with the required Personal Data, or if you do not accept this Policy (or any amendments thereto) or withdraw your consent to our use and/or disclosure of your personal data for these purposes, it may not be possible for us to continue to serve you or provide you with the products and services that you require or have requested.
                    </p>
                  </div>
                  <div id="opt10">
                    <h3>10. Disclaimer</h3>
                    <p>
                      To the maximum extent permitted by law, we shall not be liable in any event for any special, exemplary, punitive, indirect, incidental or consequential damages of any kind or for any loss of reputation or goodwill, whether based in contract, tort (including negligence), equity, strict liability, statute or otherwise, suffered as a result of unauthorised or unintended use, access or disclosure of your personal data.
                    </p>
                  </div>
                  <div id="opt11">
                    <h3>11. Updates to the Privacy Policy</h3>
                    <p>
                      EduZ Tuition reserves the right to amend and vary the terms of this Privacy Policy at any time and will provide the updated Policy on our website. The use or continued use of any of EduZ Tuition's services shall be deemed as your agreement and consent to be bound by our Privacy Policy.
                    </p>
                  </div>
                  <div id="opt12">
                    <h3>12. Governing Law</h3>
                    <p>
                      This Policy is governed by the laws of Singapore. You agree to submit to the exclusive jurisdiction of the Courts of Singapore in any dispute relating to this Policy.
                    </p>
                  </div>
                  <div id="opt13">
                    <h3>13. Contact Us</h3>
                    <p>
                    Feel free to contact us for any enquiries, requests and feedback.
                    </p>
                    <h4>By Mail:</h4>
                      <p>
                        Personal Data Protection Officer<br/>
                        223 Choa Chu Kang Central<br/>
                        #01-237B<br/>
                        Singapore 680223
                      </p>
                      <h4>By Email:</h4>
                      <p>
                        <a href="mailto:contact@eduztuition.com"
                        onClick={() => AnalyticsEvent({
                          category: "Email",
                          action: "Click",
                          source: "/privacy",
                          label: "Privacy - mailto:contact@eduztuition.com"
                        })}
                        >contact@eduztuition.com</a>
                      </p>
                  </div>
                </div>
                

                
                {/*  /.tab-content */}
              </div>
            </div>
          </ScrollspyNav>
        </div>
      </div>
      <Footer/>
    </div>
  );
};

export default PrivacyPolicy;
