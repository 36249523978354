import React from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";

const AboutTabs = () => {
  return (
    <>
      <Tabs>
        <TabList className="nav nav-tabs md-mt-50">
          <Tab className="nav-link font-slab">Our Story</Tab>
          <Tab className="nav-link font-slab">Our Mission</Tab>
          <Tab className="nav-link font-slab">Our Vision</Tab>
        </TabList>
        {/* End TabList */}

        <div className="tab-content mt-20">
          <TabPanel>
            <p className="font-rubik">
              Established in 2010, EduZ Tuition was founded by two ex-school teachers. For over 10 years, we have guided over 3000 students and have consistently produced outstanding results. Today, EduZ Tuition is a top notch learning centre providing quality education with a team of dedicated staff and a conducive learning environment.
            </p>
          </TabPanel>
          <TabPanel>
            <p className="font-rubik">
              To bring meaning and passion into every student's life through quality teaching.
            </p>
          </TabPanel>
          <TabPanel>
            <p className="font-rubik">
              Education leading to beyond education.
            </p>
          </TabPanel>
        </div>
        {/* End TabContent */}
      </Tabs>
    </>
  );
};

export default AboutTabs;
