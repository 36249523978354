import React from "react";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import Header from "../../components/shared/Header";
import FancyVideoOne from "../../components/about/about-us/FancyVideoOne";
import AboutTabs from "../../components/about/about-us/AboutTabs";
import CounterThree from "../../components/about/about-us/CounterThree";
import Footer from "../../components/shared/Footer";
import CallToActionTwo from "../../components/about/about-us/CallToActionTwo";
import AnalyticsEvent from "../../components/shared/AnalyticsEvent";

const AboutUs = () => {
  return (
    <div className="main-page-wrapper p0">
      <Helmet>
        <title>
          About Us
        </title>
      </Helmet>

      <Header />
      {/* End HeaderFour */}

      {/* =============================================
				Fancy Text block Twenty
			============================================== */}
      <div className="fancy-text-block-twenty">
        <img
          src="images/shape/124.svg"
          alt="shape"
          className="shapes shape-one"
        />
        <img
          src="images/shape/125.svg"
          alt="shape"
          className="shapes shape-two"
        />
        <div className="container">
          <h1 className="title font-slab">About us</h1>
          <div className="fancy-video-box-one mb-130 sm-mb-150 md-mb-70">
            <FancyVideoOne />
          </div>
          {/* /.fancy-video-box-one */}

          <div className="row">
            <div
              className="col-lg-5"
              data-aos="fade-right"
              data-aos-duration="1200"
            >
              <div className="client-info font-rubik">
                With over <span>3000+ students</span>
              </div>
              <div className="title-style-five">
                <h2>
                  <span>EDU</span>cation
                  <br /> made <span>eZ</span> 
                  <br/>since 2010
                </h2>
              </div>
            </div>
            <div
              className="col-lg-6 col-sm-4 ml-auto"
              data-aos="fade-left"
              data-aos-duration="1200"
            >
              <AboutTabs />
            </div>
          </div>
        </div>
      </div>
      {/* /.fancy-text-block-twenty */}

      {/* =====================================================
				Counter With Icon One
			===================================================== */}
      <div className="counter-with-icon-one">
        <div className="container">
          <div className="border-top pt-50 md-pt-10 mb-50">
            <CounterThree />
          </div>
        </div>
      </div>
      {/* /.counter-with-icon-one */}

      
      {/* =====================================================
				Map Area One
			===================================================== */}
      <div className="map-area-one">
        <div className="si-content">
          <h3>Come say Hi!</h3>
          <p>
            We're located in the Northern part of Singapore!
          </p>
          <Link className="theme-btn-five" to="/contact"
            onClick={() => AnalyticsEvent({
              category: "Contact",
              action: "Click",
              source: "/about-us",
              label: "locateUs"
            })}
          >
              Locate us
          </Link>
        </div>
        <div className="embed-responsive embed-responsive-21by9">
          <iframe
            className="embed-responsive-item"
            title="myFrame"
            src="https://maps.google.com/maps?q=eduztuition&t=&z=13&ie=UTF8&iwloc=&output=embed"
          ></iframe>
        </div>
      </div>
      {/* /.map-area-one */}

      {/* 
     =============================================
				Call to Action
		============================================== */}
      <div className="call-to-action-four  mb-170 md-mb-80">
        <div className="container">
          <div className="bg-wrapper">
            <CallToActionTwo />
          </div>
          {/* /.bg-wrapper */}
        </div>
        {/* /.container */}
      </div>
      {/* /.fancy-short-banner-four */}

      <Footer/>
    </div>
  );
};

export default AboutUs;
