import React from "react";
import { Link, useLocation } from "react-router-dom";
import AnalyticsEvent from "./AnalyticsEvent";

const linksFooterContent = [
  {
    itemName: "Home",
    routePath: "/",
  },
  {
    itemName: "About Us",
    routePath: "/about-us",
  },
  {
    itemName: "Team EduzTuition",
    routePath: "/team",
  },
  {
    itemName: "Testimonials",
    routePath: "/testimonials",
  },
  {
    itemName: "Why EduZ Tuition",
    routePath: "/programmes",
  },
  {
    itemName: "Lower Primary School Tuition",
    routePath: "/programmes/primary",
  },
  {
    itemName: "Upper Primary School Tuition",
    routePath: "/programmes/primary",
  },
  {
    itemName: "Secondary School Tuition",
    routePath: "/programmes/secondary",
  },
  {
    itemName: "Junior College (JC) Tuition",
    routePath: "/programmes/junior-college",
  },
  {
    itemName: "Centre based lessons",
    routePath: "/centre-based-lessons",
  },
  {
    itemName: "Online lessons",
    routePath: "/online-lessons",
  },
  {
    itemName: "Art classes",
    routePath: "/art-classes",
  },
  {
    itemName: "Pricing",
    routePath: "/centre-based-lessons",
  },
  {
    itemName: "Blog",
    routePath: "/blog",
  },
  {
    itemName: "Contact Us",
    routePath: "/contact-us",
  },
];


const subjectsAvailableFooterContent1 = [
  {
    itemName: "Primary 1 School Tuition",
    routePath: "/programmes/primary-1",
  },
  {
    itemName: "Primary 2 School Tuition",
    routePath: "/programmes/primary-2",
  },
  {
    itemName: "Primary 3 School Tuition",
    routePath: "/programmes/primary-3",
  },
  {
    itemName: "Primary 4 School Tuition",
    routePath: "/programmes/primary-4",
  },
  {
    itemName: "Primary 5 School Tuition",
    routePath: "/programmes/primary-5",
  },
  {
    itemName: "Primary 6 School Tuition",
    routePath: "/programmes/primary-6",
  },
  {
    itemName: "Secondary 1 School Tuition",
    routePath: "/programmes/secondary-1",
  },
  {
    itemName: "Secondary 2 School Tuition",
    routePath: "/programmes/secondary-2",
  },
  {
    itemName: "Secondary 3 School Tuition",
    routePath: "/programmes/secondary-3",
  },
  {
    itemName: "Secondary 4 School Tuition",
    routePath: "/programmes/secondary-4",
  },
  {
    itemName: "Junior College 1 Tuition",
    routePath: "/programmes/junior-college-1",
  },
  {
    itemName: "Junior College 2 Tuition",
    routePath: "/programmes/junior-college-2",
  },
];

const subjectsAvailableFooterContent2 = [
  {
    itemName: "English Language",
    routePath: "/programmes/english",
  },
  {
    itemName: "Chinese Language",
    routePath: "/programmes/chinese",
  },
  // {
  //   itemName: "Higher Chinese Language",
  //   routePath: "/programmes/chinese",
  // },
  {
    itemName: "Mathematics",
    routePath: "/programmes/math",
  },
  {
    itemName: "E. Math",
    routePath: "/programmes/math#e-math",
  },
  {
    itemName: "A. Math",
    routePath: "/programmes/math#a-math",
  },
  {
    itemName: "Science",
    routePath: "/programmes/science",
  },
  {
    itemName: "Combined Phy/Chem",
    routePath: "/programmes/science#combined-phy-chem",
  },
  {
    itemName: "Physics",
    routePath: "/programmes/science#pure-physics",
  },
  {
    itemName: "Chemistry",
    routePath: "/programmes/science#pure-chemistry",
  },
  // {
  //   itemName: "Biology",
  //   routePath: "/programmes",
  // },
  {
    itemName: "General Paper (GP)",
    routePath: "/programmes/english#gp",
  },
  {
    itemName: "H1/H2 Mathematics",
    routePath: "/programmes/math#jc-math",
  },
  {
    itemName: "H1/H2 Chemistry",
    routePath: "/programmes/science#jc-chemistry",
  },
  {
    itemName: "H1/H2 Physics",
    routePath: "/programmes/science#jc-physics",
  },
  // {
  //   itemName: "H1/H2 Biology",
  //   routePath: "/programmes",
  // },
  {
    itemName: "H1/H2 Economics",
    routePath: "/programmes/econs",
  },
  {
    itemName: "H1 Chinese",
    routePath: "/programmes/chinese",
  },
  
]

const socialContent = [
  {
    icon: "fa-whatsapp",
    link: "https://wa.me/+6590625285?lang=en",
  },
  {
    icon: "fa-facebook",
    link: "https://www.facebook.com/EduzTuition2010/",
  },
  {
    icon: "fa-instagram",
    link: "https://www.instagram.com/eduztuitionofficial/",
  },
  {
    icon: "fa-youtube",
    link: "https://www.youtube.com/channel/UCJ8oIDR9TpM3YCu0xhXPJnQ/featured",
  },
];


const Footer = () => {
  const location = useLocation();
  return (
    <div className="theme-footer-four pt-100">
      <div className="top-footer mb-100">
        <div className="container">
          <div className="row justify-content-end">
            <div className="col-xl-3 col-lg-3 col-12 footer-about-widget address-list">
              <div className="logo">
                <a href="/">
                  <img src="/images/logo/eduztuition-logo-small.webp" alt="" />
                </a>
              </div>
              <ul className="font-rubik mt-10">
                <li>
                  <a href="mailto:contact@eduztuition.com"
                  onClick={() => AnalyticsEvent({
                    category: "Email",
                    action: "Click",
                    source: location,
                    label: "Footer - mailto:contact@eduztuition.com"
                  })}
                  >contact@eduztuition.com</a>
                </li>
                <li>
                  <a href="tel:+65 9062 5285"
                    onClick={() => AnalyticsEvent({
                      category: "Call",
                      action: "Click",
                      source: location,
                      label: "Footer - tel:+65 9062 5285"
                    })}
                  >+65 9062 5285</a>
                </li>
              </ul>
              <ul className="social-icon d-flex pt-15">
                {socialContent.map((val, i) => (
                  <li key={i}>
                    <a href={val.link} target="_blank" rel="noreferrer"
                    onClick={() => AnalyticsEvent({
                      category: "Social",
                      action: "Click",
                      source: location,
                      label: "Footer - "+val.link
                    })}>
                      <i className={`fa ${val.icon}`}></i>
                    </a>
                  </li>
                ))}
              </ul>
            </div>
            {/* /.footer-list */}

          <div className="col-lg-3 col-md-3 col-12 footer-list">
            <h5 className="footer-title font-slab">Links</h5>
            <ul>
              {linksFooterContent.map((list, i) => (
                <li key={i}>
                  <Link to={list.routePath}
                    onClick={() => AnalyticsEvent({
                      category: "Navigate",
                      action: "Click",
                      source: location,
                      label: "Footer - "+list.itemName
                    })}
                  >{list.itemName}</Link>
                </li>
              ))}
            </ul>
          </div>

          {/* /.footer-list */}

          <div className="col-lg-3 col-md-3 col-12 footer-list">
            <h5 className="footer-title font-slab">Programmes</h5>
            <ul>
              {subjectsAvailableFooterContent1.map((list, i) => (
                <li key={i}>
                  <Link to={list.routePath}
                    onClick={() => AnalyticsEvent({
                      category: "Navigate",
                      action: "Click",
                      source: location,
                      label: "Footer - "+list.itemName
                    })}
                  >{list.itemName}</Link>
                </li>
              ))}
            </ul>
          </div>
          {/* /.footer-list */}

          <div className="col-lg-3 col-md-3 col-12 footer-list">
            <h5 className="footer-title font-slab"> </h5>
            <ul className="pr-5">
              {subjectsAvailableFooterContent2.map((list, i) => (
                <li key={i}>
                  <Link to={list.routePath}
                    onClick={() => AnalyticsEvent({
                      category: "Navigate",
                      action: "Click",
                      source: location,
                      label: "Footer - "+list.itemName
                    })}
                  >{list.itemName}</Link>
                </li>
              ))}
            </ul>
          </div>
          {/* /.footer-list */}
        </div>
      </div>
        {/* /.container */}
    </div>

    <div className="container">
      <div className="bottom-footer-content">
        <p>
          Copyright © {new Date().getFullYear()}{" "}
            EduZ Tuition Pte Ltd.{" "} All rights reserved. | <a href="/terms" target="_blank"
              onClick={() => AnalyticsEvent({
                category: "Navigate",
                action: "Click",
                source: location,
                label: "Footer - Terms"
              })}
              >Terms &amp; Conditions</a> | <a href="/privacy" target="_blank"
              onClick={() => AnalyticsEvent({
                category: "Navigate",
                action: "Click",
                source: location,
                label: "Footer - Privacy"
              })}
              >Privacy Policy</a>
        </p>
      </div>
      {/* /.bottom-footer */}
    </div>
    {/* /.theme-footer-four */}
  </div>
  );
};

export default Footer;
