import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import "react-pro-sidebar/dist/css/styles.css";
import {
  ProSidebar,
  SidebarHeader,
  Menu,
  MenuItem,
  SubMenu,
  SidebarContent,
} from "react-pro-sidebar";
import AnalyticsEvent from "../AnalyticsEvent";

const Level = [
  {
    name: "Primary School Tuition",
    routerPath: "/programmes/primary",
  },
  {
    name: "Secondary School Tuition",
    routerPath: "/programmes/secondary",
  },
  {
    name: "Junior College Tuition",
    routerPath: "/programmes/junior-college",
  }
];

const Subject = [
  {
    name: "English",
    routerPath: "/programmes/english",
  },
  {
    name: "Chinese",
    routerPath: "/programmes/chinese",
  },
  {
    name: "Mathematics",
    routerPath: "/programmes/math",
  },
  {
    name: "E. Math",
    routerPath: "/programmes/math#e-math",
  },
  {
    name: "A. Math",
    routerPath: "/programmes/math#a-math",
  },
  {
    name: "Science",
    routerPath: "/programmes/science",
  },
  {
    name: "Combined Phy/Chem",
    routerPath: "/programmes/science#combined-phy-chem",
  },
  {
    name: "Physics",
    routerPath: "/programmes/science#pure-physics",
  },
  {
    name: "Chemistry",
    routerPath: "/programmes/science#pure-chemistry",
  },
  {
    name: "General Paper",
    routerPath: "/programmes/english#gp",
  },
  {
    name: "H1/H2 Mathematics",
    routerPath: "/programmes/math#jc-math",
  },
  {
    name: "H1/H2 Chemistry",
    routerPath: "/programmes/science#jc-chemistry",
  },
  {
    name: "H1/H2 Physics",
    routerPath: "/programmes/science#jc-physics",
  },
  {
    name: "H1/H2 Economics",
    routerPath: "/programmes/econs",
  },
  {
    name: "H1 Chinese",
    routerPath: "/programmes/chinese#jc-chinese",
  },
];

const MegaMenuMobile = () => {
  const [click, setClick] = useState(false);
  const handleClick = () => setClick(!click);
  const location = useLocation();

  return (
    <div className="mega-menu-wrapper">
      <div className="mob-header multi-mob-header">
        <button className="toggler-menu" onClick={handleClick}>
          <div className={click ? "active" : ""}>
            <span></span>
            <span></span>
            <span></span>
          </div>
        </button>
      </div>
      {/* End Header */}

      <ProSidebar
        className={click ? "mega-mobile-menu menu-open" : "mega-mobile-menu"}
      >
        <SidebarHeader>
          <div className="logo position-static">
            <a href="/">
              <img src="/images/logo/eduztuition-logo-small.webp" alt="home" />
            </a>
          </div>
          <div className="fix-icon text-dark" onClick={handleClick}>
            <img src="/images/icon/close-w.svg" alt="icon" />
          </div>
          {/* Mobile Menu close icon */}

          {/* End logo */}
        </SidebarHeader>
        <SidebarContent>
          <Menu>
            <MenuItem title="Home">
              <Link to="/">Home</Link>
            </MenuItem>
            <SubMenu title="About">
              <MenuItem>
                <Link to="/about-us">About Us</Link>
              </MenuItem>
              <MenuItem>
                <Link to="/team">Team</Link>
              </MenuItem>
              <MenuItem>
                <Link to="/testimonials">Testimonials</Link>
              </MenuItem>
            </SubMenu>
            
            <SubMenu title="Programmes">
              <MenuItem>
                <Link to="/programmes">
                  Why Eduz Tution?
                </Link>
              </MenuItem>
              <MenuItem>
                <Link to="/centre-based-lessons">
                  Centre Based Lessons
                </Link>
              </MenuItem>
              <MenuItem>
                <Link to="/online-lessons">
                  Online Based Lessons
                </Link>
              </MenuItem>
              <SubMenu title="Level" className="plus alt">
                {Level.map((val, i) => (
                  <MenuItem key={i}>
                    <Link to={val.routerPath}>{val.name}</Link>
                  </MenuItem>
                ))}
              </SubMenu>
              <SubMenu title="Subject" className="plus alt">
                {Subject.map((val, i) => (
                  <MenuItem key={i}>
                    <Link to={val.routerPath}>{val.name}</Link>
                  </MenuItem>
                ))}
              </SubMenu>
              <MenuItem>
                {" "}
                <Link to="/art-classes">
                  Art Classes
                </Link>
              </MenuItem>
            </SubMenu>
            <MenuItem title="Blog">
              <Link to="/blog">Blog</Link>
            </MenuItem>
            <MenuItem title="Contact">
              <Link to="/contact">Contact Us</Link>
            </MenuItem>
            <SubMenu title="Enquire Now">
              <MenuItem>
                <a href="https://wa.me/+6597973786?lang=en" target="_blank" rel="noreferrer"
                  onClick={() => AnalyticsEvent({
                    category: "WhatsAppEnquire",
                    action: "Click",
                    source: location,
                    label: "mobileHeaderClickWhatsAppEnquireCCK"
                  })}
                ><i className="fa fa-whatsapp"></i> Choa Chu Kang branch</a>
              </MenuItem>
              <MenuItem>
                <a href="https://wa.me/+6590625285?lang=en" target="_blank" rel="noreferrer"
                  onClick={() => AnalyticsEvent({
                    category: "WhatsAppEnquire",
                    action: "Click",
                    source: location,
                    label: "mobileHeaderClickWhatsAppEnquireWoodlands"
                  })}
                ><i className="fa fa-whatsapp"></i> Woodlands branch</a>
              </MenuItem>
              <MenuItem>
                <a href="https://wa.me/+6589407170?lang=en" target="_blank" rel="noreferrer"
                  onClick={() => AnalyticsEvent({
                    category: "WhatsAppEnquire",
                    action: "Click",
                    source: location,
                    label: "mobileHeaderClickWhatsAppEnquireAdmiralty"
                  })}
                ><i className="fa fa-whatsapp"></i> Admiralty branch</a>
              </MenuItem>
            </SubMenu>
          </Menu>
        </SidebarContent>
      </ProSidebar>
    </div>
  );
};

export default MegaMenuMobile;
