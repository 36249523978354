import React from "react";
import { Helmet } from "react-helmet";
import Header from "../../components/shared/Header";
import Footer from "../../components/shared/Footer";

const Programmes = () => {
  return (
    <div className="main-page-wrapper p0">
      <Helmet>
        <title>
          Programmes
        </title>
      </Helmet>
      <Header />
      {/* End HeaderFour */}
      
            {/* 	=============================================
				Fancy Hero Three
			============================================== */}
      <div className="fancy-hero-three bg-transparent mb-100">
        <div className="shapes shape-one"></div>
        <div className="shapes shape-two"></div>
        <div className="shapes shape-three"></div>
        <div className="shapes shape-four"></div>
        <div className="shapes shape-five"></div>
        <div className="shapes shape-six"></div>
        <div className="bg-wrapper">
          <div className="container">
            <div className="row">
              <div className="col-xl-9 col-lg-10 col-md-10 m-auto">
                <h1 className="heading">Our programmes</h1>
                <p className="sub-heading">
                  Find out why so many of our students and parents choose Eduz Tuition.
                </p>
              </div>
            </div>
          </div>
        </div>
        {/* /.bg-wrapper  */}
      </div>
      {/* /.fancy-hero-three */}


      {/* 	=============================================
				Fancy Feature Seven
			==============================================  */}
      <div className="fancy-feature-twentyOne">
        <div className="container">
          <div className="block-wrapper">
            <div className="block-style-nine">
              <div className="row align-items-center">
                <div
                  className="col-lg-7 col-md-9 m-auto"
                  data-aos="fade-right"
                  data-aos-duration="1200"
                >
                  <div className="illustration-holder">
                    <img src="images/site/programmes/well-structured-teaching-materials.webp" alt="Well Structured Teaching Materials" />
                  </div>
                  {/* /.illustration-holder */}
                </div>
                <div
                  className="col-lg-5"
                  data-aos="fade-left"
                  data-aos-duration="1200"
                >
                  <div className="text-wrapper">
                    <h3 className="title font-gilroy-bold">
                    Well Structured Teaching Materials
                    </h3>
                    <p className="font-rubik">
                    Specially-designed and structured lessons to ensure that our students are exposed to the everchanging landscape of the examination field. This means students are exposed to a wide variety of questions possibly tested.
                    </p>
                  </div>
                  {/* /.text-wrapper */}
                </div>
              </div>
            </div>
            {/* /.block-style-nine */}
            <div className="block-style-nine">
              <div className="row align-items-center">
                <div
                  className="col-lg-7 col-md-9 m-auto order-lg-last"
                  data-aos="fade-left"
                  data-aos-duration="1200"
                >
                  <div className="illustration-holder">
                    <img src="images/site/programmes/experienced-tutors.webp" alt="Experienced Tutors" />
                  </div>{" "}
                  {/* /.illustration-holder */}
                </div>
                <div
                  className="col-lg-5 order-lg-first"
                  data-aos="fade-right"
                  data-aos-duration="1200"
                >
                  <div className="text-wrapper">
                    <h3 className="title font-gilroy-bold">
                    Experienced Tutors
                    </h3>
                    <p className="font-rubik">
                    Most of our teachers have at least <strong>7 years</strong> of teaching experience. This ranges from current school teachers to ex-school teachers who have joined us as full-time tutors.
                    </p>
                  </div>{" "}
                  {/* /.text-wrapper */}
                </div>
              </div>
            </div>{" "}
            {/* /.block-style-nine */}
            <div className="block-style-nine">
              <div className="row align-items-center">
                <div
                  className="col-lg-7 col-md-9 m-auto"
                  data-aos="fade-right"
                  data-aos-duration="1200"
                >
                  <div className="illustration-holder">
                    <img src="images/site/programmes/no-material-registration-fees.webp" alt="No material and registration fees" />
                  </div>{" "}
                  {/* /.illustration-holder */}
                </div>
                <div
                  className="col-lg-5"
                  data-aos="fade-left"
                  data-aos-duration="1200"
                >
                  <div className="text-wrapper">
                    <h3 className="title font-gilroy-bold">
                    No Material And Registration Fee
                    </h3>
                    <p className="font-rubik">
                      We DO NOT collect registration or material fee. 
                    </p>
                  </div>{" "}
                  {/* /.text-wrapper */}
                </div>
              </div>
            </div>{" "}
            {/* /.block-style-nine */}
            <div className="block-style-nine">
              <div className="row align-items-center">
                <div
                  className="col-lg-7 col-md-9 m-auto order-lg-last"
                  data-aos="fade-left"
                  data-aos-duration="1200"
                >
                  <div className="illustration-holder">
                    <img src="images/site/programmes/small-class-size.webp" alt="Small class size" />
                  </div>{" "}
                  {/* /.illustration-holder */}
                </div>
                <div
                  className="col-lg-5 order-lg-first"
                  data-aos="fade-right"
                  data-aos-duration="1200"
                >
                  <div className="text-wrapper">
                    <h3 className="title font-gilroy-bold">
                    Small Class Size
                    </h3>
                    <p className="font-rubik">
                      We are proud of our low teacher-to-student ratio. Students will receive adequate attention from our tutors who will engage them in active discussions and prompt them for questions to clarify their doubts.
                    </p>
                  </div>{" "}
                  {/* /.text-wrapper */}
                </div>
              </div>
            </div>{" "}
            {/* /.block-style-nine */}
            <div className="block-style-nine">
              <div className="row align-items-center">
                <div
                  className="col-lg-7 col-md-9 m-auto"
                  data-aos="fade-right"
                  data-aos-duration="1200"
                >
                  <div className="illustration-holder">
                    <img src="images/site/programmes/track-record.webp" alt="Track record" />
                  </div>{" "}
                  {/* /.illustration-holder */}
                </div>
                <div
                  className="col-lg-5"
                  data-aos="fade-left"
                  data-aos-duration="1200"
                >
                  <div className="text-wrapper">
                    <h3 className="title font-gilroy-bold">
                    Track Record
                    </h3>
                    <p className="font-rubik">
                    Established for more than a decade. 3000+ students and counting! Including various schools' top PSLE, O and A level scorers. Parents and students recommend and believe in us.
                    </p>
                  </div>{" "}
                  {/* /.text-wrapper */}
                </div>
              </div>
            </div>{" "}
            {/* /.block-style-nine */}
            <div className="block-style-nine">
              <div className="row align-items-center">
                <div
                  className="col-lg-7 col-md-9 m-auto order-lg-last"
                  data-aos="fade-left"
                  data-aos-duration="1200"
                >
                  <div className="illustration-holder">
                    <img src="images/site/programmes/efficient-communication-process.webp" alt="Efficient communication process" />
                  </div>{" "}
                  {/* /.illustration-holder */}
                </div>
                <div
                  className="col-lg-5 order-lg-first"
                  data-aos="fade-right"
                  data-aos-duration="1200"
                >
                  <div className="text-wrapper">
                    <h3 className="title font-gilroy-bold">
                    Efficient Communication Process
                    </h3>
                    <p className="font-rubik">
                    We work hand-in-hand with parents to ensure our students' progress. Our highly qualified executives will be available to handle your administrative enquiries, as well as provide constant feedback about students and their pace of learning.
                    </p>
                  </div>{" "}
                  {/* /.text-wrapper */}
                </div>
              </div>
            </div>{" "}
            {/* /.block-style-nine */}
            <div className="block-style-nine">
              <div className="row align-items-center">
                <div
                  className="col-lg-7 col-md-9 m-auto"
                  data-aos="fade-right"
                  data-aos-duration="1200"
                >
                  <div className="illustration-holder">
                    <img src="images/site/programmes/report.webp" alt="Progress report" />
                  </div>{" "}
                  {/* /.illustration-holder */}
                </div>
                <div
                  className="col-lg-5"
                  data-aos="fade-left"
                  data-aos-duration="1200"
                >
                  <div className="text-wrapper">
                    <h3 className="title font-gilroy-bold">
                    Personalised Progress Reports
                    </h3>
                    <p className="font-rubik">
                    Our progress reports after each class allow you to keep track of what your child learns in class, as well as tutor's analysis of your child's strengths, areas for growth, and learning habits to maximise your child's potential.
                    </p>
                  </div>{" "}
                  {/* /.text-wrapper */}
                </div>
              </div>
            </div>{" "}
          </div>
        </div>
      </div>
      {/* /.fancy-feature-seven */}
      
      <Footer/>
    </div>
  );
};

export default Programmes;
