import React from "react";
import { Link } from "react-router-dom";
import AnalyticsEvent from "../../shared/AnalyticsEvent";

const PricingContent = [
  {
    title: "Primary",
    price: "110~$160",
    priceMeta: "4 lessons / 1.5 hours",
    packageName: "pc1",
    skewClass: "skew-right",
    priceList: [
      {
        list: "English",
        listClass: "",
      },
      {
        list: "Creative Writing",
        listClass: "",
      },
      {
        list: "Maths",
        listClass: "",
      },
      {
        list: "Science",
        listClass: "",
      },
      {
        list: "Chinese",
        listClass: "",
      },
      {
        list: "Chinese Creative Writing",
        listClass: "",
      },
    ],
    animatinDelay: "0",
  },
  {
    title: "Secondary",
    price: "220~$250",
    priceMeta: "4 lessons / 1.5 - 2 hours",
    packageName: "pc2",
    skewClass: "skew-left",
    priceList: [
      {
        list: "English",
        listClass: "",
      },
      {
        list: "A. Mathematics",
        listClass: "",
      },
      {
        list: "E. Mathematics",
        listClass: "",
      },
      {
        list: "Science (Physics/Chemistry/Biology)",
        listClass: "",
      },
      {
        list: "Chinese",
        listClass: "",
      },
      {
        list: "Higher Chinese",
        listClass: "",
      },
    ],
    animatinDelay: "100",
  },
  {
    title: "Junior College",
    price: "320",
    priceMeta: "4 lesssons / 2 hours",
    packageName: "pc3",
    skewClass: "skew-right",
    priceList: [
      {
        list: "General Paper",
        listClass: "",
      },
      {
        list: "H1/H2 Mathematics",
        listClass: "",
      },
      {
        list: "H1/H2 Economics",
        listClass: "",
      },
      {
        list: "H1/H2 Physics",
        listClass: "",
      },
      {
        list: "H1/H2 Chemistry",
        listClass: "",
      },
      {
        list: "H1 Chinese",
        listClass: "",
      },
    ],
    animatinDelay: "200",
  },
];

const PricingThree = () => {
  return (
    <div className="row justify-content-center">
      {PricingContent.map((item, i) => (
        <div
          className="col-lg-4 col-md-4"
          key={i}
          data-aos="fade-up"
          data-aos-duration="1200"
          data-aos-delay={item.animatinDelay}
        >
          <div className={`pr-table-wrapper ${item.skewClass}`}>
            <div className={`pack-name font-slab ${item.packageName}`}>
              <span>{item.title}</span>
            </div>
            <div className="price font-slab">${item.price}</div>
            <p className="user-condition">{item.priceMeta}</p>
            <img src="images/shape/114.svg" alt="shape" className="line" />
            <ul>
              {item.priceList.map((val, i) => (
                <li className={val.listClass} key={i}>
                  {val.list}&nbsp;
                </li>
              ))}
            </ul>
            <Link to="/contact" className="subscribe-btn font-rubik"
              onClick={() => AnalyticsEvent({
                category: "PricingClickContactUs",
                action: "Click",
                source: "/online-lessons",
                label: item.title
              })}
            >
              Contact Us
            </Link>
          </div>
        </div>
      ))}
    </div>
  );
};

export default PricingThree;
