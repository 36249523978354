import React, { useState } from "react";
import ModalVideo from "react-modal-video";

const VideoGalleryContent = [
  {
    img: "ol-video-1",
    text1: "ONLINE@EDUZ",
    text2: "Lesson Demo",
    animationDelay: "",
    videoId: "tK5BMs3KrRM"
  },
  {
    img: "ol-video-2",
    text1: "Integration (Area of rectangles)",
    text2: "JC H2 Maths",
    animationDelay: "50",
    videoId: "JWgatYilkIM"
  },
  {
    img: "ol-video-3",
    text1: "Coordinates of turning points.",
    text2: "Upper Sec E. Math",
    animationDelay: "150",
    videoId: "zwD8xzlpK1Q"
  },
  {
    img: "ol-video-4",
    text1: "Direct & inverse proportion (Advanced)",
    text2: "Sec 2 Math",
    animationDelay: "100",
    videoId: "2cp4Fo3cwF4"
  },
  {
    img: "ol-video-5",
    text1: "Tips for geometrical properties.",
    text2: "Upper Sec E. Math",
    animationDelay: "50",
    videoId: "FLObikjU_AA"
  },
  {
    img: "ol-video-6",
    text1: "Integration (Product of 2 trigo terms)",
    text2: "JC2 H2 Maths",
    animationDelay: "100",
    videoId: "MyiRhnZAwnE"
  },
];

const VideoGallery = () => {
  const [isOpen, setOpen] = useState(false);
  const [currentVideoId, setCurrentVideoId] = useState(false);
  
  return (
    <div className="row justify-content-center">
      <ModalVideo
        channel="youtube"
        autoplay
        isOpen={isOpen}
        videoId={currentVideoId}
        onClose={() => setOpen(false)}
      />
      {VideoGalleryContent.map((val, i) => (
        <div
          className="col-xl-4 col-lg-4 col-sm-6"
          data-aos="fade-up"
          data-aos-duration="1200"
          data-aos-delay={val.animationDelay}
          key={i}
        > 
          <div className="block-style-twentyOne">
            <img src={`images/site/programmes/${val.img}.webp`} alt="" className="w-100" />
            <div className="d-flex align-items-center justify-content-center flex-column video-button">
              <span>
                {val.text1} <br /> {val.text2}
              </span>
              <span
                className="icon d-flex align-items-center justify-content-center"
                onClick={() => { setOpen(true); setCurrentVideoId(val.videoId); }}
              >
                <img src="images/icon/118.svg" alt="" />
              </span>
            </div>
          </div>
          {/* /.block-style-twentyOne */}
        </div>
      ))}
    </div>
  );
};

export default VideoGallery;
