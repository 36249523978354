import { React, useEffect } from "react";
import { useParams } from "react-router-dom";
import AnalyticsEvent from "../shared/AnalyticsEvent";

var filename, eventAction, eventCategory, eventSource, eventLabel, description = ""

const FileDownload = (props) => {
  const params = useParams();  
  console.log(params)

  if (props.type === "CurriculumDownload")
  {
    filename = "/docs/curriculum-package/eduztuition-"+params.level+"-curriculum-package.pdf"
    eventCategory = props.type
    eventAction = "Download"
    eventSource = "/curriculum-package/"+params.level
    eventLabel = params.level
    description = "Downloading "+params.level+" "+"Curriculum Package..."
  }

  if (props.type === "NewsletterDownload")
  {
    filename = "/docs/newsletter/EduZTuition-"+params.quarter+"-Newsletter.pdf"
    eventCategory = props.type
    eventAction = "Download"    
    eventSource = "/newsletter/"+props.medium+"/"+params.quarter
    eventLabel = params.quarter
    description = "Downloading "+params.quarter+" Newsletter..."
  }
  

  AnalyticsEvent({
    category: eventCategory,
    action: eventAction,
    source: eventSource,
    label: eventLabel
  })

  useEffect(() => {
    window.location.href = filename;
  }, []);

  return <p>{description}</p>;
};

export default FileDownload;
  