import React from "react";
import VerticalTimeline from "../../../components/programmes/subject/VerticalTimeline";
import VerticalTimelineElement from "../../../components/programmes/subject/VerticalTimelineElement"

const SubjectLevelContent =
[
  {
    "id": "english",
    "subject": "English",
    "levels":[
      {
        "level": "Primary 1",
        "levelShort": "P1",
        "levelIcon": "/images/icon/90.svg",
        "content": [{
          "icon": "20",
          "meta": "Content & Language Mastery",
          "subTitle": `Our P1 English focuses on understanding nouns, pronouns, adjectives, the proper use of tenses and punctuations.`,
          "dataDelay": "0",
        },
        {
          "icon": "21",
          "meta": "Skills and Techniques",
          "subTitle": `Students will be introduced to comprehensions (short stories), creative writing and oratory (reading aloud).`,
          "dataDelay": "100",
        }],
      },
      {
        "level": "Primary 2",
        "levelShort": "P2",
        "levelIcon": "/images/icon/90.svg",
        "content": [{
          "icon": "20",
          "meta": "Content & Language Mastery",
          "subTitle": `Our P2 English focuses on the proper use of tenses for various scenarios and the introduction of synonyms and idioms to expand their vocabulary.`,
          "dataDelay": "0",
        },
        {
          "icon": "21",
          "meta": "Skills and Techniques",
          "subTitle": `Students learn to analyse the meaning in short stories, poems and letters while practicing sentence writing for composition. Additional practice to speak English during class will continue to hone their verbal skills.`,
          "dataDelay": "100",
        }],
      },
      {
        "level": "Primary 3",
        "levelShort": "P3",
        "levelIcon": "/images/icon/90.svg",
        "content": [{
          "icon": "20",
          "meta": "Content & Language Mastery",
          "subTitle": `Our P3 English focuses on synthesis and transformation of sentences while understanding tones and emotions in texts. They are highly encouraged to develop their repertoire of vocabulary through analysing and reading of books.`,
          "dataDelay": "0",
        },
        {
          "icon": "21",
          "meta": "Skills and Techniques",
          "subTitle": `Students learn to read and analyse article-based comprehension passages while applying descriptive vocabulary in compositions.`,
          "dataDelay": "100",
        }],
      },
      {
        "level": "Primary 4",
        "levelShort": "P4",
        "levelIcon": "/images/icon/90.svg",
        "content": [{
          "icon": "20",
          "meta": "Content & Language Mastery",
          "subTitle": `Our P4 English touches on phasal verbs, connectors, clauses and common expression. Active discussion are held during lesson time to encourage students to express their personal thoughts and opinions.`,
          "dataDelay": "0",
        },
        {
          "icon": "21",
          "meta": "Skills and Techniques",
          "subTitle": `Students are taught keyword analysis and inferential skills to tackle comprehension questions. Students are also encouraged to come up with creative stories to capture readers’ attention in composition. Continued refinement in their pronunciation, enunciation, intonation help students gain confidence in their oral skills.`,
          "dataDelay": "100",
        }],
      },
      {
        "level": "Primary 5",
        "levelShort": "P5",
        "levelIcon": "/images/icon/90.svg",
        "content": [{
          "icon": "20",
          "meta": "Content & Language Mastery",
          "subTitle": `P5 English introduces direct and indirect speech to students. The concept of persuasion in passages and conversations are also taught.`,
          "dataDelay": "0",
        },
        {
          "icon": "21",
          "meta": "Skills and Techniques",
          "subTitle": `Students are introduced to situational writing and are equipped with writing techniques like flashback, foreshadowing and twist and hook to enhance their composition skills. Mock interview preparation for students keen on Direct School Admission or other general interview settings are also provided.`,
          "dataDelay": "100",
        }],
      },
      {
        "level": "Primary 6",
        "levelShort": "P6",
        "levelIcon": "/images/icon/90.svg",
        "content": [{
          "icon": "20",
          "meta": "Content & Language Mastery",
          "subTitle": `P6 English covers the remaining PSLE topics like passive and active voice, spelling and editing and reported speeches.`,
          "dataDelay": "0",
        },
        {
          "icon": "21",
          "meta": "Skills and Techniques",
          "subTitle": `Students are tasked with practice questions and personalized feedback are provided to address each student’s individual weakness.`,
          "dataDelay": "100",
        },
        {
          "icon": "22",
          "meta": "Revision and Reinforcement",
          "subTitle": `Revision will be focused mastering the individual paper requirements and honing analytical and time management skills through practice tests in simulated exam conditions.`,
          "dataDelay": "200",
        }],
      },
      {
        "level": "Secondary 1",
        "levelShort": "S1",
        "levelIcon": "/images/icon/90.svg",
        "content": [{
          "icon": "20",
          "meta": "Content & Language Mastery",
          "subTitle": `Students are introduced summary writing, a new format of editing which requires students to identify and correct the errors. Written passages now covers a wider range of general knowledge topics to help students develop a global perspective and build content for use in their essay.`,
          "dataDelay": "0",
        },
        {
          "icon": "21",
          "meta": "Skills and Techniques",
          "subTitle": `Students are taught of essay writing techniques to incorporate personal thoughts and opinions. Students also learn summarizing skills which allow them to locate keywords within a text and paraphrase in a succinct manner.`,
          "dataDelay": "100",
        }],
      },
      {
        "level": "Secondary 2",
        "levelShort": "S2",
        "levelIcon": "/images/icon/90.svg",
        "content": [{
          "icon": "20",
          "meta": "Content & Language Mastery",
          "subTitle": `Written passages and in-class discussion continues to cover a wide range of current affairs topics to help students develop a global perspective and build content for use in their essay. Students attempt papers of diverse topics to apply discussed ideas. Grammatical exercises are also of priority to build a strong foundation and avoid loss of marks.`,
          "dataDelay": "0",
        },
        {
          "icon": "21",
          "meta": "Skills and Techniques",
          "subTitle": `Students’ confidence and writing skills are honed with practices to the various essay types. Students are also trained in their time management skills through practice tests in simulated exam conditions.`,
          "dataDelay": "100",
        }],
      },
      {
        "level": "Secondary 3",
        "levelShort": "S3",
        "levelIcon": "/images/icon/90.svg",
        "content": [{
          "icon": "20",
          "meta": "Content & Language Mastery",
          "subTitle": `Written passages and in-class discussion continues to cover a wide range of current affairs topics to help students develop a global perspective and build content for use in their essay. Students continue to attempt papers of diverse topics to apply discussed ideas. `,
          "dataDelay": "0",
        },
        {
          "icon": "21",
          "meta": "Skills and Techniques",
          "subTitle": `Students are taught to master their reasoning skills for writing mature text, while also summarizing ideas and concepts appropriately in a coherent and logical manner. Examination skills such as time management and concise answering techniques will be honed.`,
          "dataDelay": "100",
        }
      ],
      },
      {
        "level": "Secondary 4",
        "levelShort": "S4",
        "levelIcon": "/images/icon/90.svg",
        "content": [{
          "icon": "20",
          "meta": "Content & Language Mastery",
          "subTitle": `Even more complex written passages and more recent current affairs in-class discussion continues to make the bulk of lesson time to help students prepare for their final exams. Students continue to attempt papers of diverse topics to apply discussed ideas. `,
          "dataDelay": "0",
        },
        {
          "icon": "21",
          "meta": "Skills and Techniques",
          "subTitle": `Students are taught to master their critical evaluation, summarizing and comprehension skills on a wide range of topics, while also organising and supporting arguments in a coherent and logical manner to substantiate arguments.`,
          "dataDelay": "100",
        },
        {
          "icon": "22",
          "meta": "Revision and Reinforcement",
          "subTitle": `Revision will be focused on mastering the individual 'N' or ‘O’ Level paper requirements and honing time management skills through practice tests in simulated exam conditions. Discussion on key topics will be held to build students’ repository of general knowledge and current affairs.`,
          "dataDelay": "200",
        },
      ],
      },
    ]
  },
  {
    "id": "gp",
    "subject": "General Paper",
    "levels": [
      {
        "level": "Junior College 1",
        "levelShort": "JC1",
        "levelIcon": "/images/icon/90.svg",
        "content": [{
          "icon": "20",
          "meta": "Content & Language Mastery",
          "subTitle": `In our JC1 GP, we focus on understanding the individual requirements for the GP paper, mainly argumentative writing, comprehension, summary and application question.`,
          "dataDelay": "0",
        },
        {
          "icon": "21",
          "meta": "Skills and Techniques",
          "subTitle": `In our JC1 GP, we focus on honing students’ critical reasoning and writing skills to express ideas clearly. Student’s inference and summarizing skills will be honed, and they will also be trained to apply knowledge and understanding of issues in addressing specific task(s).`,
          "dataDelay": "100",
        }
      ],
      },
      {
        "level": "Junior College 2",
        "levelShort": "JC2",
        "levelIcon": "/images/icon/90.svg",
        "content": [{
          "icon": "20",
          "meta": "Content & Language Mastery",
          "subTitle": `In the second year, emphasis will be on strengthening students’ understanding of current affairs and trends as well as gain ample practice with 'A' level question types. Key components of the paper covered includes composition, comprehension, summary and application questions.`,
          "dataDelay": "0",
        },
        {
          "icon": "21",
          "meta": "Skills and Techniques",
          "subTitle": `In the second year, students are taught to master their critical evaluation and reasoning skills for written text, while also organizing and supporting arguments in a coherent and logical manner to substantiate arguments.`,
          "dataDelay": "100",
        },
        {
          "icon": "22",
          "meta": "Revision and Reinforcement",
          "subTitle": `In the second year, revision will be focused on mastering the individual H1 GP paper requirements and honing time management skills through practice tests in simulated exam conditions. Discussion on key topics will be held to build students’ repository of general knowledge.`,
          "dataDelay": "200",
        }
      ],
      },
    ]
  },
  {
    "id": "chinese",
    "subject": "Chinese",
    "levels":[
      {
        "level": "Primary 1",
        "levelShort": "P1",
        "levelIcon": "/images/icon/90.svg",
        "content": [{
          "icon": "20",
          "meta": "Content & Language Mastery",
          "subTitle": `P1 Chinese focuses on building a foundation in Hanyu Pinyin, practice writing more Chinese characters and recognizing and differentiating the various Chinese pronunciation.`,
          "dataDelay": "0",
        },
        {
          "icon": "21",
          "meta": "Skills and Techniques",
          "subTitle": `Students have the opportunity to speak Chinese with on-the-spot guidance by tutors.`,
          "dataDelay": "100",
        }],
      },
      {
        "level": "Primary 2",
        "levelShort": "P2",
        "levelIcon": "/images/icon/90.svg",
        "content": [{
          "icon": "20",
          "meta": "Content & Language Mastery",
          "subTitle": `P2 Chinese exposure to composition and reading comprehension which expand their Chinese word bank.`,
          "dataDelay": "0",
        },
        {
          "icon": "21",
          "meta": "Skills and Techniques",
          "subTitle": `Basic composition writing skills, oral test components are taught. Furthermore, students will have additional practice to read and write Chinese text as they learn identify, learn and apply sentence structures.`,
          "dataDelay": "100",
        }],
      },
      {
        "level": "Primary 3",
        "levelShort": "P3",
        "levelIcon": "/images/icon/90.svg",
        "content": [{
          "icon": "20",
          "meta": "Content & Language Mastery",
          "subTitle": `Our P3 English focuses on synthesis and transformation of sentences while understanding tones and emotions in texts. They are highly encouraged to develop their repertoire of vocabulary through analysing and reading of books.`,
          "dataDelay": "0",
        },
        {
          "icon": "21",
          "meta": "Skills and Techniques",
          "subTitle": `Students learn to read and analyse article-based comprehension passages while applying descriptive vocabulary in compositions.`,
          "dataDelay": "100",
        }],
      },
      {
        "level": "Primary 4",
        "levelShort": "P4",
        "levelIcon": "/images/icon/90.svg",
        "content": [{
          "icon": "20",
          "meta": "Content & Language Mastery",
          "subTitle": `Fluency in reading and speaking is expected while students prepare for oral exams. Chinese idioms are also introduced to enhance their vocabulary bank.`,
          "dataDelay": "0",
        },
        {
          "icon": "21",
          "meta": "Skills and Techniques",
          "subTitle": `Building onto their foundation, description of actions and emotions are expected in their written composition. Students are also exposed to a wider range of question type in their reading comprehension.`,
          "dataDelay": "100",
        }],
      },
      {
        "level": "Primary 5",
        "levelShort": "P5",
        "levelIcon": "/images/icon/90.svg",
        "content": [{
          "icon": "20",
          "meta": "Content & Language Mastery",
          "subTitle": `Chinese proverbs are introduced to enhance students’ vocabulary. The skill to identify when and how to use more advanced phrases is also crucial to make their oral and writing skills stand out.`,
          "dataDelay": "0",
        },
        {
          "icon": "21",
          "meta": "Skills and Techniques",
          "subTitle": `The main focus is on honing exam techniques for each examinable components, which includes sharpening students’ vocabulary for their description writing, encouraging appropriate reasoning and expression for their oral examinations and brushing up inference skills for their comprehension paper.`,
          "dataDelay": "100",
        }],
      },
      {
        "level": "Primary 6",
        "levelShort": "P6",
        "levelIcon": "/images/icon/90.svg",
        "content": [{
          "icon": "20",
          "meta": "Content & Language Mastery",
          "subTitle": `Advanced phrases, proverbs and idioms are introduced for more advanced students while more (social) topics are touched upon for a more well-rounded Chinese education.`,
          "dataDelay": "0",
        },
        {
          "icon": "21",
          "meta": "Skills and Techniques",
          "subTitle": `Students are tasked with practice questions and personalized feedback are provided to address each student’s individual weakness. Cheat sheets with important and useful phases, proverbs and idioms are provided to improve composition and oral skills.`,
          "dataDelay": "100",
        },
        {
          "icon": "22",
          "meta": "Revision and Reinforcement",
          "subTitle": `In P6 Chinese, revision will be focused on mastering the individual Chinese paper requirements and honing time management skills. It is crucial to continue students’ exposure and usage of Chinese characters to build familiarization.`,
          "dataDelay": "200",
        }],
      },
      {
        "level": "Secondary 1",
        "levelShort": "S1",
        "levelIcon": "/images/icon/90.svg",
        "content": [{
          "icon": "20",
          "meta": "Content & Language Mastery",
          "subTitle": `Students will be taught the format of writing an email as required in their paper 1. Students are also encouraged to do more reading of Chinese passages to build their Chinese word bank.`,
          "dataDelay": "0",
        },
        {
          "icon": "21",
          "meta": "Skills and Techniques",
          "subTitle": `The email writing skills taught allows students to build their bilingual skills as they explore more overlaps between Chinese and English.`,
          "dataDelay": "100",
        }],
      },
      {
        "level": "Secondary 2",
        "levelShort": "S2",
        "levelIcon": "/images/icon/90.svg",
        "content": [{
          "icon": "20",
          "meta": "Content & Language Mastery",
          "subTitle": `Continued exposure to Chinese characters in textbooks, passages and practices allows students to recognize, read and apply. Students are expected to be capable of reading text and passages smoothly.`,
          "dataDelay": "0",
        },
        {
          "icon": "21",
          "meta": "Skills and Techniques",
          "subTitle": `Students’ oral and listening skills will be tested as students prepare for their Secondary 2 exams while continued practices in the various composition and comprehension question types will build concise answering skills.`,
          "dataDelay": "100",
        }],
      },
      {
        "level": "Secondary 3",
        "levelShort": "S3",
        "levelIcon": "/images/icon/90.svg",
        "content": [{
          "icon": "20",
          "meta": "Content & Language Mastery",
          "subTitle": `In-class discussion are held to expose students to a wide range of current affairs topics to help students develop a global perspective, build their Chinese vocabulary and build content for use in their essay.`,
          "dataDelay": "0",
        },
        {
          "icon": "21",
          "meta": "Skills and Techniques",
          "subTitle": `Numerous practice questions are given to build students’ familiarity in the answering techniques for the different question types in their composition and comprehension papers.`,
          "dataDelay": "100",
        }
      ],
      },
      {
        "level": "Secondary 4",
        "levelShort": "S4",
        "levelIcon": "/images/icon/90.svg",
        "content": [{
          "icon": "20",
          "meta": "Content & Language Mastery",
          "subTitle": `Even more complex written passages and more recent current affairs in-class discussion continues to make the bulk of lesson time to help students prepare for their final exams. Students continue to attempt papers of diverse topics to apply discussed ideas. `,
          "dataDelay": "0",
        },
        {
          "icon": "21",
          "meta": "Skills and Techniques",
          "subTitle": `Students are taught to master their critical evaluation, summarizing and comprehension skills on a wide range of topics, while also organising and supporting arguments in a coherent and logical manner to substantiate arguments.`,
          "dataDelay": "100",
        },
        {
          "icon": "22",
          "meta": "Revision and Reinforcement",
          "subTitle": `Revision will be focused on mastering the individual ‘O’ Level paper requirements and honing time management skills through practice tests in simulated exam conditions. Discussion on key topics will be held to build students’ repository of general knowledge and current affairs.`,
          "dataDelay": "200",
        },
      ],
      },
    ]
  },
  {
    "id": "math",
    "subject": "Math",
    "levels":[
      {
        "level": "Primary 1",
        "levelShort": "P1",
        "levelIcon": "/images/icon/90.svg",
        "content": [{
          "icon": "20",
          "meta": "Content & Language Mastery",
          "subTitle": `Our P1 Math covers numbers, simple mathematical equations, measurements, geometry and comprehending pictorial graphs.`,
          "dataDelay": "0",
        },
        {
          "icon": "21",
          "meta": "Skills and Techniques",
          "subTitle": `Application-based questions about length, time and money will help students gain awareness of the importance of math in everyday life.`,
          "dataDelay": "100",
        }],
      },
      {
        "level": "Primary 2",
        "levelShort": "P2",
        "levelIcon": "/images/icon/90.svg",
        "content": [{
          "icon": "20",
          "meta": "Content & Language Mastery",
          "subTitle": `Our P2 Math explores more advanced concept in numbers and mathematical equations, while using numbers up to 1000. Topics involving mass, volume and fractions will also be introduced to prepare students for P3.`,
          "dataDelay": "0",
        },
        {
          "icon": "21",
          "meta": "Skills and Techniques",
          "subTitle": `Crucial presentation skills are taught to students to instil a good writing habit from young. Rectification of any misconceptions is also important in P2 to ensure students have an accurate understanding of mathematics.`,
          "dataDelay": "100",
        }], 
      },
      {
        "level": "Primary 3",
        "levelShort": "P3",
        "levelIcon": "/images/icon/90.svg",
        "content": [{
          "icon": "20",
          "meta": "Content & Language Mastery",
          "subTitle": `Our P3 Math explores numbers and mathematical equations, while using numbers up to 10 000. Additional topics in area, perimeters, angles and perpendicular and parallel lines are introduced. Statistical components like analysing scales and bar graphs are also taught to prepare students for P4.`,
          "dataDelay": "0",
        },
        {
          "icon": "21",
          "meta": "Skills and Techniques",
          "subTitle": `Students in P3 will learn skills more specific to answering examinations questions accurately, using the foundation set at primary 1 and 2.`,
          "dataDelay": "100",
        }],
      },
      {
        "level": "Primary 4",
        "levelShort": "P4",
        "levelIcon": "/images/icon/90.svg",
        "content": [{
          "icon": "20",
          "meta": "Content & Language Mastery",
          "subTitle": `Our P4 Math explores more mathematical symbols and numbers, while using numbers up to 100 000. Additional topics in factors, multiples, decimals and symmetry are introduced. Students are also taught to create and analyse tables and line graphs.`,
          "dataDelay": "0",
        },
        {
          "icon": "21",
          "meta": "Skills and Techniques",
          "subTitle": `Students in P4 continue to work on answering techniques while practicing application-based mathematics concepts and questions.`,
          "dataDelay": "100",
        }],
      },
      {
        "level": "Primary 5",
        "levelShort": "P5",
        "levelIcon": "/images/icon/90.svg",
        "content": [{
          "icon": "20",
          "meta": "Content & Language Mastery",
          "subTitle": `P5 English introduces direct and indirect speech to students. The concept of persuasion in passages and conversations are also taught.`,
          "dataDelay": "0",
        },
        {
          "icon": "21",
          "meta": "Skills and Techniques",
          "subTitle": `Students are introduced to situational writing and are equipped with writing techniques like flashback, foreshadowing and twist and hook to enhance their composition skills. Mock interview preparation for students keen on Direct School Admission or other general interview settings are also provided.`,
          "dataDelay": "100",
        }],
      },
      {
        "level": "Primary 6",
        "levelShort": "P6",
        "levelIcon": "/images/icon/90.svg",
        "content": [{
          "icon": "20",
          "meta": "Content & Language Mastery",
          "subTitle": `Our P6 Math covers the remaining PSLE topics in algebra, nets and pie charts.`,
          "dataDelay": "0",
        },
        {
          "icon": "21",
          "meta": "Skills and Techniques",
          "subTitle": `Problem sums that involve real-world situations and combines the knowledge of multiple concepts are a top priority to prepare students for the challenging final exam.`,
          "dataDelay": "100",
        },
        {
          "icon": "22",
          "meta": "Revision and Reinforcement",
          "subTitle": `Revision will be focused on drilling concepts and clearing any erroneous understanding of mathematics through practicing past year examination questions.`,
          "dataDelay": "200",
        }],
      },
      {
        "level": "Secondary 1",
        "levelShort": "S1",
        "levelIcon": "/images/icon/90.svg",
        "content": [{
          "icon": "20",
          "meta": "Content & Language Mastery",
          "subTitle": `Students are introduced summary writing, a new format of editing which requires students to identify and correct the errors. Written passages now covers a wider range of general knowledge topics to help students develop a global perspective and build content for use in their essay.`,
          "dataDelay": "0",
        },
        {
          "icon": "21",
          "meta": "Skills and Techniques",
          "subTitle": `Students are taught of essay writing techniques to incorporate personal thoughts and opinions. Students also learn summarizing skills which allow them to locate keywords within a text and paraphrase in a succinct manner.`,
          "dataDelay": "100",
        }
      ],
      },
      {
        "level": "Secondary 2",
        "levelShort": "S2",
        "levelIcon": "/images/icon/90.svg",
        "content": [{
          "icon": "20",
          "meta": "Content & Language Mastery",
          "subTitle": `S2 Math revisits and introduces more complex concepts in topics like ratio, algebra, functions, graphs and inequalities, data analysis. New topics this year includes understanding congruency, similarity, the Pythagoras theorem, introduction to trigonometry and also probability. `,
          "dataDelay": "0",
        },
        {
          "icon": "21",
          "meta": "Skills and Techniques",
          "subTitle": `Students are taught to identify question requirement and the appropriate method to use. Questions tends to be more application-based. Students are expected to build a habit to check their own answers to minimize careless errors and loss of marks. Revision will be focused on mastering all the lower secondary math topics to prepare students for their final exams.`,
          "dataDelay": "100",
        }
        ],
      }
    ]
  },
  {
    "id": "e-math",
    "subject": "E. Math",
    "levels":[
      {
        "level": "Secondary 3",
        "levelShort": "S3",
        "levelIcon": "/images/icon/90.svg",
        "content": [{
            "icon": "20",
            "meta": "Content & Language Mastery",
            "subTitle": `S3 E. Math revises and expand students’ knowledge on topics from the lower secondary math. New concepts include indices, coordinate geometry, set notation, matrices, properties of circles and further trigonometry and its application.`,
            "dataDelay": "0",
          },
          {
            "icon": "21",
            "meta": "Skills and Techniques",
            "subTitle": `Students are taught to integrate existing knowledge with upper secondary topics while honing their presentation skills. Building of good habits like double checking their own answers is also crucial to minimize the loss of marks. `,
            "dataDelay": "100",
          }],
      },
      {
        "level": "Secondary 4",
        "levelShort": "S4",
        "levelIcon": "/images/icon/90.svg",
        "content": [{
          "icon": "20",
          "meta": "Content & Language Mastery",
          "subTitle": `S4 E.Math continues to introduce new concepts like vectors, radian measurement and additional statistical plots. Familiarization of the equations in the mathematical formulae is also crucial to become exam ready.`,
          "dataDelay": "0",
        },
        {
          "icon": "21",
          "meta": "Skills and Techniques",
          "subTitle": `Identification of topics and question requirements is prioritized, along with practicing ample examination questions for exposure. `,
          "dataDelay": "100",
        },
        {
          "icon": "22",
          "meta": "Revision and Reinforcement",
          "subTitle": `Intensive revision will be focused on mastering all the E Math concepts through topical revision packages. Examination skills such as time management and error analysis will be honed through practice tests.`,
          "dataDelay": "200",
        }],
      },
    ]
  },
  {
    "id": "a-math",
    "subject": "A. Math",
    "levels":[
      {
        "level": "Secondary 3",
        "levelShort": "S3",
        "levelIcon": "/images/icon/90.svg",
        "content": [{
          "icon": "20",
          "meta": "Content & Language Mastery",
          "subTitle": `S3 A Math focuses largely on advanced trigonometry with concepts on addition formula, double-angle formula, and R formula and drawing of the sine and cosine graphs. Non-trigonometry concepts are still covered, and these includes inequalities, indices and surds, polynomials and partial fractions and binomial expansions.`,
          "dataDelay": "0",
        },
        {
          "icon": "21",
          "meta": "Skills and Techniques",
          "subTitle": `Manipulation of symbols and functions is a crucial skill in A Math. Students are also frequently expected to prove expressions using identities and formulae and communicate inferences and conclusions to problem sums.`,
          "dataDelay": "100",
        }],
      },
      {
        "level": "Secondary 4",
        "levelShort": "S4",
        "levelIcon": "/images/icon/90.svg",
        "content": [{
          "icon": "20",
          "meta": "Content & Language Mastery",
          "subTitle": `S4 A Math continues to introduce more concepts, namely calculus, geometric theorems, and application-based kinematics.`,
          "dataDelay": "0",
        },
        {
          "icon": "21",
          "meta": "Skills and Techniques",
          "subTitle": `Problem sums that involving real-world situations and combines the knowledge of multiple concepts are a top priority to prepare students for the challenging final exam.`,
          "dataDelay": "100",
        },
        {
          "icon": "22",
          "meta": "Revision and Reinforcement",
          "subTitle": `Intensive revision will be focused on mastering all the A Math concepts through topical revision packages. Examination skills such as time management and error analysis will be honed through practice tests.`,
          "dataDelay": "200",
        }],
      },
    ]
  },
  {
    "id": "h12-math",
    "subject": "H1/2 Math",
    "levels":[
      {
        "level": "Junior College 1",
        "levelShort": "JC1",
        "levelIcon": "/images/icon/90.svg",
        "content": [{
          "icon": "20",
          "meta": "Content & Language Mastery",
          "subTitle": `In our JC1 H1 Math, pure math topics such as Calculus, equations and inequalities and exponential, logarithmic function are covered. Meanwhile JC1 H2 math students learn the more advanced pure math topics such as functions, graphs, Sequences and Series, vectors, complex numbers, and advanced calculus.`,
          "dataDelay": "0",
        },
        {
          "icon": "21",
          "meta": "Skills and Techniques",
          "subTitle": `In our JC1 math, students are taught to master the use of their Graphic Calculator (GC) to solve and check for answers. They are also taught to identify the topic for each question and integrate various concepts to present answers.`,
          "dataDelay": "100",
        }],
      },
      {
        "level": "Junior College 2",
        "levelShort": "JC2",
        "levelIcon": "/images/icon/90.svg",
        "content": [{
          "icon": "20",
          "meta": "Content & Language Mastery",
          "subTitle": `In the second year, our JC math focuses on statical mathematics. In H1, topics such as computation of probability, binomial and normal distributions, sampling, and hypothesis testing as well as correlation and regression are covered. JC2 H2 math is highly similar with an additional topic in general and specific discrete distribution models.`,
          "dataDelay": "0",
        },
        {
          "icon": "21",
          "meta": "Skills and Techniques",
          "subTitle": `In the second year, we prioritise tutorials questions involving real-world situations that combines the knowledge of multiple concepts. We also practice using the GC for answering of statistics-related questions and learn to identify question trends and marking criteria through commonly tested exam questions.`,
          "dataDelay": "100",
        },
        {
          "icon": "22",
          "meta": "Revision and Reinforcement",
          "subTitle": `In the second year, revision will be focused on mastering all the H1/2 Math topics through topical revision packages. Examination skills such as time management and error analysis will be honed through practice tests.`,
          "dataDelay": "200",
        }],
      }
    ]
  },
  {
    "id": "science",
    "subject": "Science",
    "levels":[
      {
        "level": "Primary 3",
        "levelShort": "P3",
        "levelIcon": "/images/icon/90.svg",
        "content": [{
          "icon": "20",
          "meta": "Content & Language Mastery",
          "subTitle": `P3 Science covers the basic of PSLE science themes in Diversity, Cycles, Systems, Interactions and Energy. Science experiments are introduced to arouse students’ interest and aid understanding.`,
          "dataDelay": "0",
        },
        {
          "icon": "21",
          "meta": "Skills and Techniques",
          "subTitle": `Students are introduced to the way science questions are asked and practice question analysis using the scientific method.`,
          "dataDelay": "100",
        }],
      },
      {
        "level": "Primary 4",
        "levelShort": "P4",
        "levelIcon": "/images/icon/90.svg",
        "content": [{
          "icon": "20",
          "meta": "Content & Language Mastery",
          "subTitle": `P4 Science touches on more chapters under the 6 PSLE science themes, while revising the topics covered in P3 at greater depth.`,
          "dataDelay": "0",
        },
        {
          "icon": "21",
          "meta": "Skills and Techniques",
          "subTitle": `More skill-based scientific skills like drawing relationship between variables, analysing information in charts and graphs, and interpreting the aims, variables and conclusion of experiment set-up are the focus in this level.`,
          "dataDelay": "100",
        }],
      },
      {
        "level": "Primary 5",
        "levelShort": "P5",
        "levelIcon": "/images/icon/90.svg",
        "content": [{
          "icon": "20",
          "meta": "Content & Language Mastery",
          "subTitle": `P5 Science touches on more in-depth chapters under the 6 PSLE science themes. Most of the content requires understanding of processes and the combination of knowledge from multiple chapters are necessary to answer practice questions.`,
          "dataDelay": "0",
        },
        {
          "icon": "21",
          "meta": "Skills and Techniques",
          "subTitle": `Students are taught on how to identify question requirement and its respective answering structure. Students are also drilled on the use of specific scientific keyword in answering questions.`,
          "dataDelay": "100",
        }],
      },
      {
        "level": "Primary 6",
        "levelShort": "P6",
        "levelIcon": "/images/icon/90.svg",
        "content": [{
          "icon": "20",
          "meta": "Content & Language Mastery",
          "subTitle": `P6 Science touches on the remaining chapters under the 6 PSLE science themes. Topical revision of common and tricky PSLE Science questions will be held in class.`,
          "dataDelay": "0",
        },
        {
          "icon": "21",
          "meta": "Skills and Techniques",
          "subTitle": `Practice questions that involve real-world situations and combines the knowledge of multiple concepts are a top priority to prepare students for the challenging final exam.`,
          "dataDelay": "100",
        },
        {
          "icon": "22",
          "meta": "Revision and Reinforcement",
          "subTitle": `Revision will be focused on drilling concepts and clearing any erroneous understanding of mathematics through practicing past year examination questions.`,
          "dataDelay": "200",
        }],
      },
      {
        "level": "Secondary 1",
        "levelShort": "S1",
        "levelIcon": "/images/icon/90.svg",
        "content": [{
          "icon": "20",
          "meta": "Content & Language Mastery",
          "subTitle": `S1 Science introduces students to Laboratory Safety and Apparatus as well as cover scientific concepts from all 3 sciences ranging from Cells and Movement of Substances to Elements and Compounds to Physical Quantities, and Mass, Weight and Density.`,
          "dataDelay": "0",
        },
        {
          "icon": "21",
          "meta": "Skills and Techniques",
          "subTitle": `Students will gain question analysis skills to interpret information and answer the question appropriately. Students will also learn more about science experiments, how they are conducted and how to write and record experiment procedures.`,
          "dataDelay": "100",
        }],
      },
      {
        "level": "Secondary 2",
        "levelShort": "S2",
        "levelIcon": "/images/icon/90.svg",
        "content": [{
          "icon": "20",
          "meta": "Content & Language Mastery",
          "subTitle": `S2 Science touches on more in-depth scientific concepts like Human System, Acids and Bases and Electricity.`,
          "dataDelay": "0",
        },
        {
          "icon": "21",
          "meta": "Skills and Techniques",
          "subTitle": `Students will learn how to analyse graphical data and utilize that information to answer the question while also attempt questions that requires higher order thinking skills. Students are also trained in their time management skills through practice tests in simulated exam conditions.`,
          "dataDelay": "100",
        },]
      },
    ]
  },
  {
    "id": "comb",
    "subject": "Combined Physics/Chemistry",
    "levels":[
      {
        "level": "Secondary 3",
        "levelShort": "S3",
        "levelIcon": "/images/icon/90.svg",
        "content": [{
          "icon": "20",
          "meta": "Content & Language Mastery",
          "subTitle": `S3 Combined P/C touches on chemistry topics like Atomic Structure and Stoichiometry while managing physics topics like Kinematics, Thermal Physics, and Dynamics.`,
          "dataDelay": "0",
        },
        {
          "icon": "21",
          "meta": "Skills and Techniques",
          "subTitle": `Students will be exposed to reasoning and conceptual application skills with dedicated focus on concept application and examination skills that are necessary for the combined science level.`,
          "dataDelay": "100",
        }
      ],
      },
      {
        "level": "Secondary 4",
        "levelShort": "S4",
        "levelIcon": "/images/icon/90.svg",
        "content": [{
          "icon": "20",
          "meta": "Content & Language Mastery",
          "subTitle": `S4 Combined P/C continues to build student knowledge with chemistry topics like redox and Organic Chemistry while also touching on physics topics like Electricity and Magnetism.`,
          "dataDelay": "0",
        },
        {
          "icon": "21",
          "meta": "Skills and Techniques",
          "subTitle": `Emphasis will be on ensuring student fully grasp the basic concepts in each chapter and thus, practice questions focus on answering common question types in each and every chapter.`,
          "dataDelay": "100",
        },
        {
          "icon": "22",
          "meta": "Revision and Reinforcement",
          "subTitle": `Intensive revision will be focused on mastering all the Combined P/C concepts through topical revision packages. Examination skills such as time management and error analysis will be honed through practice tests.`,
          "dataDelay": "200",
        },
      ],
      },
    ]
  },
  {
    "id": "phy",
    "subject": "Pure Physics",
    "levels":[
      {
        "level": "Secondary 3",
        "levelShort": "S3",
        "levelIcon": "/images/icon/90.svg",
        "content": [{
          "icon": "20",
          "meta": "Content & Language Mastery",
          "subTitle": `S3 Pure Physics explores topics such as Kinematics, Pressure, Thermal Properties of Matter, General Wave Properties and Electromagnetic Spectrum in depth.`,
          "dataDelay": "0",
        },
        {
          "icon": "21",
          "meta": "Skills and Techniques",
          "subTitle": `Students are taught to develop an understanding of the role of Physics in scientific issues and relevant applications of Physics, which is beneficial to prepare them for their practical and theory papers.`,
          "dataDelay": "100",
        }
      ],
      },
      {
        "level": "Secondary 4",
        "levelShort": "S4",
        "levelIcon": "/images/icon/90.svg",
        "content": [{
          "icon": "20",
          "meta": "Content & Language Mastery",
          "subTitle": `S4 Pure Physics touches on new topics such as Static Electricity, Magnetism, Electromagnetism and Electromagnetic Induction. `,
          "dataDelay": "0",
        },
        {
          "icon": "21",
          "meta": "Skills and Techniques",
          "subTitle": `Students will be coached and guided in problem solving strategies and answering techniques on a wide range of exam questions. Through these repeated practices, students will explored the multiple ways of solving so students can reapply into the various question types.`,
          "dataDelay": "100",
        },
        {
          "icon": "22",
          "meta": "Revision and Reinforcement",
          "subTitle": `Intensive revision will be focused on mastering all the Pure Physics concepts through topical revision packages. Examination skills such as time management and error analysis will be honed through practice tests.`,
          "dataDelay": "200",
        }],
      },
    ]
  },
  {
    "id": "chem",
    "subject": "Pure Chemistry",
    "levels":[
      {
        "level": "Secondary 3",
        "levelShort": "S3",
        "levelIcon": "/images/icon/90.svg",
        "content": [{
          "icon": "20",
          "meta": "Content & Language Mastery",
          "subTitle": `S3 Pure Chem reinforces students’ foundation with topics such as Atomic Structure and Stoichiometry that is helpful for their theory and practical papers.`,
          "dataDelay": "0",
        },
        {
          "icon": "21",
          "meta": "Skills and Techniques",
          "subTitle": `Students will be coached and guided in problem solving strategies and answering techniques on a wide range of exam questions. Through these repeated practices, students will explored the multiple ways of solving so students can reapply into the various question types.`,
          "dataDelay": "100",
        }
      ],
      },
      {
        "level": "Secondary 4",
        "levelShort": "S4",
        "levelIcon": "/images/icon/90.svg",
        "content": [{
          "icon": "20",
          "meta": "Content & Language Mastery",
          "subTitle": `S4 Pure Chemistry continues to build on students’ knowledge with new topics like Organic Chemistry and Electrochemistry and continues to integrate S3 Chemistry topics into these new topics.`,
          "dataDelay": "0",
        },
        {
          "icon": "21",
          "meta": "Skills and Techniques",
          "subTitle": `Students are taught to integrate existing knowledge with upper secondary topics while honing their presentation skills. Building of good habits like double checking their own answers is also crucial to minimize the loss of marks.`,
          "dataDelay": "100",
        },
        {
          "icon": "22",
          "meta": "Revision and Reinforcement",
          "subTitle": `Intensive revision will be focused on mastering all the Pure Chemistry concepts through topical revision packages. Examination skills such as time management and error analysis will be honed through practice tests.`,
          "dataDelay": "200",
        }],
      },
    ]
  },
  {
    "id": "h12-phy",
    "subject": "H1/2 Physics",
    "levels":[
      {
        "level": "Junior College 1",
        "levelShort": "JC1",
        "levelIcon": "/images/icon/90.svg",
        "content": [{
          "icon": "20",
          "meta": "Content & Language Mastery",
          "subTitle": `Our JC1 H1 Physics focuses on a broad range of introductory topics covering the whole of measurements, kinematics and Dynamics, which builds the foundation for the later part of the subject. H2 Physics explores additional topics in Newtonian Mechanics, Thermal Physics and Oscillations and waves. Lessons are held with simulations, demonstrations, and videos where we show how the physics concepts can be applied in everyday situations and use relevant examples to allow students to understand the theoretical concepts better.`,
          "dataDelay": "0",
        },
        {
          "icon": "21",
          "meta": "Skills and Techniques",
          "subTitle": `In our JC1 Physics program, students are taught to master the identification of the concept behind each question. They are honed to take note of key words in questions and understand the question requirements.`,
          "dataDelay": "100",
        }
      ],
      },
      {
        "level": "Junior College 2",
        "levelShort": "JC2",
        "levelIcon": "/images/icon/90.svg",
        "content": [{
          "icon": "20",
          "meta": "Content & Language Mastery",
          "subTitle": `In JC2, Electricity and Magnetism as well as Modern Physics will be covered and revision for the final exams will commence. H1 Physics students will learn selected topics from these themes while H2 students will learn the entire syllabus.`,
          "dataDelay": "0",
        },
        {
          "icon": "21",
          "meta": "Skills and Techniques",
          "subTitle": `In the second year, identification of topics and question requirements will still be a priority, along with practicing ample examination questions for exposure.`,
          "dataDelay": "100",
        },
        {
          "icon": "22",
          "meta": "Revision and Reinforcement",
          "subTitle": `In the second year, revision will be focused on mastering all the H1/2 Physics topics through topical revision packages. Examination skills such as time management and error analysis will be honed through practice tests.`,
          "dataDelay": "200",
        }],
      },
    ]
  },
  {
    "id": "h12-chem",
    "subject": "H1/2 Chemistry",
    "levels":[
      {
        "level": "Junior College 1",
        "levelShort": "JC1",
        "levelIcon": "/images/icon/90.svg",
        "content": [{
          "icon": "20",
          "meta": "Content & Language Mastery",
          "subTitle": `For JC1 Chemistry, VSEPR model, structure and bonding of molecules are covered as bridging modules from O Level. JC1 H1 Chemistry also cover the basics of Kinetics, Equilibrium, Energetics and parts of Organic Chemistry. H2 Chemistry cover the above-mentioned chapters in full depth, while exploring Solubility Equilibria, Entropy, and Titration.`,
          "dataDelay": "0",
        },
        {
          "icon": "21",
          "meta": "Skills and Techniques",
          "subTitle": `In our JC1 Chemistry program, students are taught to master the identification of the concept behind each question. They are honed to take note of key words in questions and understand the question requirements.`,
          "dataDelay": "100",
        }
      ],
      },
      {
        "level": "Junior College 2",
        "levelShort": "JC2",
        "levelIcon": "/images/icon/90.svg",
        "content": [{
          "icon": "20",
          "meta": "Content & Language Mastery",
          "subTitle": `JC2 H1 Chemistry continue to touch on organic chemistry topics on halogens, carboxylic acid, hydroxy derivatives at a reduced level. H1 syllabus ends with the easier topics on polymer chemistry and nanomaterials while H2 Chemistry continues to explore electrochemistry and transition elements.`,
          "dataDelay": "0",
        },
        {
          "icon": "21",
          "meta": "Skills and Techniques",
          "subTitle": `In the second year, identification of topics and question requirements will still be a priority, along with practicing ample examination questions for exposure.`,
          "dataDelay": "100",
        },
        {
          "icon": "22",
          "meta": "Revision and Reinforcement",
          "subTitle": `In the second year, revision will be focused on mastering all the H1/2 Chemistry topics through topical revision packages. Examination skills such as time management and error analysis will be honed through practice tests.`,
          "dataDelay": "200",
        }],
      },
    ]
  },
  {
    "id": "econs",
    "subject": "H1/2 Economics",
    "levels":[
      {
        "level": "Junior College 1",
        "levelShort": "JC1",
        "levelIcon": "/images/icon/90.svg",
        "content": [{
          "icon": "20",
          "meta": "Content & Language Mastery",
          "subTitle": `JC1 will focus on the microeconomics analysis (individual and societal levels) where idea of unlimited wants, limited resources and knowledge of how firms and markets function are introduced to students. H1 Economics is a lighter load than H2 - with lesser content, with the removal of topics such as Firms and Decisions, and are only examinable on case studies.`,
          "dataDelay": "0",
        },
        {
          "icon": "21",
          "meta": "Skills and Techniques",
          "subTitle": `Our JC1 economics explicitly teach the relevant economic skills like the tools and methods of analysis used by economists, the ability to explain and analyse economic issues and evaluate perspectives and decisions of economic agents.`,
          "dataDelay": "100",
        }
      ],
      },
      {
        "level": "Junior College 2",
        "levelShort": "JC2",
        "levelIcon": "/images/icon/90.svg",
        "content": [{
          "icon": "20",
          "meta": "Content & Language Mastery",
          "subTitle": `In the second year, H1 Economics students will cover basic macroeconomics analysis like Aggregate Demand, Aggregate Supply, Standard of Living, as well as 3 out of the 4 macroeconomic aims. H2 Economics focuses on macroeconomics analysis, international trade and globalisation. It provides an overview of the workings and linkages of the national and international economy. In addition, the impact of external trends and developments on the domestic regional and global economies, and their implications for policy choices and decisions of governments, are discussed.`,
          "dataDelay": "0",
        },
        {
          "icon": "21",
          "meta": "Skills and Techniques",
          "subTitle": `In the second year, carry-over writing, analytical and essay writing skills will be applied to the new topics taught. These skills will be refined as more practice questions are performed under timed situations. Links between the first year and second year topic will also be made clear.`,
          "dataDelay": "100",
        },
        {
          "icon": "22",
          "meta": "Revision and Reinforcement",
          "subTitle": `In the second year, revision will be focused on mastering all the H1/2 Economics topics through topical revision packages. Examination skills such as time management and error analysis will be honed through practice tests.`,
          "dataDelay": "200",
        }],
      },
    ]
  },
  {
    "id": "h1-chinese",
    "subject": "H1 Chinese",
    "levels":[
      {
        "level": "Junior College 1",
        "levelShort": "JC1",
        "levelIcon": "/images/icon/90.svg",
        "content": [{
          "icon": "20",
          "meta": "Content & Language Mastery",
          "subTitle": `Students will be taught more about the difference in exam format with focus on the Mid-Year A Level paper. Additional lessons can also be tailored for students who wishes to retake their Chinese in the year end examination. `,
          "dataDelay": "0",
        },
        {
          "icon": "21",
          "meta": "Skills and Techniques",
          "subTitle": `Specific skills to master the individual ‘A’ Level paper requirements will be taught and personalized feedback and lesson plan will be provided to students.`,
          "dataDelay": "100",
        },
        {
          "icon": "22",
          "meta": "Revision and Reinforcement",
          "subTitle": `Revision will be focused on mastering the individual ‘A’ Level paper requirements and honing time management skills through practice tests in simulated exam conditions.`,
          "dataDelay": "200",
        },
      ],
      },
    ]
  }
]

const SubjectLevelFeature = (props) => {
    return (
    <VerticalTimeline layout={props.layout}>
        {SubjectLevelContent.find((item) => item.id===props.subject).levels.map(
            (obj) => (
            <VerticalTimelineElement
                className="vertical-timeline-element--work"
                contentStyle={{ background: '#b18566', color: '#fff' }}
                contentArrowStyle={{ borderRight: '7px solid  #b18566' }}
                date={obj.level}
                iconText={obj.levelShort}
                iconStyle={{ background: '#b18566', color: '#fff' }}
                icon={`<img src="${obj.levelIcon}"/>`}
            >
                <div className="vertical-timeline-element-title">{obj.level}</div>
                {obj.content.map(
                  (ct) => (
                    <>
                    <div className="vertical-timeline-element-subtitle">{ct.meta}</div>
                    <p>
                    {ct.subTitle}
                    </p>
                    </>
                  )
                )}
            </VerticalTimelineElement>
            ))
        }
    </VerticalTimeline>
    )
};

export default SubjectLevelFeature;