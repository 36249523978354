import React from "react";
import { Helmet } from "react-helmet";
import Header from "../../components/shared/Header";
import Footer from "../../components/shared/Footer";
import TestimonialThree from "../../components/homepage/TestimonialThree";
import CounterFive from "../../components/about/testimonials/CounterFive";
import Quotes from "../../components/about/testimonials/Quotes";
import AnalyticsEvent from "../../components/shared/AnalyticsEvent";

const Testimonials = () => {
  return (
    <div className="main-page-wrapper font-gordita">
      <Helmet>
        <title>
          Testimonials
        </title>
      </Helmet>
      
      <Header />

      {/* =============================================
				Fancy Hero One
			============================================== */}
      <div className="fancy-hero-one">
        <div className="container">
          <div className="row">
            <div className="col-xl-9 col-lg-11 m-auto">
              <h2 className="font-rubik">
                Our results and testimonials
              </h2>
            </div>
            <div className="col-xl-9 col-lg-11 m-auto">
              <p className="font-rubik">
              For over 10 years, we have guided over 3000 students and have consistently produced outstanding results. Today, EduZ Tuition is a top notch learning centre providing quality education with a team of dedicated staff and a conducive learning environment.
              </p>
            </div>
          </div>
        </div>
        <div className="bubble-one"></div>
        <div className="bubble-two"></div>
        <div className="bubble-three"></div>
        <div className="bubble-four"></div>
        <div className="bubble-five"></div>
        <div className="bubble-six"></div>
      </div>

      {/* =====================================================
            Client Feedback Slider Seven
        ===================================================== */}
      <div
        className="client-feedback-slider-seven mt-50 mb-250"
        id="feedback"
      >
        <div className="inner-container">
          <div className="container">
            <div className="row">
              <div className="col-lg-8 m-auto">
                <img src="images/icon/143.svg" alt="icon" className="m-auto" />
                <div className="title-style-eleven text-center mt-30">
                  <h2>Check what some of our parents say about us.</h2>
                </div>
                {/* /.title-style-eleven */}
              </div>
            </div>

            <div className="row">
              <div className="col-xl-8 col-lg-10 m-auto">
                <div className="clientSliderFive  mt-80 md-mt-50">
                  <TestimonialThree />
                </div>
                <img
                  src="images/shape/156.svg"
                  alt="shape"
                  className="shapes shape-one"
                />
                <img
                  src="images/shape/157.svg"
                  alt="shape"
                  className="shapes shape-two"
                />
                <img
                  src="images/shape/158.svg"
                  alt="shape"
                  className="shapes shape-three"
                />
              </div>
            </div>
          </div>
        </div>
        {/*  /.inner-container */}
        <img src="images/site/homepage/testimonials-shape.svg" alt="shape" className="bg-image" />
      </div>
      {/* /.client-feedback-slider-six */}


      {/* =============================================
				Fancy Text block One
			==============================================  */}
      <div className="fancy-text-block-one">
        <div className="container">
            <h3 className="font-rubik mb-50">
              Don't take our word for it - hear from our students and parents
            </h3>
          <Quotes/>
        </div>
        <div className="bubble-one"></div>
        <div className="bubble-two"></div>
        <div className="bubble-three"></div>
        <div className="bubble-four"></div>
        <div className="bubble-five"></div>
        <div className="bubble-six"></div>
      </div>
      {/* /.fancy-text-block-one */}
      
      {/* =====================================================
				Counter Info Standard
			===================================================== */}
      <div className="counter-info-standard pt-70 pb-45">
        <div className="container">
          <div className="row justify-content-center">
            <CounterFive />
            <a href="https://wa.me/+6590625285?lang=en" target="_blank" rel="noreferrer"
                className="theme-btn-five mt-50 md-mt-30 mb-50"
                onClick={() => AnalyticsEvent({
                  category: "WhatsAppEnquire",
                  action: "Click",
                  source: "/testimonials",
                  label: "clickWhatsAppEnquire"
                })}
              >
                <i className="fa fa-whatsapp"></i> Enquire Now
              </a>
          </div>
        </div>
      </div>
      {/* /.counter-info-standard */}
      
      <Footer/>
    </div>
  );
};

export default Testimonials;
