import React from "react";
import { Helmet } from "react-helmet";
import Header from "../../components/shared/Header";
import Footer from "../../components/shared/Footer";
import FancyTextBlockTen from "../../components/programmes/centre-based-lessons/FancyTextBlockTen";
import FeatureFive from "../../components/programmes/online-lessons/FeatureFive";
import VideoGallery from "../../components/programmes/online-lessons/VideoGallery";
import PricingThree from "../../components/programmes/online-lessons/PricingThree";
import FeaturesEight from "../../components/programmes/online-lessons/FeaturesEight";
import AnalyticsEvent from "../../components/shared/AnalyticsEvent";

const OnlineBasedLessons = () => {
return (
    <div className="main-page-wrapper p0">
      <Helmet>
        <title>
          Online Lessons
        </title>
      </Helmet>

      <Header />
      
      {/* 	=============================================
				Fancy Hero Three
			============================================== */}
      <div className="fancy-hero-three bg-transparent">
        <div className="shapes shape-one"></div>
        <div className="shapes shape-two"></div>
        <div className="shapes shape-three"></div>
        <div className="shapes shape-four"></div>
        <div className="shapes shape-five"></div>
        <div className="shapes shape-six"></div>
        <div className="bg-wrapper">
          <div className="container">
            <div className="row">
              <div className="col-xl-9 col-lg-10 col-md-10 m-auto">
                <h1 className="heading">Online Lessons</h1>
                <p className="sub-heading">
                  Stay safe. Stay Home. Stay Ahead of the Game still. <br/>Many topics have become self-learned in schools. Ensure your child can keep up with the rest while keeping safe with our live, virtual online lessons.
                </p>
                <a href="https://wa.me/+6590625285?lang=en" target="_blank" rel="noreferrer"
                  className="theme-btn-five mt-50 md-mt-30"
                  onClick={() => AnalyticsEvent({
                    category: "WhatsAppEnquire",
                    action: "Click",
                    source: "/online-lessons",
                    label: "onlineLessonsClickWhatsAppEnquire"
                  })}
                >
                  <i className="fa fa-whatsapp"></i> Enquire Now
                </a>
              </div>
            </div>
          </div>
        </div>
        {/* /.bg-wrapper  */}
      </div>
      {/* /.fancy-hero-three */}

      
      {/*=============================================
          Fancy Text block Ten
      ==============================================  */}
      <div className="fancy-text-block-ten fancy-feature-seven mt-170 md-mt-100 mb-50">
        <div className="container">
          <FancyTextBlockTen />
        </div>
      </div>
      {/* /.fancy-text-block-ten */}


      {/* =============================================
				Fancy Text block Five
			==============================================  */}
      <div className="fancy-text-block-five pt-130 pb-100 md-pt-100 md-pb-100">
        <div className="container">
          <div className="row">
            <div className="col-lg-7 ml-auto">
              <div className="title-style-one mb-50 md-mb-30">
                <h2>We provide recordings for some classes</h2>
                <p className="font-rubik">
                Have you ever encountered that moment when you remember the teacher has gone through a particular type of question, but can't exactly remember the method to solve it? That's why some of our selected lessons are recorded for easy reference. You can view them if you miss class too!
                </p>
              </div>
              {/*  /.title-style-one */}
            </div>
          </div>

          <div className="wrapper">
            <div className="row justify-content-center">
              <FeatureFive />
            </div>
          </div>
        </div>
      </div>
      {/* /.block-style-five */}

      {/* 	=====================================================
				Fancy Feature Twenty One
			===================================================== */}
      <div className="fancy-feature-twentyOne mt-50 md-mt-50" id="template">
        <div className="container">
          <div
            className="title-style-eight text-center mb-80 md-mb-30"
            data-aos="fade-up"
            data-aos-duration="1200"
          >
            <h2>See it for yourself</h2>
            <p>Catch some snippets on how our online lessons are conducted.</p>
          </div>
        </div>

        <div className="lg-container">
          <div className="container">
            <VideoGallery />
          </div>
        </div>
        {/* /.lg-container */}
        </div>
      {/* /.fancy-feature-twentyOne */}
      
      {/* 
     =============================================
			Pricing Section Three
		============================================== */}
      <div className="pricing-section-three-inner-page mb-200 md-mb-120">
        <img
          src="images/shape/124.svg"
          alt="shape"
          className="shapes shape-one"
        />
        <img
          src="images/shape/125.svg"
          alt="shape"
          className="shapes shape-two"
        />

        <div className="container">
          <div className="title-style-five text-center mb-50 md-mb-40">
            <h6>Our Online Lesson Pricing</h6>
            <div className="row">
              <div className="col-lg-9 m-auto">
                <h2>Contact us to enquire for schedule</h2>
              </div>
            </div>
          </div>

          <div className="pricing-table-area-three">
            <PricingThree />
          </div>
          {/* /.pricing-table-area-three */}


          <div className="fancy-feature-eight mt-50 md-mt-50">
            <div className="container">
              <div className="bg-wrapper title-style-five text-center">
                <h2>Discounts</h2>
                <FeaturesEight />
              </div>
            </div>
          </div>
          {/* /.fancy-feature-eight */}
          
        </div>
        {/* ./container */}
      </div>
      {/* /.pricing-section-three */}

      <Footer/>
    </div>
  );
};

export default OnlineBasedLessons;
