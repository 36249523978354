import React from "react";

const FeatureContent = [
  {
    icon: "book",
    meta: "Well Structured Teaching Materials",
    subTitle: `Specially-designed and structured lessons to ensure that our students are exposed to the everchanging landscape of examination field. This means students are exposed to a wide variety of questions possibly tested.`,
    dataDelay: "0",
  },
  {
    icon: "teacher",
    meta: "Experienced Tutors",
    subTitle: `Most of our teachers have at least 7 years of teaching experience. This ranges from current school teachers to ex-school teachers who have joined us as full-time tutors.`,
    dataDelay: "100",
  },
  {
    icon: "no-fee",
    meta: "No Material And Registration Fee",
    subTitle: `We DO NOT collect registration or material fee.`,
    dataDelay: "300",
  },
  {
    icon: "small-class",
    meta: "Small Class Size",
    subTitle: `We are proud of our low teacher-to-student ratio. Students will receive adequate attention from our tutors who will engage them in active discussions and prompt them for questions to clarify their doubts.`,
    dataDelay: "0",
  },
  {
    icon: "best",
    meta: "Track Record",
    subTitle: `Established for more than a decade. 3000+ students and counting! Including various schools' top PSLE, O and A level scorers. Parents and students recommend and believe in us.`,
    dataDelay: "100",
  },
  {
    icon: "communication",
    meta: "Efficient Communication Process",
    subTitle: `We work hand-in-hand with parents to ensure our students' progress. Our highly qualified executives will be available to handle your administrative enquiries, as well as provide constant feedback about students and their pace of learning.`,
    dataDelay: "300",
  },
  
  {
    icon: "convenience",
    meta: "Personalised Progress Reports",
    subTitle: `Our progress reports after each class allow you to keep track of what your child learns in class, as well as tutor's analysis of your child's strengths, areas for growth, and learning habits to maximise your child's potential.`,
    dataDelay: "0",
  },
];

const Features = () => {
  return (
    <div className="row justify-content-center">
      {FeatureContent.map((val, i) => (
        <div
          className="col-6 col-lg-4 col-md-6"
          key={i}
          data-aos="fade-up"
          data-aos-duration="1200"
          data-aos-delay={val.dataDelay}
        >
          <div className="block-style-three">
            <div className="icon">
              <img src={`images/site/icons/${val.icon}.svg`} alt="icon" />
            </div>
            <h4>{val.meta}</h4>
            <p className="font-rubik">{val.subTitle}</p>
          </div>
        </div>
      ))}
    </div>
  );
};

export default Features;
