import React from "react";
import { Link } from "react-router-dom";
import { useLocation } from 'react-router-dom'


const Level = [
  {
    name: "Primary School Tuition",
    routerPath: "/programmes/primary",
  },
  {
    name: "Secondary School Tuition",
    routerPath: "/programmes/secondary",
  },
  {
    name: "Junior College Tuition",
    routerPath: "/programmes/junior-college",
  }
];

const Subject = [
  {
    name: "English",
    routerPath: "/programmes/english",
  },
  {
    name: "Chinese",
    routerPath: "/programmes/chinese",
  },
  {
    name: "Mathematics",
    routerPath: "/programmes/math",
  },
  {
    name: "E. Math",
    routerPath: "/programmes/math#e-math",
  },
  {
    name: "A. Math",
    routerPath: "/programmes/math#a-math",
  },
  {
    name: "Science",
    routerPath: "/programmes/science",
  },
  {
    name: "Combined Phy/Chem",
    routerPath: "/programmes/science#combined-phy-chem",
  },
  {
    name: "Physics",
    routerPath: "/programmes/science#pure-physics",
  },
  {
    name: "Chemistry",
    routerPath: "/programmes/science#pure-chemistry",
  },
  {
    name: "General Paper",
    routerPath: "/programmes/english#gp",
  },
  {
    name: "H1/H2 Mathematics",
    routerPath: "/programmes/math#jc-math",
  },
  {
    name: "H1/H2 Chemistry",
    routerPath: "/programmes/science#jc-chemistry",
  },
  {
    name: "H1/H2 Physics",
    routerPath: "/programmes/science#jc-physics",
  },
  {
    name: "H1/H2 Economics",
    routerPath: "/programmes/econs",
  },
  {
    name: "H1 Chinese",
    routerPath: "/programmes/chinese#jc-chinese",
  },
];


const MegaMenu = () => {
  const location = useLocation();
  const progList = ["/programmes", "/centre-based-lessons", "/online-lessons", "/art-classes"]
  for (var i = 0; i < Level.length; i++) {
    progList.push(Level[i].routerPath)
  }
  for (i = 0; i < Subject.length; i++) {
    progList.push(Subject[i].routerPath)
  }
  return (
    <ul className="navbar-nav">
      <li className="nav-item">
        <a className={location.pathname === "/" ? "nav-link active" : "nav-link"} href="/">
          Home
        </a>
      </li>
      <li className="nav-item dropdown">
        <a className={["/about-us", "/team", "/testimonials"].includes(location.pathname) ? "nav-link dropdown-toggle active" : "nav-link dropdown-toggle"} href="/about-us" data-toggle="dropdown">
          About
        </a>
        <ul className="dropdown-menu">
          <li>
            <Link className="dropdown-item" to="/about-us">
              {" "}
              About Us
            </Link>
          </li>
          <li>
            <Link className="dropdown-item" to="/team">
              {" "}
              Team
            </Link>
          </li>
          <li>
            <Link className="dropdown-item" to="/testimonials">
              {" "}
              Testimonials
            </Link>
          </li>
        </ul>
        {/* /.dropdown-menu */}
      </li>
      {/* End li */}

      <li className="nav-item dropdown">
        <a className={(["/programmes", "/centre-based-lessons", "/online-lessons", "/art-classes"].includes(location.pathname) || location.pathname.includes("programmes")) ? "nav-link dropdown-toggle active" : "nav-link dropdown-toggle"} href="/programmes" data-toggle="dropdown">
          Programmes
        </a>

        <ul className="dropdown-menu">
        <li>
            <Link className="dropdown-item" to="/programmes">
              {" "}
              Why EduZ Tuition?
            </Link>
        </li>
        <li>
            <Link className="dropdown-item" to="/centre-based-lessons">
              {" "}
              Centre Based Lessons
            </Link>
        </li>
        <li>
            <Link className="dropdown-item" to="/online-lessons">
              {" "}
              Online Based Lessons
            </Link>
        </li>
        <li className="dropdown-submenu dropdown">
          <a
            href="#"
            className="dropdown-item dropdown-toggle"
            data-toggle="dropdown"
          >
            Level
          </a>
          <ul className="dropdown-menu">
            {Level.map((val, i) => (
              <li key={i}>
                <Link to={val.routerPath} className="dropdown-item">
                  {val.name}
                </Link>
              </li>
            ))}
          </ul>
        </li>
        <li className="dropdown-submenu dropdown">
          <a
            href="#"
            className="dropdown-item dropdown-toggle"
            data-toggle="dropdown"
          >
            Subject
          </a>
          <ul className="dropdown-menu">
            {Subject.map((val, i) => (
              <li key={i}>
                <Link to={val.routerPath} className="dropdown-item">
                  {val.name}
                </Link>
              </li>
            ))}
          </ul>
        </li>
        <li>
          <Link className="dropdown-item" to="/art-classes">
            {" "}
            Art classes
          </Link>
        </li>
        </ul>
        {/* /.dropdown-menu */}
      </li>
      {/* End li */}

      <li className="nav-item">
        <a className={location.pathname.includes("blog") ? "nav-link active" : "nav-link"} href="/blog">
          Blog
        </a>
      </li>

      <li className="nav-item">
        <a className={location.pathname === "/contact" ? "nav-link active" : "nav-link"} href="/contact">
          Contact Us
        </a>
      </li>
      {/* End li */}
    </ul>
  );
  // End navbar nav mega menu main
};

export default MegaMenu;
